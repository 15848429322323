import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-decision',
  templateUrl: './decision.component.html',
  styleUrls: ['./decision.component.scss']
})
export class DecisionComponent implements OnInit {

  @Input('contenido') contenido;
  @Output() DecisionResponse = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    console.log(this.contenido);
  }

  enviarRespuesta(input){
    if(input === 'possitive'){
      this.DecisionResponse.emit(this.contenido.origen+'Done');
    }else{
      this.DecisionResponse.emit(input);
    }
  }

}
