import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-category-list-store',
  templateUrl: './category-list-store.component.html',
  styleUrls: ['./category-list-store.component.scss']
})
export class CategoryListStoreComponent {

  @Input() categories;
  @Input() categoryParentId;
  @Input() params;
  @Output() change: EventEmitter<any> = new EventEmitter();
  mainCategories;
  address;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  public ngDoCheck() {
  }

  public stopClickPropagate(event: any){
    if(window.innerWidth < 960){
      event.stopPropagation();
      event.preventDefault();
    }    
  }

  public changeCategory(event, category){
    console.log('CATEGORYLISTSTORE')
    this.change.emit(event);
    this.address = document.location.href;
    if (this.address.split('/').find(element => element === 'stores')){
          this.router.navigateByUrl('/search', {skipLocationChange: true}).then(() =>
          this.router.navigate(['/store/' + this.params.stores + '/' + category]));
    } else {
      this.router.navigate(['/products/search/bycategory/' + category]);
    }
  }

}