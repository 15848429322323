import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FlexLayoutModule } from '@angular/flex-layout';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatNativeDateModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatRippleModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatStepperModule} from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';


import {MatMenuModule} from '@angular/material/menu';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true               
};

import { PipesModule } from '../theme/pipes/pipes.module';
import { RatingComponent } from './rating/rating.component';
import { ControlsComponent } from './controls/controls.component';
import { MainCarouselComponent } from './main-carousel/main-carousel.component';
import { BrandsCarouselComponent } from './brands-carousel/brands-carousel.component';
import { ProductsCarouselComponent } from './products-carousel/products-carousel.component';
import { ProductsMixCarouselComponent } from './products-mix-carousel/products-mix-carousel.component';
import { PromsCarouselComponent } from './proms-carousel/proms-carousel.component';
import { ProductDialogComponent } from './products-carousel/product-dialog/product-dialog.component';
import { BannersComponent } from './banners/banners.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryListStoreComponent } from './category-list-store/category-list-store.component';
import {PortadasComponent} from './portadas/portadas.component';
import {ModalAnimationComponent} from './modal-animation/modal-animation.component';
import { ModalWindowComponent } from './modal-window/modal-window.component';
import { ResetPassComponent } from './modal-window/components/reset-pass/reset-pass.component';
import { SignInModalComponent } from './modal-window/components/sign-in-modal/sign-in-modal.component';
import { ConfirmCheckoutComponent } from './modal-window/components/confirm-checkout/confirm-checkout.component';
import { InfoPagoTransferComponent } from './modal-window/components/info-pago-transfer/info-pago-transfer.component';
import { MainBannerComponent } from './main-banner/main-banner.component';
import { ProductsCollageComponent } from './products-collage/products-collage.component';
import { DecisionComponent } from './modal-window/components/decision/decision.component';
import { MaterialModule } from './material/material.module';
import { NewUserSuccessComponent } from './modal-window/components/new-user-success/new-user-success.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FormularioComponent } from './formulario/formulario.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SwiperModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgbPaginationModule
  ],
  exports: [
    RouterModule,
    SwiperModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
    RatingComponent,
    ControlsComponent,
    MainBannerComponent,
    MainCarouselComponent,
    BrandsCarouselComponent,
    ProductsCarouselComponent,
    ProductsMixCarouselComponent,
    PromsCarouselComponent,
    ProductDialogComponent,
    BannersComponent,
    CategoryListComponent,
    CategoryListStoreComponent,
    PortadasComponent,
    ModalAnimationComponent,
    ModalWindowComponent,
    ResetPassComponent,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ProductsCollageComponent,
    ResetPasswordComponent,
    FormularioComponent,
    NgbPaginationModule
  ],
  declarations: [
    RatingComponent,
    ControlsComponent,
    MainCarouselComponent,
    BrandsCarouselComponent,
    ProductsCarouselComponent,
    ProductsMixCarouselComponent,
    PromsCarouselComponent,
    ProductDialogComponent,
    BannersComponent,
    CategoryListComponent,
    CategoryListStoreComponent,
    PortadasComponent,
    ModalAnimationComponent,
    ModalWindowComponent,
    ResetPassComponent,
    SignInModalComponent,
    ConfirmCheckoutComponent,
    InfoPagoTransferComponent,
    MainBannerComponent,
    ProductsCollageComponent,
    DecisionComponent,
    NewUserSuccessComponent,
    ResetPasswordComponent,
    FormularioComponent
  ],
  entryComponents:[
    ProductDialogComponent
  ],
  providers:[
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ]
})
export class SharedModule { }
