import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-new-user-success',
  templateUrl: './new-user-success.component.html',
  styleUrls: ['./new-user-success.component.scss']
})
export class NewUserSuccessComponent implements OnInit {

  @Output() NewUserResponse = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  // this.router.navigate(['/sign-up/verification']);

  confirmCheckout(){
    this.NewUserResponse.emit('Done');
  }

}
