import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent implements OnInit, OnDestroy, AfterViewInit {

  // timeRemaining: number = 60;
  // interval: any;
  percentValue = 100;
  animationDuration = 10000;
  pieInterval: any;

  constructor() { }

  ngAfterViewInit(): void {
    this.setPieInterval();
  }

  ngOnInit(): void {
    // this.startTimer();
  }

  ngOnDestroy() {
    if (this.pieInterval) {
      clearInterval(this.pieInterval);
    }
  }

  setPieInterval() {
    this.pieInterval = setInterval(() => {
      this.percentValue = 0;
      setTimeout(() => {
        this.percentValue = 100;
      }, 0.5);
    }, this.animationDuration + 500);
  }

  // startTimer() {
  //   this.interval = setInterval(() => {
  //     this.timeRemaining -= 1;
  //     if (this.timeRemaining <= 0) {
  //       clearInterval(this.interval);
  //     }
  //   }, 1000); // Intervalo de actualización cada segundo
  // }

  // getProgress(): number {
  //   return (this.timeRemaining / 60) * 100; // Calcula el progreso en porcentaje
  // }

}
