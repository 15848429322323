import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
// import {SelectOptionService} from '../elements/select-option.service';

@Injectable()
export class AuthService {
  private SSkey = 'jwt-xpert';

  private SSusername = 'username';



  private SSStore = 'storeid';

  private SSid = 'id';
  private SSemail = 'email';
  private SSnombres = 'nombres';
  private SSapellidos = 'apellidos';
  private SSrol = 'rol';
  private SSport = 'port';
  private SShabilitaciones = 'habilitaciones';
  private SSproduct = 'producto';

  private SScategorias = 'categorias';
  private SSgrupos = 'grupos';
  private SStipos = 'tipos';

  private SSvendedores = 'vendedores';
  private SSformasPago = 'formasPago';
  private SSestados = 'estados';

  private numProductos = 'numProductos';
  private SSautenticado = 'autenticacion';


  private SShabilitacionVentas = 'habilitacionVentas';
  private SShabilitacionTienda = 'habilitacionTienda';


  private SShabilitacionBot = 'habilitacionBot';
  private SSsuscriptionName = 'suscriptionName';
  private SShabilitacionSystem = 'habilitacionSystem';


  private SSidentificadorTienda = 'identificadorTienda';
  private SSidentificadorBot = 'identificadorTienda';
  private SSidentificador = 'identificador';
  // private SSidentificadorTienda = 'identificadorTienda';

  public username = 'cuenta';
  public autenticado = false;


  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId) {
  }

  setLocal(key: string, data: any) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem(key, JSON.stringify(data))
      }
    } catch (e) {
      console.log(e)
    }
  }

  getLocal(key: string) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        return JSON.parse(localStorage.getItem(key))
      }
    } catch (e) {

    }
  }

  getIdentificadorBot() {
    try {
      if (isPlatformBrowser(this.platformId)) {
        return localStorage.getItem(this.SSidentificadorBot);
      }
    } catch (e) {

    }
  }

  clear(): void {
    try {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.clear()
      }
    } catch (e) {
      console.log('Error cleaning localStorage', e)
    }
  }

  setAuth() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSautenticado, 'OK');
    }
  }
  getAuth() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSautenticado);
    }
  }
  delAuth() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSautenticado, 'error');
    }
  }
  setToken(data: any) {
    console.log('token guardado', data);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSkey, data);
    }
  }
  getToken() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSkey);
      // return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOiJVODcyMjg1NjlEIiwiaWF0IjoxNjk3NDc2NTgzLCJleHAiOjE2OTc1MzQxODN9.D0fUzZoSnvLU_TkVQTJjn2NuqcouYRUGVQ801fWfPKE';
    }
  }
  setNombres(data: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSnombres, data);
    }
  }
  getNombres() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSnombres);
    }
  }
  getApellidos() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSapellidos);
    }
  }
  setApellidos(data: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSapellidos, data);
    }
  }
  setUsername(data: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSusername, data);
    }
  }
  setEmail(data: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSemail, data);
    }
  }
  setIdentificador(data: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSidentificador, data);
    }
  }
  getIdentificador() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSidentificador);
    }
  }
  getEmail() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSemail);
    }
  }
  getUsername() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSusername);
    }
  }
  setHabilitacionTienda(data: string) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem(this.SShabilitacionTienda, data);
        console.log('guardado en local ', data)

      }
    } catch (e) {
      console.log(e);
    }
  }

  getHabilitacionTienda() {
    try {
      if (isPlatformBrowser(this.platformId)) {
        return localStorage.getItem(this.SShabilitacionTienda);
      }
    } catch (e) {

    }
  }

  setHabilitacionBot(data: string) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem(this.SShabilitacionBot, data);
      }
    } catch (e) {
      console.log(e);
    }
  }



  getHabilitacionBot() {
    try {
      if (isPlatformBrowser(this.platformId)) {
        return localStorage.getItem(this.SShabilitacionBot);
      }
    } catch (e) {

    }
  }

  setHabilitacionSystem(data: string) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem(this.SShabilitacionSystem, data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  getSuscriptionName() {
    try {
      if (isPlatformBrowser(this.platformId)) {
        return localStorage.getItem(this.SSsuscriptionName);
      }
    } catch (e) {

    }
  }

  setSuscriptionName(data: string) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem(this.SSsuscriptionName, data);
      }
    } catch (e) {
      console.log(e);
    }
  }

  getHabilitacionSystem() {
    try {
      if (isPlatformBrowser(this.platformId)) {
        return localStorage.getItem(this.SShabilitacionSystem);
      }
    } catch (e) {

    }
  }

  clearParameters() {
    // localStorage.setItem(this.SSkey, data.jwt);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
    // this.detectAuth();
  }

}
