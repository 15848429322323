import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
// import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';

@Injectable()
export class ApiService {

  /// alertas /////////////////
  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;

  baseUrl = environment.urlApi;
  authUrl = environment.urlApi;
  baseServicios = environment.urlApi + '/ushops/servicios/ServAuth';

  constructor(private http: HttpClient, private auth: AuthService
    // , private toastyService: ToastyService
  ) { }

  //http://54.70.216.182:80/utilidadesGenerales/ushops-reg/registroHome




  validateAccount(body, query): Observable<any> {
    const options = {
      // headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
    };

    // console.log(this.auth.getToken());
    return this.http.post(`${this.authUrl}/EgmSystems/Reg/verificacion${query}`, body)
      .pipe(catchError(this.handleError));
  }
  

  formularioregistro(credencials): Observable<any> {

    return this.http.patch(`${this.authUrl}/EgmSystems/Reg/usuario`, credencials).pipe(catchError(this.handleError));

  }

 
  //http://54.70.216.182:80/utilidadesGenerales/ushops-reg/ayudaHome

  formulariocontacto(parametros): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['x-auth']: `twyeHL1WhkZNBqf5y44YnKs6DK4WO2lQcPe00fBeptv2Xxv4jtgZxXixkfYb` })
    };



    console.log(parametros);
    return this.http.patch(`https://ims.egm-robotics.com/IMSSystems/clientela/contactos/newContactForm`, parametros, options)
      .pipe(catchError(this.handleError));
  }

  authentication(credencials): Observable<any> {
    // return this.http.post(`${this.authUrl}/usuario/authentication`, credencials).pipe(catchError(this.handleError));
    return this.http.post(`${this.authUrl}/EgmSystems/Auth/getAuth`, credencials).pipe(catchError(this.handleError));
  }

  getUsuarioParameters(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    console.log('getUsuarioParameters', `${this.baseUrl}/usuario/getUsuarioParameters`, this.auth.getToken())
    return this.http.patch(`${this.baseUrl}/ushops/salesxpertGeneral/usuario/getUsuarioParameters`, parameters, options)
      .pipe(catchError(this.handleError));
  }


  // addToast(options) {

  //   console.log('toast saliendo');
  //   if (options.closeOther) {
  //     this.toastyService.clearAll();
  //   }
  //   console.log('toast saliendo', 1);

  //   this.position = options.position ? options.position : this.position;
  //   console.log('toast saliendo', 2);

  //   const toastOptions: ToastOptions = {
  //     title: options.title,
  //     msg: options.msg,
  //     showClose: options.showClose,
  //     timeout: options.timeout,
  //     theme: options.theme,
  //     onAdd: (toast: ToastData) => {
  //       /* added */
  //     },
  //     onRemove: (toast: ToastData) => {
  //       /* removed */
  //     }
  //   };
  //   console.log('toast saliendo', 3, toastOptions);

  //   switch (options.type) {
  //     case 'default': this.toastyService.default(toastOptions); break;
  //     case 'info': this.toastyService.info(toastOptions); break;
  //     case 'success': this.toastyService.success(toastOptions); break;
  //     case 'wait': this.toastyService.wait(toastOptions); break;
  //     case 'error': this.toastyService.error(toastOptions); break;
  //     case 'warning': this.toastyService.warning(toastOptions); break;
  //   }

  //   console.log('toast saliendo', 4);
  // }



  handleError(error: HttpErrorResponse) {
    console.log('error desconocido: ', error);
    if (error.error instanceof ErrorEvent) {
    } else {
      if (error.status === 401) {
        console.error('no autorizado:');
        localStorage.removeItem('jwt');
      }
    }
    return throwError(new Error(error.statusText));
  }


}
