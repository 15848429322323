import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Settings, AppSettings } from '../app.settings';
import { AppService } from '../app.service';
import { Category, Product } from '../app.models';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { ApiService} from '../services/api.service';
import { AuthService} from '../services/auth.service';


@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [ SidenavMenuService ]
})
export class PagesComponent implements OnInit {
  constructor(public appSettings: AppSettings,
              public appService: AppService,
              public sidenavMenuService: SidenavMenuService,
              public router: Router, private api: ApiService,
              private auth: AuthService,
              public _location: Location) {
    this.settings = this.appSettings.settings;
  }
  public showBackToTop = false;
  public categories: Category[];
  public category: Category;
  public sidenavMenuItems: Array<any>;
  public products: Product;
  categoria = 'Todos';
  user_auth;
  store_auth;
  idStore;
  @ViewChild('sidenav') sidenav: any;

  public banners: Array<any>;

  public settings: Settings;
  address: string;

  ngOnInit() {
    this.getCategories();
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
    this.scrollToTop()
    this.user_auth = this.appService.usuario.autenticado;
    this.store_auth = this.appService.usuario.habilitacionTienda;
    // this.getBanners();
  }

  public onChangeCategory(event){
    if(event.target){
      this.router.navigate(['/products', event.target.innerText.toLowerCase()]);
    }
  }

  selectCategory(event) {
    console.log('evento', event);
    this.categoria = event;
  }

  IrTienda(){
    this.idStore = this.auth.getIdentificadorEmpresa();
    console.log('idStore', this.idStore);
    this.router.navigate(['/negocio/v1'],{queryParams: {codigo: this.idStore}});
  }

  IrCarrito(){
    this.router.navigate(['/cart']);
  }

  public tecla(event) {
    if(event.keyCode === 13 || event.code === 'Enter') {
      this.search();
    }
  };

  public changeCategory(event) {
    if (event.target) {
      this.category = this.categories.filter(category => category.name == event.target.innerText)[0];
    }
    if (window.innerWidth < 960) {
      this.stopClickPropagate(event);
    }
  }

  public remove(product) {
      const index: number = this.appService.Data.cartList.indexOf(product);
      if (index !== -1) {
          this.appService.Data.cartList.splice(index, 1);
          this.appService.Data.totalPrice = this.appService.Data.totalPrice - product.newPrice;
      }
  }

  public clear() {
    this.appService.Data.cartList.length = 0;
  }


  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }

  getCategories() {
    const body = {
      scope: 'uShops-platform',
      device: 'web',
    };

    this.api.getCategorias(body).subscribe(
      result => {
        this.categories = result;
      },
      error => {
        console.log(error)
      }
    )
  }

  getAllCategoriasName() {
    if( this.categoria.toLowerCase() === 'all') {
      return 'Todos';
    } else {
      return this.categoria.charAt(0).toUpperCase() + this.categoria.slice(1);
    }
  }
  getCategoryName() {
    if( this.categoria.toLowerCase() === 'all') {
      return 'Todos';
    } else {
      return this.categoria.charAt(0).toUpperCase() + this.categoria.slice(1);
    }
  }

  search() {
    var valor: any;
    // if (window.innerWidth < 600) {
    //   if((<HTMLInputElement>document.getElementById('searched2')) !== null) {
    //     valor = (<HTMLInputElement>document.getElementById('searched2')).value;
    //     // document.getElementById('searchbar2').style.display = 'none';
    //   }else if((<HTMLInputElement>document.getElementById('searched4')) !== null) {
    //     valor = (<HTMLInputElement>document.getElementById('searched4')).value;
    //     // document.getElementById('searchbar4').style.display = 'none';
    //   }
    // } else {
      // if((<HTMLInputElement>document.getElementById('searched1')) !== null) {
        valor = (<HTMLInputElement>document.getElementById('searched1')).value;
      // }else if((<HTMLInputElement>document.getElementById('searched3')) !== null) {
      //   valor = (<HTMLInputElement>document.getElementById('searched3')).value;
      // }
    // }
    
    console.log('search', valor);

    if (valor !== null && valor.length > 1 && valor !== undefined ) {
      if(this.categoria === 'Todos') {
        this.categoria = 'all';
      }
      this.address = document.location.href;
      if (this.address.split('/').find(element => element === 'busqueda')){
        this.router.navigateByUrl('/products', {skipLocationChange: true}).then(() =>
        this.router.navigate(['/busqueda/general'],{queryParams: {search: valor}}));
        
        
      } else {
        this.router.navigate(['/busqueda/general'],{queryParams: {search: valor}});
      }
  }
}

  busquedaGlobal(valor) {
    this.api.searchProductsGlobal(valor).subscribe(
      result => {
        this.products = result;
        console.log('body-FindProduct', valor)
        console.log('search-main-page', this.products);
      },
      error => {
          console.log(error)
        }
      )
  }

  public scrollToTop() {
    const scrollDuration = 200;
    const scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.pageYOffset != 0) {
         window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => { window.scrollTo(0, 0); });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // this.sidenav.close();
      }
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus() {
    if (window.innerWidth < 960) {
      this.sidenavMenuService.closeAllSubMenus();
    }
  }

  public goCheckout() {
    if (this.appService.usuario.autenticado) {
      this.router.navigate(['/checkout']);
    } else {
      this.router.navigate(['/sign-in']);
    }
  }

  getImageURL(imagen) {
    const path = imagen.URL + imagen.nombreInterno;
    return  this.api.getImageURL() + path;
  }

  getBanners() {
    this.api.getCarrouselBanners().subscribe(
      result => {
        this.banners = result;
        console.log('banners', this.banners);
      },
      error => {
        console.log(error)
      }
    )
  }

}
