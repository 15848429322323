import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SidenavMenuService } from './sidenav-menu.service';
import { Category} from './../../../../app/app.models';


@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  providers: [ SidenavMenuService ]
})
export class SidenavMenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  parentMenu: Array<any>;
  public categories: Category[];

  constructor(private sidenavMenuService:SidenavMenuService, private api: ApiService) { }

  ngOnInit() {
    this.getCategories();
  }

  onClick(menuId){
    this.sidenavMenuService.toggleMenuItem(menuId);
    this.sidenavMenuService.closeOtherSubMenus(this.menuItems, menuId);
  }

  getCategories() {
    const body = {
      scope: 'uShops-platform',
      device: 'web',
    };
    this.api.getAllCategorias(body).subscribe(
      result => {
        this.categories = result;
        //console.log(this.categories);
      },
      error => {
        //console.log(error)
      }
    )
  }

}
