import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
// import {SelectOptionService} from '../elements/select-option.service';

@Injectable()
export class AuthService {
  private SSkey = 'jwt';

  private SSusername = 'username';


  
  private SSStore = 'storeid';

  private SSid = 'id';
  private SSemail = 'email';
  private SSnombres = 'nombres';
  private SSapellidos = 'apellidos';
  private SSrol = 'rol';
  private SSport = 'port';
  private SShabilitaciones = 'habilitaciones';
  private SSproduct = 'producto';

  private SScategorias = 'categorias';
  private SSgrupos = 'grupos';
  private SStipos = 'tipos';

  private SSvendedores = 'vendedores';
  private SSformasPago = 'formasPago';
  private SSestados = 'estados';

  private numProductos = 'numProductos';
  private SSautenticado = 'autenticacion';


  private SShabilitacionVentas = 'habilitacionVentas';
  private SShabilitacionTienda = 'habilitacionTienda';


  private SShabilitacionBot= 'habilitacionBot';

  private SSidentificadorTienda = 'identificadorTienda';
  private SSidentificadorBot = 'identificadorTienda';
  // private SSidentificadorTienda = 'identificadorTienda';

  public username = 'cuenta';
  public autenticado = false;


  constructor(private router: Router, private appService: AppService, @Inject(PLATFORM_ID) private platformId) {
    this.detectAuth();

  }

  setLocal(key: string, data: any) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem(key, JSON.stringify(data))
      }
    } catch (e) {
      console.log(e)
    }
  }

  getLocal(key: string) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        return JSON.parse(localStorage.getItem(key))
      }
    } catch (e) {

    }
  }

  clear(): void {
    try {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.clear()
      }
    } catch (e) {
      console.log('Error cleaning localStorage', e)
    }
  }

  setProduct(data: any) { /// hitman ////
    try {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem(this.SSproduct, JSON.stringify(data))
      }
    } catch (e) {
      console.log(e);
    }
  }
  getProduct() { /// hitman ///
    try {
      if (isPlatformBrowser(this.platformId)) {
        return JSON.parse(localStorage.getItem(this.SSproduct))
      }
    } catch (e) {

    }
  }

  setHabilitacionTienda(data: string) {
    try {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem(this.SShabilitacionTienda, data);
        console.log('guardado en local ', data)

      }
    } catch (e) {
      console.log(e);
    }
  }

  getHabilitacionTienda() {
    try {
      if (isPlatformBrowser(this.platformId)) {
        return localStorage.getItem(this.SShabilitacionTienda);
      }
    } catch (e) {

    }
  }

  // setIdentificadorEmpresa(data: string) {
  //   try {
  //     if (isPlatformBrowser(this.platformId)) {
  //       localStorage.setItem(this.SSidentificadorTienda, data);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

    setHabilitacionBot(data: string){
      try{
        if(isPlatformBrowser(this.platformId)){
          localStorage.setItem(this.SShabilitacionBot, data);
        }
      }catch(e){
        console.log(e);
      }
    }

    getHabilitacionBot(){
      try{
        if(isPlatformBrowser(this.platformId)){
          return localStorage.getItem(this.SShabilitacionBot);
        }
      }catch(e){
        
      }
    }

    setIdentificadorEmpresa(data: string){
      try{
        if(isPlatformBrowser(this.platformId)){
          localStorage.setItem(this.SSidentificadorTienda, data);
        }
      }catch(e){
        console.log(e);
      }
    }

    getIdentificadorEmpresa(){
      try{
        if(isPlatformBrowser(this.platformId)){
          return localStorage.getItem(this.SSidentificadorTienda);
        }
      }catch(e){
        
      }
    }

    setIdentificadorBot(data: string){
      try{
        if(isPlatformBrowser(this.platformId)){
          localStorage.setItem(this.SSidentificadorBot, data);
        }
      }catch(e){
        console.log(e);
      }
    }

    getIdentificadorBot(){
      try{
        if(isPlatformBrowser(this.platformId)){
          return localStorage.getItem(this.SSidentificadorBot);
        }
      }catch(e){
        
      }
    }

    // setIdentificadorEmpresa(data: string){
    //   try{
    //     if(isPlatformBrowser(this.platformId)){
    //       localStorage.setItem(this.SSidentificadorTienda, data);
    //     }
    //   }catch(e){
    //     console.log(e);
    //   }
    // } catch (e) {

    // }
  // }

  clearParameters() {
    // localStorage.setItem(this.SSkey, data.jwt);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
    this.detectAuth();
  }

  setAuth() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSautenticado, 'OK')
      this.appService.autenticacion = 'OK';
    }
  }
  getAuth() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSautenticado);
    }
  }
  delAuth() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSautenticado, 'error');
      this.appService.autenticacion = 'error';
    }
  }
  setToken(data: any) {
    console.log('token guardado', data);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSkey, data);
    }
    //localStorage.setItem(this.SShabilitacionVentas, data.habilitacionVentas);
    //console.log(data.habilitacionVentas);
    this.detectAuth();
  }

  setUsername(data: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSusername, data);
    }
  }
  getUsername() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSusername);
    }
  }

  detectAuth() {
    // console.log(localStorage.getItem(this.SSkey));
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(this.SSkey) === null) {
        localStorage.setItem(this.SSautenticado, 'error');
        // this.appService.usuario.usuarioID = result;
        this.appService.usuario.autenticado = false;
        // this.appService.usuario.username = result.username;
        this.autenticado = false;
        this.username = 'cuenta';
        console.log('error');
      } else {
        localStorage.setItem(this.SSautenticado, 'OK');
        this.autenticado = true;
        this.username = localStorage.getItem(this.SSnombres);
        // this.username = ;
        this.appService.usuario.usuarioID = this.getToken();
        this.appService.usuario.autenticado = true;
        this.appService.usuario.username = this.username;


        this.appService.usuario.habilitacionVentas = this.tytPreGetBool(localStorage.getItem(this.SShabilitacionVentas));

        // console.log(this.appService.usuario.habilitacionVentas);

        // console.log('autenticacion ok', this.username);
      }
    }
  }
  tytPreGetBool(pre) {
    return pre === 'true' ? true : false;
  }
  // ngOnInit() {
  //   localStorage.setItem(this.SSkey, data.token);
  // }

  gotoDefaultPage() {
    this.router.navigate(['/home/']);
  }
  gotoPage(page) {
    this.router.navigate([page], { skipLocationChange: true });
  }


  setCredentials(data: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSkey, data.token);
      localStorage.setItem(this.SSid, data._id);
      localStorage.setItem(this.SSnombres, data.nombres);
      localStorage.setItem(this.SSemail, data.email);
      localStorage.setItem(this.SSapellidos, data.apellidos);
      localStorage.setItem(this.SSrol, data.rol);
      localStorage.setItem(this.SSport, data.port);
      localStorage.setItem(this.SShabilitaciones, JSON.stringify(data.habilitaciones));

      // localStorage.setItem(this.SScategorias, JSON.stringify(data.categorias));
      // localStorage.setItem(this.SSgrupos, JSON.stringify(data.grupos));
      // localStorage.setItem(this.SStipos, JSON.stringify(data.tipos));

      // console.log(data);
      this.setCategorias(data);
      this.setGrupos(data);
      this.setTipos(data);

      localStorage.setItem(this.SSvendedores, JSON.stringify(data.vendedores));
      localStorage.setItem(this.SSformasPago, JSON.stringify(data.formasPago));
      localStorage.setItem(this.SSestados, JSON.stringify(data.estados));

      // localStorage.setItem(this.numProductos, data.numProductos);
      // this.selectOptionService.setCategory(data.categorias);
    }
  }
  setStoreId(data: any) {
    if(isPlatformBrowser(this.platformId)){
      localStorage.setItem(this.SSStore, data);
    }
  }


  setNombres(data: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSnombres, data);
    }
  }
  setApellidos(data: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSapellidos, data.apellidos);
    }
  }

  setRol(data: any) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSapellidos, data.rol);
    }
  }

  getToken() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSkey);
    }
  }

  getIdUser() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSid);
    }
  }

  getNombres() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSnombres);
    }
  }

  getApellidos() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSapellidos);
    }
  }

  getNombreCompleto() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSnombres) + ' ' + localStorage.getItem(this.SSapellidos);
    }
  }

  getRol() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSrol);
    }
  }
  getPort() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSport);
    }
  }

  getEmail() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.SSemail);
    }
  }


  getVendedores() {
    if (isPlatformBrowser(this.platformId)) {
      const aux = JSON.parse(localStorage.getItem(this.SSvendedores));
      return aux;
    }
  }
  getFormasPago() {
    if (isPlatformBrowser(this.platformId)) {
      const aux = JSON.parse(localStorage.getItem(this.SSformasPago));
      return aux;
    }
  }
  getEstados() {
    if (isPlatformBrowser(this.platformId)) {
      const aux = JSON.parse(localStorage.getItem(this.SSestados));
      return aux;
    }
  }
  getNumProductos() {
    if (isPlatformBrowser(this.platformId)) {
      const aux = localStorage.getItem(this.numProductos);
      return aux;
    }
  }

  setNumProductos(value) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.numProductos, value);
    }
  }

  getHabilitaciones(param: string) {
    if (isPlatformBrowser(this.platformId)) {
      const aux = JSON.parse(localStorage.getItem(this.SShabilitaciones));
      //console.log(aux[param]);
      return (aux[param]);
    }
  }

  getAllHabilitaciones() {
    if (isPlatformBrowser(this.platformId)) {
      const aux = JSON.parse(localStorage.getItem(this.SShabilitaciones));
      //console.log(aux[param]);
      return (aux);
    }
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }

  logOut() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
      this.router.navigate(['auth/login']);
    }
  }

  ////////////////////////////// guardado y adquisicion de parametros del negocio///////////////////
  getCategorias() {
    if (isPlatformBrowser(this.platformId)) {
      const aux = JSON.parse(localStorage.getItem(this.SScategorias));
      return aux;
    }
  }
  getGrupos() {
    if (isPlatformBrowser(this.platformId)) {
      const aux = JSON.parse(localStorage.getItem(this.SSgrupos));
      return aux;
    }
  }
  getTipos() {
    if (isPlatformBrowser(this.platformId)) {
      const aux = JSON.parse(localStorage.getItem(this.SStipos));
      return aux;
    }
  }

  setCategorias(data) {
    if (isPlatformBrowser(this.platformId)) {
      // console.log(data.categorias);
      // console.log(JSON.stringify(data.categorias));
      localStorage.setItem(this.SScategorias, JSON.stringify(data.categorias));
    }
  }
  setGrupos(data) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SSgrupos, JSON.stringify(data.grupos));
    }
  }
  setTipos(data) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(this.SStipos, JSON.stringify(data.tipos));
    }
  }


  getMessage(data: any) {
    //    alert(this.key, data.token);
    //     localStorage.setItem(this.id, data._id);
    //     localStorage.setItem(this.nombres, data.nombres);
    //     localStorage.setItem(this.email, data.email);
    //     localStorage.setItem(this.apellidos, data.apellidos);
    //     localStorage.setItem(this.rol, data.rol);
    //     localStorage.setItem(this.habilitaciones, data.habilitaciones);
    //

  }

}
