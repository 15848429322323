import { Component } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AppSettings, Settings} from './app.settings';
import {AuthService} from './services/auth.service';
import {AppService} from './app.service';
import { ApiService } from './services/api.service';
import { ApiUserService } from './services/api.user-service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'uShop';
  loading = false;
  public settings: Settings;

  constructor(public appSettings: AppSettings, public router: Router, private appService: AppService
             , private auth: AuthService, private api: ApiService, private apiUser: ApiUserService,
             public snackBar: MatSnackBar
             ) {
    this.settings = this.appSettings.settings;

    if (this.auth.autenticado) {
      console.log('autenticado');
      this.refreshToken();
    } else {
      console.log('error en autenticacion');
    }

  }

  ngOnInit() {
    console.log('start');
  }


  refreshToken(){

    if(this.auth.getToken() == 'error' || !this.auth.getToken()){

      return; 
    }
    
    let body = {
      "scope": "ushops-platform-kUAwIhqI"
    }

    this.api.refreshToken(body).subscribe(
      result => {
        // this.preloaderLoginForm = false;
        console.log(result)
        console.log(result['token'])
        console.log(result['datos']['estado'])
        this.appService.usuario.estadoCuenta = result['datos']['estado'];

        this.auth.setToken(result['token'])

        this.apiUser.getUsuarioParameters({scope: 'ushops-platform-kUAwIhqI'}).subscribe(
          resultParams => {
            console.log('getUsuarioParameters',resultParams)



            this.snackBar.open('Ingreso exitoso!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
            this.auth.setNombres(result['datos']['nombres'])

            this.auth.setUsername(result['datos']['email']);
            this.auth.setAuth();

            console.log('akisss')

            if(resultParams['empresa']){
              console.log(resultParams['empresa']['estadoTienda'])
              if (resultParams['empresa']['estadoTienda'] === 'habilitado'){
                this.auth.setHabilitacionTienda('true');
                this.appService.usuario.habilitacionTienda = true;
                this.auth.setIdentificadorEmpresa(resultParams.identificadorTienda)
              } else if(resultParams['empresa']['estadoTienda'] === 'solicitud' ) {
                this.auth.setHabilitacionTienda('solicitud');
                this.appService.usuario.habilitacionTienda = false;
                this.auth.setIdentificadorEmpresa('')

              } else {
                console.log('akiii noooo')
                this.auth.setHabilitacionTienda('false');
                this.appService.usuario.habilitacionTienda = false;
                this.auth.setIdentificadorEmpresa('')

              }


              if (resultParams['bot']['estadoBot'] === 'habilitado'){
                this.auth.setHabilitacionBot('true');
                this.appService.usuario.habilitacionBot = true;
                this.auth.setIdentificadorBot(resultParams.identificadorBot)
                
              } else if(resultParams['bot']['estadoBot'] === 'solicitud' ){
                this.auth.setHabilitacionBot('solicitud');
                this.appService.usuario.habilitacionBot = false;
                this.auth.setIdentificadorBot('')

              } else {
                this.auth.setHabilitacionBot('false');
                this.appService.usuario.habilitacionBot = false;
                this.auth.setIdentificadorBot('')

              }



            }

            // if(resultParams['identificadorEmpresa']){
            //   console.log('identificadorEmpresa',resultParams['identificadorEmpresa'])
            //   this.auth.setIdentificadorEmpresa(resultParams['identificadorEmpresa'])

             



            //   // window.location.reload();
            //   this.snackBar.open('actualizado', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });

            //   return ;
            // } else {
            //   this.auth.setHabilitacionBot('false');
            //   this.auth.setHabilitacionTienda('false');
            //   this.appService.usuario.habilitacionBot = false;
            //   this.appService.usuario.habilitacionTienda = false;


            // }


            if (result['datos']['estado']==='cambio contraseña') {
              this.router.navigate(['account/informacion']);
            } else {
              // window.location.reload();
            }
            // } else this.router.navigate(['/']);


            this.snackBar.open('actualizado', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });

            return ;


          }, error=>{
            this.auth.delAuth();
            this.appService.usuario.autenticado = false;
            this.appService.usuario.usuarioID = null;
            this.appService.usuario.username = '';
            this.appService.usuario.habilitacionTienda = false;
            this.auth.clearParameters();

            this.auth.setHabilitacionBot('false');
            this.auth.setHabilitacionTienda('false');

            // this.auth.setToken('null')
            // this.preloaderLoginForm = false; 
            this.snackBar.open('error desconocido!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          }
        )





        
        
        // setTimeout(function(){this.router.navigate(['/products'])}, 5000);
        // this.address = document.location.href;
        // this.router.navigateByUrl('/search', {skipLocationChange: true}).then(() =>
        // this.router.navigate(['/']));
      },
      error => {
        console.log(error)
          // this.preloaderLoginForm = false; 
          this.snackBar.open('Contraseña incorrecta!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    )
  }

}

