
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgmCoreModule } from '@agm/core';

import { OverlayContainer, Overlay } from '@angular/cdk/overlay';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { menuScrollStrategy } from './theme/utils/scroll-strategy';

import { SharedModule } from './shared/shared.module';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TopMenuComponent } from './theme/components/top-menu/top-menu.component';
import { MenuComponent } from './theme/components/menu/menu.component';
import { SidenavMenuComponent } from './theme/components/sidenav-menu/sidenav-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import {MatBadgeModule} from '@angular/material/badge';
import { AppSettings } from './app.settings';
import { AppService } from './app.service';
import { AppInterceptor } from './theme/utils/app-interceptor';
import { OptionsComponent } from './theme/components/options/options.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from './services/auth.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import {ApiService} from './services/api.service';
import {MatMenuModule} from '@angular/material/menu';
import { CookieService } from 'ngx-cookie-service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { VerificationComponent } from './pages/sign-up/verification/verification.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { NgxPaginationModule } from 'ngx-pagination';
import { PipesModule } from './theme/pipes/pipes.module';
import { ProductsComponent } from './pages/products/products.component';
import { ProductComponent } from './pages/products/product/product.component';
import { ProductZoomComponent } from './pages/products/product/product-zoom/product-zoom.component';
import { TestingComponent } from './pages/testing/testing.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ChatbotComponent } from './pages/chatbot/chatbot.component';
import { ValidationComponent } from './pages/sign-up/validation/validation.component';
import { ApiUserService } from './services/api.user-service';
// import { NuevaTiendaModule } from './pages/nuevaTienda/nuevaTienda.module';



@NgModule({
  imports: [
    // NgbModule.forRoot(),
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBDBdKaRVY0pBfdjmpeGH1h5_FT1sxNafY'
    }),
    SharedModule,
    routing,
    NgbModule,
    FlexLayoutModule,
    MatBadgeModule,
    MatMenuModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    NgxPaginationModule,
    PipesModule,

    NgCircleProgressModule.forRoot(),
  ],
  declarations: [
    // NgbModule,
    AppComponent,
    PagesComponent,
    ChatbotComponent,
    NotFoundComponent,
    TopMenuComponent,
    MenuComponent,
    SidenavMenuComponent,
    BreadcrumbComponent,
    OptionsComponent,
    FooterComponent,
    SignUpComponent,
    VerificationComponent,
    ValidationComponent,
    ProductsComponent, 
    ProductComponent, 
    ProductZoomComponent, TestingComponent
  ],
  providers: [
    ApiService,
    ApiUserService,
    AuthService,
    AppSettings,
    AppService,
    CookieService,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay] },
    // { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
