import {Component, OnInit, Input, OnChanges} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {AppService} from '../../app.service';
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit , OnChanges {
  @Input('banners') banners: Array<any> = [];

  bannersOrganizated: Array<any> = [[], [], []];

  X;
  Y;
  Z;
  n;

  sizeWith = 33;
  sizeHeight;

  constructor(public apiService: ApiService, public appService: AppService, public router: Router) {
    this.bannersOrganizated.splice(0, this.bannersOrganizated.length);

    this.bannersOrganizated.push([]);
    this.bannersOrganizated.push([]);
    this.bannersOrganizated.push([]);

  }

  ngOnInit() {
    console.log('banners', this.banners);



  }
  ngOnChanges(changes: any) {
    console.log('cambio', changes);
    console.log('banners cambiados', this.banners.length);

    if (this.banners.length <= 100 && this.banners.length >= 1) {  // requisito principal CRITICO
      this.n = this.banners.length;
      // this.Z = this.n / 18;
      this.Y = this.n / 6;
      this.X = this.n % 6;


      // this.sizeWith = 100  / (this.n / 2);
      console.log('tamaños' , this.sizeWith, this.n);


      this.bannersOrganizated.splice(0, this.bannersOrganizated.length);
      // const auxSize = Number(this.n / 3);
      // console.log(toFixed(auxSize, 0 ));
      // const auxBann = Number(this.n / 3);
      // console.log(toFixed(auxSize, 0 ));

      console.log('banners pre Organizated' , this.bannersOrganizated);

      for (let i = 0; i < this.n ; i++) {
        const auxX = toFixed( i / 2, 0);
        if (this.bannersOrganizated[auxX] === undefined) {
          this.bannersOrganizated.push([]);
          this.bannersOrganizated[auxX].push(this.banners[i]);
        } else {
            this.bannersOrganizated[auxX].push(this.banners[i]);
        }






      // this.bannersOrganizated[auxX][i % 2].push(this.banners[i]);

        // if (i % 6 === 0 || i % 6 === 1) {
        //   this.bannersOrganizated[0][i % 2].push(this.banners[i]);
        // }
        // if (i % 6 === 2 || i % 6 === 3) {
        //   this.bannersOrganizated[1][i % 2].push(this.banners[i]);
        // }
        // if (i % 6 === 4 || i % 6 === 5) {
        //   this.bannersOrganizated[2][i % 2].push(this.banners[i]);
        // }

      }
    } else {
      console.log('no cambiado');

    }

    // this.bannersOrganizated = this.banners.slice(0, 2);
    console.log('bannersOrganizated' , this.bannersOrganizated, this.bannersOrganizated.length);

  }

  public getBanner(index) {
    return this.banners[index];
  }

  public onClick(value) {
    console.log('banner', value);

    let Params = new HttpParams();
    Params = Params.append('departamento', value.title);

    this.apiService.getStores(Params).subscribe(data => {
      if (data === null) {
        console.log('archivo nulo');
      } else {
        const auxStores = [];
        const len = data.length;
        // console.log(len);
        // this.carrousellBanners.splice(0, len);
        for (let i = 0; i < len; i++) {
          const aux = {
            image: this.apiService.getImageURL() + data[i].imagenURL[0].URL + data[i].imagenURL[0].nombreInterno,
            nombreEmpresa: data[i].nombreEmpresa,
            mision: data[i].mision,
            areaPrincipal: data[i].areaPrincipal,
          };
          auxStores.push(aux);
        }
        this.appService.stores = auxStores;
        console.log('Portadas',  this.appService.stores ,  len);
        this.router.navigate(['/stores']);

      }
    });
  }

  public getStores() {

  }


  public getBgImage(URL) {
    const bgImage = {
      'background-image': URL != null ? 'url(' + URL + ')' : 'url(https://via.placeholder.com/600x400/ff0000/fff/)'
    };
    // console.log(bgImage);
    return bgImage;
  }

}

function toFixed(num, fixed) {
  fixed = fixed || 0;
  fixed = Math.pow(10, fixed);
  return Math.floor(num * fixed) / fixed;
}
