import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-main-carousel',
  templateUrl: './main-carousel.component.html',
  styleUrls: ['./main-carousel.component.scss']
})
export class MainCarouselComponent implements OnInit {
  @Input('slides') slides: Array<any> = [];

  // revisar el index del swiper wraper

  public config: SwiperConfigInterface = {};

  // private pagination: SwiperPaginationInterface = {
  //   el: '.swiper-pagination',
  //   clickable: true
  // };

  constructor() { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.config = {
      init : true,
      observer: true,
      slidesPerView: 1,
      spaceBetween: 0,
      initialSlide: 0,
      keyboard: true,
      navigation: true,
      pagination: true,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: false,
      autoplay: {
        delay: 3500,
      },
      speed: 1000,
      effect: 'fade'
    };
  }

  goLink(link){
    console.log("inside-banner", link);
    if(link !== ''){
      window.open(link,'_blank');
    }
  }

}
