import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { Data, AppService } from '../../app.service';
import { Product } from '../../app.models';
import { Stores } from '../../app.models';
import {ApiService} from '../../services/api.service';
import { Vertical } from '@ngu/carousel/lib/ngu-carousel/ngu-carousel';


@Component({
  selector: 'app-proms-carousel',
  templateUrl: './proms-carousel.component.html',
  styleUrls: ['./proms-carousel.component.scss']
})
export class PromsCarouselComponent implements OnInit {

  @Input('products') products: Array<Product> = [];
  public config: SwiperConfigInterface = {};
  public store: Array<Stores> = [];
  // store = [];
  

  constructor(public appService: AppService, public dialog: MatDialog, private router: Router, private  api: ApiService) { }


  ngOnInit() {
   }
  
   getImg(id, imagenes) {
     const final = imagenes.filter(img => img.URL.indexOf(id) > -1);
     return final[0].URL;
  }


  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 8,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      direction: 'vertical',
      breakpoints: {
        100: {
          slidesPerView: 2,
          direction: 'horizontal',
        },
        960: {
          slidesPerView: 4,
          direction: 'vertical',
        }
      }
    }
  }

}