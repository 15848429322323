import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, AppService } from '../../app.service';
import { Product } from '../../app.models';
import { ApiService } from 'src/app/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  // @Input() product: Product;
  @Input('product') product: Product;
  @Input() tipo: string;
  @Input() type: string;
  @Output() favProduct: EventEmitter<any> = new EventEmitter();
  @Output() favStore: EventEmitter<any> = new EventEmitter();
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();

  public count = 1;
  public total = 0;
  public align = 'center center';

  cartProducts = '';
  cartProductsArray = [];

  favoriteProduct: boolean = false;
  favoritesProducts: Array <any>;

  constructor(
    public appService: AppService, 
    public snackBar: MatSnackBar,
    private  api: ApiService,
    private cookieService: CookieService,
    private router: Router
    ) { }

  ngOnInit() {
    if (this.product) {
      // if (this.product.soldQuantity == null) {
      //   this.count = 0;
      //   this.total = 0;
      // } else {
        // this.count = this.product.soldQuantity;
        // this.total = this.product.total;
      // }
     // console.log(this.product);
    }
    this.layoutAlign();

    // this.getFavoritesProducts()
  }

  public layoutAlign() {
    if (this.type === 'all') {
      this.align = 'space-between center';
    } else if (this.type === 'wish') {
      this.align = 'start center';
    } else {
      this.align = 'center center';
    }
  }

  deleteFavoriteProduct() {
    if(this.tipo === 'producto') {
      const body = {
        scope: 'uShops-platform',
        reaccion: 'no me gusta',
        producto: this.product['identificadorProducto']
      }
      this.api.deleteFavoriteProduct(body).subscribe(
        result => {
          console.log(result);
          this.snackBar.open(`${this.product['nombreProducto']} se quitó de Favoritos`, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
          this.favProduct.emit('delete')
        },
        error => {
          console.log(error);
        }
      )
    } else {
      const body = {
        scope: 'uShops-platform',
        reaccion: 'no me gusta',
        store: this.product['identificador']
      }
      this.api.deleteFavoriteStore(body).subscribe(
        result => {
          console.log(result);
          this.snackBar.open(`${this.product['nombreEmpresa']} se quitó de Favoritos`, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
          this.favStore.emit('delete')
        },
        error => {
          console.log(error);
        }
      )
    }
    
  }




  // public increment(count) {
  //   if (this.count < this.product.cantidadDisponible) {
  //     this.count++;
  //     const obj = {
  //       productId: this.product.id,
  //       soldQuantity: this.count,
  //       total: this.count * this.product.valorUnitario
  //     };
  //     this.changeQuantity(obj);
  //   } else {
  //     this.snackBar.open('No existen mas productos en Stock, límite ' + this.count + ' items.',
  //       '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
  //   }
  // }

  // public decrement(count) {
  //   if (this.count > 1) {
  //     this.count--;
  //     const obj = {
  //       productId: this.product.id,
  //       soldQuantity: this.count,
  //       total: this.count * this.product.valorUnitario
  //     };
  //     this.changeQuantity(obj);
  //   }
  // }

  public addToCompare(product: Product) {
    this.appService.addToCompare(product);
  }

  public addToWishList(product: Product) {
    this.appService.addToWishList(product);
  }

  // public addToCart(product: Product) {

  //   if (this.type === 'partial') {
  //     this.count = 1;
  //   }

  //   product.soldQuantity = this.count;
  //   product.total = this.count * this.product.valorUnitario;
  //   this.appService.addToCart(product);
  //   console.log(product);
  // }

  public openProductDialog(event) {
    this.onOpenProductDialog.emit(event);
  }

  // public changeQuantity(value) {

  //   if (this.type === 'wish') {
  //     this.product.soldQuantity = value.soldQuantity;
  //     this.product.total = value.total;
  //     this.onQuantityChange.emit(value);
  //     console.log('onQuantityChange', value);
  //   }
  // }

  

  getDescription(id){
    const body = {
      scope:"uShops-platform",
      device:"web",
      value: id
    }
    this.api.getSingleProduct(body).subscribe(
      result=>{
        console.log('RESULTADO',result);
        return result.descripcionGeneral;
      },
      error=>{
        console.log(error);
        return 'No disponible';
      }
    )
  }

  goProduct(){
    if(this.tipo === 'producto') {
      const body = {
        scope:"uShops-platform",
        device:"web",
        value: this.product.identificadorProducto
      }
      this.api.getSingleProduct(body).subscribe(
        result=>{
          console.log('RESULTADO',result);
          // this.router.navigate(['/producto/v1', result.identificadorEmpresa, result.identificadorProducto]); 
          this.router.navigate(['/producto/v1'],{queryParams: {empresa: result.identificadorEmpresa, codigo: result.identificadorProducto}})
          
          // return result.descripcionGeneral;
        },
        error=>{
          console.log(error);
          // return 'No disponible';
        }
      )
    } else {
      this.router.navigate(['/negocio/v1'],{queryParams: {codigo: this.product['identificador']}})
    }

  }


  addToCart(){
    const productoToCart = {
      nombre: this.product.nombreProducto,
      // descripcionGeneral: this.product.descripcionGeneral, //
      // descripcionGeneral: this.getDescription(this.product.identificadorProducto), //
      descripcionGeneral: '',
      imagenPrincipal: this.getImg(this.product.imagenPrincipal, this.product.images),
      valorUnitario: this.product.valorUnitario,
      cantidad: 1,
      identificadorProducto: this.product.identificadorProducto
    }
    console.log('CARRITO', productoToCart);
    this.cartProductsArray = [];
    if (this.cookieService.check('userCart') && this.cookieService.get('userCart') !== ''){
      this.cartProducts = this.cookieService.get('userCart');
      this.cartProducts.split('|').forEach(element => {
        this.cartProductsArray.push(JSON.parse(element));
      });
    }
    console.log(this.cartProductsArray)
    const index = this.cartProductsArray.findIndex(product => product.nombre === this.product.nombreProducto);
    console.log(index);
    if (index === -1){ 
      if(this.cartProducts === ''){
        this.cartProducts = JSON.stringify(productoToCart)
      }else{
        this.cartProducts = this.cartProducts + '|' + JSON.stringify(productoToCart)
      }
      console.log(this.cartProducts)
      this.cookieService.set('userCart',this.cartProducts);
      let sb = this.snackBar.open('El producto se ha añadido a tu carrito', 'x', {
        duration: 3000,
        panelClass: 'notify-ok'
      });
      sb.onAction().subscribe(() => {
        sb.dismiss();
      });
    }
    else{
      let sb = this.snackBar.open('El producto ya está en tu carrito', 'x', {
        duration: 3000,
        panelClass: 'notify-warn'
      });
      sb.onAction().subscribe(() => {
        sb.dismiss();
      });
    }


  }

  favorProduct(fav_prod) {
    var index = fav_prod.indexOf(fav_prod.find(item => item.identificadorProducto === this.product.identificadorProducto));
    if(index === -1) {
      document.getElementById('like-product').style.color = "none";
      return false
    } else {
      document.getElementById('like-product').style.color = "#FF0000";
      return true
    }
  }

  getFavoritesProducts() {
    const body = {
      scope: 'uShops-platform',
      device: 'web',
    }
    this.api.getFavoritesProducts(body).subscribe(
      result => {
        this.favoriteProduct = this.favorProduct(result);
      },
      error => {
        console.log(error);
      }
    )
  }

  addFavoriteProduct() {
    
    if(this.favoriteProduct === true) {
      const body = {
        scope: 'uShops-platform',
        reaccion: 'no me gusta',
        producto: this.product.identificadorProducto
      };
      this.api.deleteFavoriteProduct(body).subscribe(
        result => {
          this.snackBar.open(`${this.product.nombreProducto} se quitó de Favoritos`, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
          this.favoriteProduct = false;
          this.favProduct.emit('delete')
          document.getElementById('like-product').style.color = "none";
        },
        error => {
          console.log(error);
        }
      )
      
    } else {
      const body = {
        scope: 'uShops-platform',
        reaccion: 'me gusta',
        producto: this.product.identificadorProducto
      };
      this.api.addFavoriteProduct(body).subscribe(
        result => {
          this.favoriteProduct = true;
          this.snackBar.open(`${this.product.nombreProducto} agregado a Favoritos`, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          document.getElementById('like-product').style.color = "#FF0000";
        },
        error => {
          console.log(error);
        }
      )
    }
    

  }

  getImg(id, imagenes){
    if (imagenes.length > 1) {
      const final = imagenes.filter(img => img?.URL.indexOf(id) > -1)
      if (final !== undefined) {
        return final[0].URL;
      }
    } else {
      return imagenes[0].URL;
    }
  }

}
