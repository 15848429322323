import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class ChatbotConfigurationService {

    ipPublica = environment.urlApi;
    baseChatbotWhatsappUrl = this.ipPublica + '/ushops/xpertAssistant';
    baseChatbotGeneralUrl = this.ipPublica + '/ushops/salesxpertGeneral';
    baseChatbotlUrl = this.ipPublica + '/ushops/salesxpert';

    constructor(private http: HttpClient, private auth: AuthService) {
    }

    getCompanyFunctions(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerFuncionesEmpresa`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    getCompanyData(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerDatosEmpresa`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    getQRCode(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        // return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerQR`, parameters, options)
        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerQR`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    logoutQR(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        // return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerQR`, parameters, options)
        return this.http.patch(`${this.baseChatbotWhatsappUrl}/logoutQR`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    getStageData(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerEtapasChatbot`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    getChatbotConfig(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerConfiguracionesChatbot`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    updateChatbotConfig(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/actualizarConfiguracionesChatbot`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    updateConversation(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/actualizarConversacion`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    getConversations(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerConversaciones`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    createConversation(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/crearConversacion`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    deleteConversation(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/eliminarConversacion`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    updateCompanyFunctions(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/actualizarFuncionEmpresa`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    updateCompanyData(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/actualizarDatosEmpresa`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    getBotData(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerDatosBot`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    updateBotData(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/editarDatosBot`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    updateChatbotStateData(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/actualizarDatosEstadoChatbot`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    getSentinelData(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerEmpresaSentinel`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    updateSentinelData(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/editarEmpresaSentinel`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    uploadImageS3(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/subirImagenRespuesta`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    uploadVideoS3(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/subirVideoS3`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    uploadFileS3(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/subirArchivoS3`, parameters, options)
            .pipe(catchError(this.handleError));
    }

    getKeyGeneral(forms) {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };
        return this.http.patch(`${this.baseChatbotGeneralUrl}/getKeyGeneral`, forms, options)
            .pipe(catchError(this.handleError));
    }




    handleError(error: HttpErrorResponse) {
        console.log('error desconocido: ', error);
        if (error.error instanceof ErrorEvent) {
        } else {
            if (error.status === 401) {
                console.error('no autorizado:');
                localStorage.removeItem('jwt');
            }
        }
        return throwError(new Error(error.statusText));
    }

}