import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {Form, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';


declare var $ : any; 
@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class FormularioComponent implements OnInit {

  haveExternalLink = '';
  registerStore: FormGroup;
  tipoIdentificacion = new FormControl('');
  contactNumbers = [];
  datosEnvio = [];
  categoriesToShow = []
  preloaderStoreForm = false ;
  storeFormInit = {
    nombreEncargado: '',
    direccion: '',
    sector: '',
    link: '',
    ruc: '',
    nombreEmpresa: '',
    telefonos : [],
    cedula: '',
    area: [],
    pais: '',
    provincia: '',
    ciudad: '',
    haveExternalLink: '',
    tipoIdentificacion: '',
    tiendaFisica:'si'
  };
  availableForm = false;
  prueba = [];

  initTelefonosValues = []
  


  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    public snackBar: MatSnackBar
  ) {
    const body = {
      scope: 'uShops-platform',
      device: 'web',
    }
    this.apiService.getAllCategorias(body).subscribe(
      result => {
        console.log(result);
        result.forEach(element => {
          this.categoriesToShow.push(element['titulo']);
        });
        console.log(this.categoriesToShow);
      },
      error => {},
    )
   }
   
  ngOnInit(): void {
    this.registerStore = this.formBuilder.group({
      // nombreEncargado: new FormControl({ value : this.storeFormInit.nombreEncargado, disabled: this.availableForm}, [Validators.compose([Validators.required, Validators.minLength(5)])]),
      // direccion: new FormControl({value : this.storeFormInit.direccion, disabled: this.availableForm}, [Validators.compose([Validators.required, Validators.minLength(5)])]),
      // sector: new FormControl({value: this.storeFormInit.sector ,disabled: this.availableForm},  [Validators.compose([Validators.required, Validators.minLength(5)])]),
      // tipoIdentificacion : new FormControl({value: this.storeFormInit.tipoIdentificacion, disabled: this.availableForm}),
      // ruc: new FormControl({value: this.storeFormInit.ruc, disabled: this.availableForm}),
      // cedula: new FormControl({value: this.storeFormInit.cedula ,disabled: this.availableForm}) ,
      // nombreEmpresa: new FormControl({value: this.storeFormInit.nombreEmpresa, disabled: this.availableForm},[Validators.compose([Validators.required, Validators.minLength(5)])]),
      // telefonos: this.formBuilder.array([]), 
      // area: new FormControl({value: this.storeFormInit.area, disabled: this.availableForm}, [Validators.required]),
      // haveExternalLink: new FormControl({value: this.storeFormInit.haveExternalLink, disabled: this.availableForm}, [Validators.required]),
      // link:new FormControl({value: this.storeFormInit.link, disabled: this.availableForm}),
      // ciudad: new FormControl({value: this.storeFormInit.ciudad, disabled: this.availableForm},[Validators.required]),
      // tiendaFisica : new FormControl({value:this.storeFormInit.tiendaFisica, disabled: this.availableForm},[Validators.required]),
      nombreEncargado: new FormControl({ value : this.storeFormInit.nombreEncargado, disabled: this.availableForm}, [Validators.compose([Validators.required, Validators.minLength(5)])]),
      tipoIdentificacion : new FormControl({value: this.storeFormInit.tipoIdentificacion, disabled: this.availableForm}, [Validators.required]),
      cedula: new FormControl({value: this.storeFormInit.cedula ,disabled: this.availableForm}) ,
      ruc: new FormControl({value: this.storeFormInit.ruc, disabled: this.availableForm}),
      // nombreEmpresa: new FormControl({value: this.storeFormInit.nombreEmpresa, disabled: this.availableForm},[Validators.compose([Validators.required, Validators.minLength(5)])]),
      // sector: new FormControl({value: this.storeFormInit.sector ,disabled: this.availableForm},  [Validators.compose([Validators.required])]),
      // direccion: new FormControl({value : this.storeFormInit.direccion, disabled: this.availableForm}, [Validators.compose([Validators.required, Validators.minLength(5)])]),
      // telefonos: this.formBuilder.array([]), 
      // area: new FormControl({value: this.storeFormInit.area, disabled: this.availableForm}, [Validators.required]),
      // haveExternalLink: new FormControl({value: this.storeFormInit.haveExternalLink, disabled: this.availableForm}, [Validators.required]),
      // link:new FormControl({value: this.storeFormInit.link, disabled: this.availableForm}),
      // pais: new FormControl({value: this.storeFormInit.pais, disabled: this.availableForm},[Validators.required]),
      // provincia: new FormControl({value: this.storeFormInit.provincia, disabled: this.availableForm},[Validators.required]),
      ciudad: new FormControl({value: this.storeFormInit.ciudad, disabled: this.availableForm},[Validators.required]),
      // tiendaFisica : new FormControl({value:this.storeFormInit.tiendaFisica, disabled: this.availableForm},[Validators.required]),
      telefono: new FormControl({value: this.storeFormInit.ciudad, disabled: this.availableForm},[Validators.required]),
      nombreAsistente: new FormControl({value: this.storeFormInit.ciudad, disabled: this.availableForm},[Validators.required]),

    });
    const body = {
      scope: 'ushops-platform-kUAwIhqI',
      device: 'web',
    }
    // this.apiService.getFormStore(body).subscribe(
    //   result => {
    //     console.log(result);
        
    //     if( result['nombreEncargado'] !== undefined) { this.storeFormInit.nombreEncargado = result['nombreEncargado']; }
    //     if( result['direccion'] !== undefined) { this.storeFormInit.direccion = result['direccion']; }
    //     if( result['sector']!== undefined) { this.storeFormInit.sector = result['sector']; }
    //     if( result['link']!== undefined) { this.storeFormInit.link = result['link']; this.storeFormInit.haveExternalLink = 'si'} else {this.storeFormInit.haveExternalLink = 'no'}
    //     if( result['nombreEmpresa']!== undefined) { this.storeFormInit.nombreEmpresa = result['nombreEmpresa']; }
    //     if( result['area']!== undefined) { this.storeFormInit.area = result['area']; }
    //     if( result['pais']!== undefined) { this.storeFormInit.pais = result['pais']; }
    //     if( result['provincia']!== undefined) { this.storeFormInit.provincia = result['provincia']; }
    //     if( result['ciudad']!== undefined) { this.storeFormInit.ciudad = result['ciudad']; }
    //     if( result['tiendaFisica']!== undefined) { this.storeFormInit.tiendaFisica = result['tiendaFisica']; }
    //     if( result['cedulaRuc']!== undefined) { 
    //       if(result['cedulaRuc'].length === 10) {
    //         this.storeFormInit.tipoIdentificacion = 'cedula';
    //         this.storeFormInit.cedula = result['cedulaRuc'];
    //       } else {
    //         this.storeFormInit.tipoIdentificacion = 'ruc';
    //         this.storeFormInit.ruc = result['cedulaRuc'];
    //       }
    //     }
    //     if(result['telefonos'] !== undefined){
    //       result['telefonos'].forEach(element => {
    //         this.initTelefonosValues.push({
    //           numero: element['numero'],
    //           principal: element['principal'],
    //           tipo: element['tipo'],
    //           whatsapp: element['whatsapp'],
    //           prefijo: element['prefijo'],
    //         });
    //         console.log(this.initTelefonosValues);
    //       });
    //     }
        
    //     console.log(this.initTelefonosValues);

    //     if(this.initTelefonosValues.length === 0){
    //       this.addNumber();
    //     }


        
        
    //     if(result['estado'] === 'creacion'){
    //       this.availableForm = false;
    //       this.registerStore.enable()
    //     } else {
    //       this.availableForm = true;
    //       this.registerStore.disable();
    //       this.registerStore.reset(this.storeFormInit);
    //     }
    //     console.log(this.storeFormInit);
    //     this.registerStore.reset(this.storeFormInit);
    //     this.telefonos.reset();
    //   },
    //   error => {
    //     this.addNumber();
    //   });

    // this.registerStore.get('haveExternalLink').valueChanges.subscribe(
    //   Value => {
    //     console.log(Value)
    //     const link = this.registerStore.get('link');
    //     if (Value === 'si'){
    //       link.setValidators(Validators.required)
    //     } else {
    //       link.clearValidators();
    //     }
    //     link.updateValueAndValidity();
    // });

    // this.registerStore.get('tipoIdentificacion').valueChanges.subscribe(
    //   Value => {
    //     console.log(Value)
    //     const cedula = this.registerStore.get('cedula');
    //     const ruc = this.registerStore.get('ruc');
    //     if (Value === 'cedula') {
    //       cedula.setValidators(Validators.compose([Validators.required, Validators.minLength(10),Validators.maxLength(10)]))
    //       ruc.clearValidators();
    //     } else if (Value === 'ruc') {
    //       ruc.setValidators(Validators.compose([Validators.required, Validators.minLength(13),Validators.maxLength(13)]))
    //       cedula.clearValidators();
    //     }
    //     cedula.updateValueAndValidity();
    //     ruc.updateValueAndValidity();
    // });
    
  }




  get telefonos(){
    return this.registerStore.get('telefonos') as FormArray;
  }


  aux1(event: any) {
    console.log(event.value);
    console.log(this.haveExternalLink);
  }

  updateStoreForm(event: any) {
    let body = {};
    let name = '';
    if( event.target.name === 'cedula' || event.target.name ==='ruc'){
      body = {
        scope: 'uShops-platform',
        device: 'web',
        parametro : 'cedulaRuc',
        value: event.target.value
      };
      name = 'cedulaRuc'
    } else {
      body = {
        scope: 'uShops-platform',
        device: 'web',
        parametro : event.target.name,
        value: event.target.value
      };
      name = event.target.value;
    }

    const index = this.datosEnvio.findIndex(dato => dato.parametro === name);
    if (index !== -1) {
      this.datosEnvio[index].value = event.target.value;
    } else {
      this.datosEnvio.push(body);
    }
    console.log(body)
    console.log(this.datosEnvio);
  }

  addNumber() {
    // console.log(valueForm);
    // if(this.contactNumbers.findIndex(dato => dato.numero === valueForm['telefono'] ) === -1 && valueForm['telefono']!=='') {
    //   const number = {
    //     numero: valueForm['telefono'],
    //     whatsapp : true,
    //     tipo: 'trabajo',
    //     prefijo: '+593',
    //     principal: false,
    //   }
    //   this.contactNumbers.push(number);
    // }
    // const body = {
    //   scope: 'uShops-platform',
    //   device: 'web',
    //   parametro : 'telefonos',
    //   value: this.contactNumbers
    // };

    // const index = this.datosEnvio.findIndex(dato => dato.parametro === 'telefonos');
    // if(index !==-1) { this.datosEnvio[index].value = this.contactNumbers; }
    // else { this.datosEnvio.push(body); }
    // console.log(body);
    // console.log(this.datosEnvio);

    
    
    
    // const telefonoFormGroup = this.formBuilder.group({
    //   telefono: '',
    //   principal: false,
    //   tipo: 'casa',
    //   whatsapp: false, 
    // })

    // const telefonoFormGroup = this.formBuilder.group({
    //   numero: '',
    //   prefijo: '+593',
    //   principal: false,
    //   tipo: 'casa',
    //   whatsapp: false, 
    // })

    // this.telefonos.push(telefonoFormGroup)
  }

  probando(index: number, event: any){
    console.log(index);
    console.log(event)
  }
  deleteNumber(index: number) {
    // if(!this.availableForm){
    //   this.contactNumbers.splice(index,1);
    //   const body = {
    //     scope: 'uShops-platform',
    //     device: 'web',
    //     parametro : 'telefonos',
    //     value: this.contactNumbers
    //   };
  
    //   const index2 = this.datosEnvio.findIndex(dato => dato.parametro === 'telefonos');
    //   if(index2 !==-1) { this.datosEnvio[index2].value = this.contactNumbers; }
    //   else { this.datosEnvio.push(body); }
    //   console.log(body);
    //   console.log(this.datosEnvio);
    // }
    this.telefonos.removeAt(index)
  }

  editNumber(index: number){
    const telefonoFormGroup = this.formBuilder.group({
      numero: this.initTelefonosValues[index]['numero'],
      principal: this.initTelefonosValues[index]['principal'],
      tipo: this.initTelefonosValues[index]['tipo'],
      whatsapp: this.initTelefonosValues[index]['whatsapp'], 
      prefijo: this.initTelefonosValues[index]['prefijo'], 
    })
    this.telefonos.push(telefonoFormGroup)
    this.deleteExistNumber(index);
  }

  deleteExistNumber(index: number){
    this.initTelefonosValues.splice(index,1);
  }

  submitForm(){

    console.log(this.registerStore.value['tipoIdentificacion']);
    console.log(this.datosEnvio.length)
    console.log(this.registerStore.valid)
    if(this.registerStore.valid){
      this.preloaderStoreForm = true;
      console.log(this.datosEnvio);

      const body = {
        scope: 'uShops-platform',
        device: 'web',
        parametro : 'area',
        value: this.registerStore.value['area']
      };
      const indexArea = this.datosEnvio.findIndex(dato => dato.parametro === 'area');
      if( indexArea === -1){
        this.datosEnvio.push(body);
      }

      const body2 = {
        scope: 'uShops-platform',
        device: 'web',
        parametro : 'tiendaFisica',
        value: this.registerStore.value['tiendaFisica'],
      };

      const indexTienda = this.datosEnvio.findIndex(dato => dato.parametro === 'tiendaFisica');
      if( indexTienda === -1){
        this.datosEnvio.push(body2);
      }

    let telefonosEnvio = [];
    this.initTelefonosValues.forEach(element => {
      telefonosEnvio.push(element);
    });
    this.registerStore.value['telefonos'].forEach(element => {
      telefonosEnvio.push(element);
    });
    console.log(telefonosEnvio);
    const body3 = {
        scope: 'uShops-platform',
        device: 'web',
        parametro : 'telefonos',
        value: telefonosEnvio,
    }

    const indexTelefonos = this.datosEnvio.findIndex(dato => dato.parametro === 'telefonos');
    if(indexTelefonos !==-1) { this.datosEnvio[indexTelefonos].value = this.contactNumbers; }
    else { this.datosEnvio.push(body3); }

      console.log('dato a enviar', this.datosEnvio);
      let i = 0;
      this.datosEnvio.forEach(element => {
        this.apiService.updateFormStore(element).subscribe(
          result => {
            console.log(result);
            console.log('REGISTRO EXITOSO')
          },
        )
        i++;
        if(i === this.datosEnvio.length){
          this.preloaderStoreForm = false;
          $('.modal-window').addClass('active');
        }
      });
    }
   
    console.log(this.registerStore.value);
  }


  SubmitRegister() {

    // var ventana = document.getElementById('modal-new-bot');
    // ventana.style.display = 'block';
    // console.log('modal')
    // console.log(ventana)



    // return;
    
    // let telefonosEnvio = [];
    // this.initTelefonosValues.forEach(element => {
    //   telefonosEnvio.push(element);
    // });
    // this.registerStore.value['telefonos'].forEach(element => {
    //   telefonosEnvio.push(element);
    // });

    var auxID = '';
    if(this.registerStore.get('tipoIdentificacion').value === 'ruc'){
      auxID = this.registerStore.get('ruc').value;
    } else {
      auxID = this.registerStore.get('cedula').value;
    }

    const body = {
      scope: 'ushops-platform-kUAwIhqI',
      device: 'web',
      nombreEncargado: this.registerStore.get('nombreEncargado').value,
      // direccion: this.registerStore.get('direccion').value,
      // link: this.registerStore.get('link').value,
      cedulaRuc: auxID,
      // sector: this.registerStore.get('sector').value,
      ciudad: this.registerStore.get('ciudad').value,
      // pais: this.registerStore.get('pais').value,
      // provincia: this.registerStore.get('provincia').value,
      // nombreEmpresa: this.registerStore.get('nombreEmpresa').value,
      // telefonos: this.registerStore.get('telefonos').value,
      
      telefono: this.registerStore.get('telefono').value,
      nombreAsistente: this.registerStore.get('nombreAsistente').value,



      // telefonos: telefonosEnvio,
      // area: this.registerStore.get('area').value,
      // tiendaFisica: this.registerStore.get('tiendaFisica').value
    }

    if(body['cedulaRuc'].length === null) {
      this.snackBar.open('Un documento de identificación es necesario', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }

    if (this.registerStore.valid && auxID !== null) {
      // if (body['telefonos'].length > 0) {
        this.apiService.requestBot(body).subscribe(
          result => {
            console.log('requestBot succesfull',result)
            
            var ventana = document.getElementById('modal-new-bot');
            ventana.style.display = 'block';
          },
          error => {
            console.log('Error al registrar tienda', error)
            this.snackBar.open('Lo sentimos, tenemos dificultades con tu solicitud', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
          },
        )
      // } else {
      //   this.snackBar.open('Debes ingresar por lo menos un teléfono', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      // }
    } else {
      this.snackBar.open('Revisa que todos los parámetros estén llenos', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }


  }

  closeModal(){
    $('.modal-window').removeClass('active');
    this.router.navigate(['/']);
  }

}
