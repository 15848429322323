import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
// import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit, OnDestroy {

  private socket: any;

  newMessage: string = "";
  showChat: boolean = false;
  historialMensajes: any = [];

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.connectSocketServer();
  }

  ngOnDestroy(): void {
    if (this.socket) {
      this.socket.close();
      console.log('Socket closed');
    }
  }

  connectSocketServer() {
    const socketUrl = 'ws://52.42.103.199:45500';
    this.socket = new WebSocket(socketUrl);

    this.socket.onopen = () => {
      console.log('Connected to socket server');
      // console.log('info usuario: ', this.authService.getIdentificadorEmpresa());
      let id_company = this.authService.getIdentificadorEmpresa();
      if(id_company){
        this.socket.send(JSON.stringify({idEmisor: id_company, action: 'deleteHistory'}));
        this.socket.send(JSON.stringify({idEmisor: id_company, action: 'getHistory'}));
      }else{
        this.socket.send(JSON.stringify({idEmisor: 'usuario', action: 'deleteHistory'}));
        this.socket.send(JSON.stringify({idEmisor: 'usuario', action: 'getHistory'}));
      }
    };

    this.socket.onmessage = (event: any) => {
      const data = JSON.parse(event.data);
      // console.log('Received history:', data);
      this.historialMensajes = data;
    };
  }

  openChat() {
    this.showChat = true;
    if (this.historialMensajes && this.historialMensajes.length > 0) {
      setTimeout(() => {
        this.scrollToLastMessage();
      }, 200);
    }
  }

  sendMessage() {
    console.log('message: ', this.newMessage);

    if (this.newMessage && this.newMessage !== '') {

      let id_company = this.authService.getIdentificadorEmpresa();

      if(id_company){
        this.socket.send(JSON.stringify({
          idEmisor: id_company,
          texto: this.newMessage
        }));
      }else{
        this.socket.send(JSON.stringify({
          idEmisor: 'usuario',
          texto: this.newMessage
        }));
      }
    }

    this.newMessage = "";

    setTimeout(() => {
      if(this.historialMensajes.length > 0) this.scrollToLastMessage();
    }, 200);
  }

  scrollToLastMessage() {

    let elements = document.getElementsByClassName('msj');
    let lastElement: any = elements[(elements.length - 1)];
    let offset = lastElement.offsetTop;

    //@ts-ignore
    document.getElementById('message-container').scrollTop = offset;
  }

}
