import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { VerificationComponent } from './pages/sign-up/verification/verification.component';
import { ProductsComponent } from './pages/products/products.component';
import { ProductComponent } from './pages/products/product/product.component';
import { TestingComponent } from './pages/testing/testing.component';
import { ValidationComponent } from './pages/sign-up/validation/validation.component';

export const routes: Routes = [
    {
        path: '',
        component: PagesComponent, children: [
            { path: '', loadChildren: './pages/home/home.module#HomeModule' },
            
            { path: 'testing', component: TestingComponent },

            { path: 'sign-up', component: SignUpComponent, pathMatch: 'full', data: { breadcrumb: 'Registro ' } },
            { path: 'sign-up/verification', component: VerificationComponent },
            { path: 'basic-validate', component: ValidationComponent },
            { path: 'producto', component: ProductsComponent, pathMatch: 'full', data: { breadcrumb: 'Productos y Tiendas' } },
            { path: 'producto/:single_product', component: ProductComponent, data: { breadcrumb: '' } },
            { path: 'producto/search/:tipo/:texto', component: ProductsComponent, data: { breadcrumb: '' } },
            { path: 'producto/search/:tipo/:categoria/:texto', component: ProductsComponent, data: { breadcrumb: 'Búsqueda por categoría' } },
            { path: 'producto/search/:tipo/total/:indicador/:texto', component: ProductsComponent, data: { breadcrumb: 'Ver todo' } },
            { path: 'producto/search/:tipo/:categoria/total/:indicador/:texto', component: ProductsComponent, data: { breadcrumb: 'Ver todo' } },
            { path: 'producto/:store/:product', component: ProductComponent },

            { path: 'account', loadChildren: './pages/account/account.module#AccountModule', data: { breadcrumb: 'Configuración de cuenta ' } },
         //   { path: 'shoppings', loadChildren: './pages/shopping-list/shopping-list.module#ShoppingListModule', data: { breadcrumb: 'Mis compras' } },
            // { path: 'compare', loadChildren: './pages/compare/compare.module#CompareModule', data: { breadcrumb: 'Compare' } },
            // { path: 'wishlist', loadChildren: './pages/wishlist/wishlist.module#WishlistModule', data: { breadcrumb: 'Wishlist' } },
            { path: 'cart', loadChildren: './pages/cart/cart.module#CartModule', data: { breadcrumb: 'Mis compras ' } },
            { path: 'checkout', loadChildren: './pages/checkout/checkout.module#CheckoutModule', data: { breadcrumb: 'Checkout ' } },
            // { path: 'contact', loadChildren: './pages/contact/contact.module#ContactModule', data: { breadcrumb: 'Contact' } },
            { path: 'sign-in', loadChildren: './pages/sign-in/sign-in.module#SignInModule', data: { breadcrumb: 'Iniciar sesión ' } },
            // { path: 'sign-up', loadChildren: './pages/sign-up/sign-up.module#SignUpModule', data: { breadcrumb: 'Registro ' } },
            // { path: 'brands', loadChildren: './pages/brands/brands.module#BrandsModule', data: { breadcrumb: 'Brands' } },
            { path: 'busqueda', loadChildren: './pages/search/search.module#SearchModule', data: { breadcrumb: 'Productos y Tiendas' } },
            // { path: 'producto', loadChildren: './pages/products/products.module#ProductsModule', data: { breadcrumb: 'Productos y Tiendas' } },
            { path: 'negocio', loadChildren: './pages/stores/stores.module#StoresModule', data: { breadcrumb: 'Tiendas en uShops' } },
            { path: 'stores', loadChildren: './pages/stores/stores.module#StoresModule', data: { breadcrumb: 'Tiendas en uShops' } },
            { path: 'products', loadChildren: './pages/products/products.module#ProductsModule', data: { breadcrumb: 'Productos y Tiendas' } },
            { path: 'categories', loadChildren: './pages/categories/categories.module#CategoriesModule', data: { breadcrumb: 'Categorias' } },
            { path: 'favorites', loadChildren: './pages/favorites/favorites.module#FavoritesModule', data: { breadcrumb: 'Favoritos ' } },
            // { path: 'tienda', loadChildren: './pages/stores/tienda1/tienda1.module#Tienda1Module', data: { breadcrumb: 'Tienda1' } },
            { path: 'newProduct', loadChildren: './pages/nuevoProducto/nuevoProducto.module#NuevoProductoModule', data: { breadcrumb: 'Nuevo Producto' } },
            { path: 'editProduct', loadChildren: './pages/edicionProducto/edicionProducto.module#EdicionProductoModule', data: { breadcrumb: 'Editar Producto' } },
            // { path: 'editStore', loadChildren: './pages/edicionTienda/edicionTienda.module#EdicionTiendaModule', data: { breadcrumb: 'Editar Tienda' }},
            // { path: 'newStore', loadChildren: './pages/nuevaTienda/nuevaTienda.module#NuevaTiendaModule', data: { breadcrumb: 'Nueva Tienda' } },
            // { path: 'search', loadChildren: './pages/search/search.module#SearchModule', data: { breadcrumb: 'Search' } },
            { path: '**', loadChildren: './pages/sign-in/sign-in.module#SignInModule', data: { breadcrumb: 'Iniciar sesión ' } },

        ],

    },
 
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
});
