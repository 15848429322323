import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';


@Component({
  selector: 'app-main-banner',
  templateUrl: './main-banner.component.html',
  styleUrls: ['./main-banner.component.scss']
})
export class MainBannerComponent implements OnInit {

  @Input('slides') slides: Array<any> = [];

  public config: SwiperConfigInterface = {};

  banner_info: Array<any> = [
    {
      image: './../assets/images/Bag-Commerce.svg',
      text1: 'Compra',
      text2: 'Fácil y rápido, encuentra todo en un solo lugar',
      text3: 'Regístrate gratis'
    },
    {
      image: './../assets/images/Order-Computer.svg',
      text1: 'Vende',
      text2: 'Ofrece tu catálogo de productos',
      text3: 'Crea tu tienda'
    },
    {
      image: './../assets/images/ushops-cart.png',
      text1: 'uShops',
      text2: 'Gestiona la venta, el pago y la entrega en un solo sitio',
      text3: 'Conoce más'
    },
  ]
  
  constructor(
    private router: Router,
    public appService: AppService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.config = {
      // init : true,
      observer: true,
      slidesPerView: 1,
      spaceBetween: 0,
      initialSlide: 0,
      keyboard: false,
      navigation: true,
      // pagination: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      // preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 4000,
      },
      speed: 4000,
      effect: 'fade'
    };

  }

  goLink(link){
    console.log("inside-banner", link);
    if(link !== ''){
      window.open(link,'_blank');
    }
  }

  redirect(item) {
    switch (item) {
      case 'Compra':
        this.router.navigate(['/sign-up']);
        break;
      case 'Vende gratis':
        if(this.appService.usuario.autenticado==false)   {
          this.router.navigate(['/sign-up']);
        } else {
          this.router.navigate(['/account/informacion']);
        }
        break;
      case 'uShops':
        window.open('https://info.ushops.tech/home','_blank');
        break;
    
      default:
        break;
    }
  }

}
