import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor, HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, ObservedValueOf } from 'rxjs';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiUserService {

  // ipPublica = 'http://34.217.240.171:';
  //ipPublica = 'http://localhost:';
  // ipPublica = 'http://54.70.216.182:80'
  // ipPublica = 'https://pruebas.ushops.tech';
  // ipPublica = 'https://pruebas2.egm-robotics.com';
  // ipPublica = 'https://api.egm-robotics.com';
  ipPublica = environment.urlApi;
  
  puerto = '';

  // authUrl = this.ipPublica + '40000/api';
  // basePublicUrl = this.ipPublica + '40200/api';
  // baseAuthUrl = this.ipPublica + '50200/api';

  // authUrl = 'https://localhost:443/ushops/authentication';
  // basePublicUrl = 'https://localhost:443/api';
  // baseAuthUrl = 'https://localhost:443/api';

  authUrl = this.ipPublica ;
  // authUrl = this.ipPublica + '/ushops';
  basePublicUrl = this.ipPublica + this.puerto + '/ushops/serviciosGenerales';
  baseAuthUrl = this.ipPublica + this.puerto + '/ushops/serviciosAutentication';
  baseProductsUrl = this.ipPublica + '/ushops/servicios/ServAuth'
  baseChatbotUrl = 'https://pruebas2.egm-robotics.com/api/chatbot'
  baseImgUrl = this.ipPublica + this.puerto + '/ushops/imagenes';
  baseUploadFilesUrl = this.ipPublica + this.puerto + '/ushops/uploadFiles';
  baseGenerales = this.ipPublica + '/ushops/generales/ServGen';
  baseServicios = this.ipPublica + '/ushops/servicios/ServAuth';
  baseNotificaciones = this.ipPublica + '/ushops/notificaciones/NotifAuth'

  baseGenerales2 = this.ipPublica + '/ushops/generales/ServGen';
  // baseGenerales2 = this.ipPublica + '/ushops/generales2/ServGen';

  baseCart = this.ipPublica + '/ushops/1v1/carrito/ServCart'
  baseFavoritos = this.ipPublica + '/ushops/favoritos'

  // getBasePublicURL() {
  //   return (this.baseImgUrl);
  // }

  getBaseImageURL() {
    return (this.baseImgUrl);
  }
  getImageURL() {
    return (this.baseImgUrl + '/imagenes/general/getImg?path=');
  }
  getBaseAuthURL() {
    return (this.baseAuthUrl);
  }
  getUploadFilesURL() {
    return (`${this.baseUploadFilesUrl}/image`);
  }


  constructor(private http: HttpClient, private auth: AuthService) { }


  getUsuarioParameters(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    console.log('getUsuarioParameters', `${this.baseServicios}/usuario/getUsuarioParameters`,this.auth.getToken() )
    return this.http.patch(`${this.baseServicios}/usuario/getUsuarioParameters`, parameters, options)
      .pipe(catchError(this.handleError));
  }


  handleError(error: HttpErrorResponse) {
    // console.log('error desconocido: ', error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('error:', error.error);
    } else {
      if (error.status === 401) {
        console.error('no autorizado:');
        // localStorage.removeItem('jwt');
      }
    }
    return throwError(error.error);
  }
}
