import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppService} from '../../app.service';
import {ApiService} from '../../services/api.service';
import {HttpParams} from '@angular/common/http';


@Component({
  selector: 'app-portadas',
  templateUrl: './portadas.component.html',
  styleUrls: ['./portadas.component.scss']
})
export class PortadasComponent implements OnInit , OnChanges {
  @Input('portadas') portadas: Array<any> = [];

  PortadasOrganizated: Array<any> = [];


  public sidenavOpen = true;
  public viewCol = 20;

  constructor(public apiService: ApiService, public appService: AppService, public router: Router) {
    // this.PortadasOrganizated.splice(0, this.PortadasOrganizated.length);
    //
    // this.PortadasOrganizated.push([]);
  }

  ngOnInit() {

    console.log('portadas', this.portadas);
  }

  ngOnChanges(changes: any) {
    console.log('cambio', changes);
    console.log('portadas cambiadas', this.portadas.length);

    if (this.portadas.length > 0) {
      this.PortadasOrganizated = this.portadas;
    }

    // if (this.banners.length <= 100 && this.banners.length >= 1) {  // requisito principal CRITICO
    //   this.n = this.banners.length;
    //   // this.Z = this.n / 18;
    //   this.Y = this.n / 6;
    //   this.X = this.n % 6;
    //
    //
    //   // this.sizeWith = 100  / (this.n / 2);
    //   console.log('tamaños' , this.sizeWith, this.n);
    //
    //
    //   this.bannersOrganizated.splice(0, this.bannersOrganizated.length);
    //   // const auxSize = Number(this.n / 3);
    //   // console.log(toFixed(auxSize, 0 ));
    //   // const auxBann = Number(this.n / 3);
    //   // console.log(toFixed(auxSize, 0 ));
    //
    //   console.log('banners pre Organizated' , this.bannersOrganizated);
    //
    //   for (let i = 0; i < this.n ; i++) {
    //     const auxX = toFixed( i / 2, 0);
    //     if (this.bannersOrganizated[auxX] === undefined) {
    //       this.bannersOrganizated.push([]);
    //       this.bannersOrganizated[auxX].push(this.banners[i]);
    //     } else {
    //       this.bannersOrganizated[auxX].push(this.banners[i]);
    //     }
    //
    //   }
    // } else {
    //   console.log('no cambiado');
    //
    // }
    //
    // // this.bannersOrganizated = this.banners.slice(0, 2);
    // console.log('bannersOrganizated' , this.bannersOrganizated, this.bannersOrganizated.length);

  }



  public getBanner(index) {
    return this.portadas[index];
  }

  public getBgImage(index) {
    const bgImage = {
      'background-image': index != null ? 'url(' + this.portadas[index].image + ')' : 'url(https://via.placeholder.com/600x400/ff0000/fff/)'
    };
    return bgImage;
  }

  public onClick(value) {
    console.log('tiendas', value);

    // let Params = new HttpParams();
    // Params = Params.append('nombreEmpresa', value.nombreEmpresa);
    // Params = Params.append('estadoPublicacion', 'publicado');
    //
    // this.apiService.getProducts(Params).subscribe(data => {
    //   if (data === null) {
    //     console.log('archivo nulo');
    //   } else {
    //
    //
    //     this.appService.productos = data;
    //     this.appService.storeSel = value;
    //     console.log('productos',  this.appService.productos);
    //
        this.router.navigate(['/stores', value.nombreEmpresa] );
    //
    //
    //
    //   }
    // });
  }



}
