import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { emailValidator, matchingPasswords } from '../../../theme/utils/app-validators';
import { ApiService } from '../../../services/api.service';
import { AuthService } from '../../../services/auth.service';
import { AppService } from 'src/app/app.service';




@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {
  @Input() cedula_shared: string = '';


  e = null;
  cdg = null;
  tmp = null;
  idc = null;



  openModal = false;
  tipoModal = 'new-user'

  verificationForm: FormGroup;
  paramsCode: any;
  datosUsuario = {};
  email = '';
  nombre = '';
  cedula = '';
  activarCronometro = true;
  mostrarContador = 30;
  constructor(public formBuilder: FormBuilder, public router: Router, public snackBar: MatSnackBar, private _activatedRoute: ActivatedRoute,
    private api: ApiService, public activatedRoute: ActivatedRoute, private auth: AuthService, private appService: AppService) {

    // this.activatedRoute.queryParams.subscribe(params => {
    //   console.log(params);
    //     this.cedula=params.cedula;
    //     this.email=params.email;
    //   }
    // );
    this.datosUsuario = this.auth.getLocal('registeredUser')
    console.log(this.datosUsuario)
    if (this.datosUsuario === null) this.router.navigate(['/sign-up/']);
    this.email = this.datosUsuario['email'];
    this.nombre = this.datosUsuario['nombre'];
    //this.cedula=this.datosUsuario['cedula'];
  }

  ngOnInit(): void {
    // this.verificationForm = this.formBuilder.group({
    //   'scope':'uShops-platform',
    //   'email': '',
    //   'device':'web',
    //   'codigoVerificacion': ['', Validators.compose([Validators.required, Validators.minLength(5)])],
    // },);

    this.activatedRoute.queryParamMap.subscribe(params => {
      this.paramsCode = params.get('c');
    });
    console.log('this.paramsCode: ', this.paramsCode);

    this.verificationForm = this.formBuilder.group({
      'scope': 'uShops-platform',
      'username': '',
      'device': 'web',
      'password': [this.paramsCode ? this.paramsCode : '', Validators.compose([Validators.required, Validators.minLength(5)])],
      'password2': [this.paramsCode ? this.paramsCode : '', Validators.compose([Validators.required, Validators.minLength(5)])],
    },);
    console.log(this.verificationForm);
    //this.carga();

    this._activatedRoute.queryParams.subscribe(params => {
      console.log(params);

      if(params['e']){
        this.e = params['e'];
      }

      if(params['cdg']){
        this.cdg = params['cdg'];
      }
      if(params['idc']){
        this.idc = params['idc'];
      }
      if(params['tmp']){
        this.tmp = params['tmp'];
      }
      

      console.log(this.e, this.cdg, this.tmp)
      

    });


  }


  carga() {
    this.activarCronometro = false;
    let contador = 30;
    let id = setInterval(baseTiempo, 1000);
    let s = document.getElementById("segundos")
    console.log(id);
    function baseTiempo() {
      if (contador <= 0) {
        contador = 30;
        clearInterval(id);
        return this.activarCronometro = true;
      } else {
        contador = contador - 1;
        s.innerHTML = contador.toString();
      }
      console.log(contador);
    }
  }

  public reenvioCodigoVerificacion() {
    const body = {
      scope: 'uShops-platform',
      device: 'web',
      username: this.email,
    }
    console.log(body);
    // this.api.getVerifCode(body).subscribe(
    this.api.getNewVerifCode(body).subscribe(
      result => {
        console.log(result);
        this.snackBar.open('El código ha sido reenviado. No olvides revisar tu bandeja de Correo no deseado', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });

      },
      error => {
        console.log(error);
      }
    )
  }



  public onVerificationFormSubmit(values: FormGroup) {
    console.log('boton de verificacion presionado');
    //  console.log(this.cedula_shared);
    if (this.verificationForm.valid) {
      values['email'] = this.email;
      this.api.verifUser(values).subscribe(
        result => {
          console.log(result);
          this.SignIn();
          // this.snackBar.open('Verificacion exitosa!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
          // this.snackBar.open('El código enviado servirá para que puedas iniciar sesión', '×', { panelClass: 'success', verticalPosition: 'top', duration: 7000 });
          // this.router.navigate(['/sign-in/']);
          ////
        },
        error => {
          alert('El código ingresado no es el correcto. Por favor verificalo e ingresalo nuevamente');
        }
      )
    }
  }

  verificationCode(values: FormGroup) {
    if (this.verificationForm.valid) {
      values['username'] = this.email;
      console.log(values);

      if(this.verificationForm.get('password').value != this.verificationForm.get('password2').value){
        console.log('contraseñas incorrectas')
        return;
      }


      let query = 'e='+ this.e+'&cdg='+this.cdg+'&idc='+this.idc+ '&tmp='+ this.tmp;

      this.api.userSetActivate(values, query).subscribe(
        result => {
          console.log('verfi-code', result);
          this.openModal = true;
          this.tipoModal = 'new-user';
          // this.SignIn();
        },
        error => {
          console.log(error);
          alert('El código ingresado no es el correcto. Por favor verificalo e ingresalo nuevamente');
        }
      )
    }
  }

  SignIn() {
    console.log(this.verificationForm.value['codigoVerificacion'])
    const body = {
      device: 'web',
      username: this.email,
      scope: 'uShops-platform',
      password: this.verificationForm.value['codigoVerificacion'],
    };
    console.log(body)
    this.api.authentication(body).subscribe(
      result => {
        console.log(result)
        console.log(result['jtoken'])
        console.log(result['parameters']['estado'])
        this.snackBar.open('Ingreso exitoso!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
        this.auth.setNombres(result['parameters']['nombres'])
        this.auth.setToken(result['jtoken'])
        this.auth.setUsername(result['parameters']['email']);
        this.auth.setAuth();
        this.auth.setIdentificadorEmpresa(result['parameters']['identificadorEmpresa'])
        if (result['parameters']['estado'] === 'cambio contraseña') {
          this.router.navigate(['account/informacion']);
        } else window.location.reload();
        // } else this.router.navigate(['/']);
        if (result['parameters']['estadoTienda'] === 'habilitado') {
          this.auth.setHabilitacionTienda('true');
          this.appService.usuario.habilitacionTienda = true;
        } else {
          this.auth.setHabilitacionTienda('false');
          this.appService.usuario.habilitacionTienda = false;
        }

        this.openModal = true;
        this.tipoModal = 'new-user';

        // setTimeout(function(){this.router.navigate(['/products'])}, 5000);
        // this.address = document.location.href;
        // this.router.navigateByUrl('/search', {skipLocationChange: true}).then(() =>
        // this.router.navigate(['/']));
      },
      error => {
        this.snackBar.open('Contraseña incorrecta!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    )
  }

  restaurarModal(event: any) {
    console.log(event);

    if (event === false) {
      this.openModal = false;
      this.tipoModal = '';
    }
    if (event === 'Done') {
      this.router.navigate(['/sign-in']);
    }
  }

}
