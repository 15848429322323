import { AuthService } from './auth.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor, HttpParams,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, ObservedValueOf } from 'rxjs';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiService {

  // ipPublica = 'http://34.217.240.171:';
  //ipPublica = 'http://localhost:';
  // ipPublica = 'http://54.70.216.182:80'
  // ipPublica = 'https://pruebas.ushops.tech';
  // ipPublica = 'https://pruebas2.egm-robotics.com';
  // ipPublica = 'https://api.egm-robotics.com';
  ipPublica = environment.urlApi;

  puerto = '';

  // authUrl = this.ipPublica + '40000/api';
  // basePublicUrl = this.ipPublica + '40200/api';
  // baseAuthUrl = this.ipPublica + '50200/api';

  // authUrl = 'https://localhost:443/ushops/authentication';
  // basePublicUrl = 'https://localhost:443/api';
  // baseAuthUrl = 'https://localhost:443/api';

  authUrl = this.ipPublica;
  // authUrl = this.ipPublica + '/ushops';
  basePublicUrl = this.ipPublica + this.puerto + '/ushops/serviciosGenerales';
  baseAuthUrl = this.ipPublica + this.puerto + '/ushops/serviciosAutentication';
  baseProductsUrl = this.ipPublica + '/ushops/servicios/ServAuth'
  baseChatbotWhatsappUrl = 'https://pruebas2.egm-robotics.com/api/chatbot'
  baseImgUrl = this.ipPublica + this.puerto + '/ushops/imagenes';
  baseUploadFilesUrl = this.ipPublica + this.puerto + '/ushops/uploadFiles';
  baseGenerales = this.ipPublica + '/ushops/generales/ServGen';
  baseServicios = this.ipPublica + '/ushops/servicios/ServAuth';
  baseNotificaciones = this.ipPublica + '/ushops/notificaciones/NotifAuth'

  baseGenerales2 = this.ipPublica + '/ushops/generales/ServGen';
  // baseGenerales2 = this.ipPublica + '/ushops/generales2/ServGen';

  baseCart = this.ipPublica + '/ushops/1v1/carrito/ServCart'
  baseFavoritos = this.ipPublica + '/ushops/favoritos'

  // getBasePublicURL() {
  //   return (this.baseImgUrl);
  // }

  getBaseImageURL() {
    return (this.baseImgUrl);
  }
  getImageURL() {
    return (this.baseImgUrl + '/imagenes/general/getImg?path=');
  }
  getBaseAuthURL() {
    return (this.baseAuthUrl);
  }
  getUploadFilesURL() {
    return (`${this.baseUploadFilesUrl}/image`);
  }


  constructor(private http: HttpClient, private auth: AuthService) { }

  authentication(credencials): Observable<any> {
    // return this.http.post(`${this.authUrl}/usuario/authentication`, credencials).pipe(catchError(this.handleError));
    return this.http.post(`${this.authUrl}/EgmSystems/Auth/getAuth`, credencials).pipe(catchError(this.handleError));
  }

  refreshToken(body): Observable<any> {

    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };
    // return this.http.post(`${this.authUrl}/usuario/authentication`, credencials).pipe(catchError(this.handleError));
    return this.http.post(`${this.authUrl}/EgmSystems/Auth/refreshAuth`, body, options).pipe(catchError(this.handleError));
  }



  newUser(credencials): Observable<any> {
    return this.http.patch(`${this.authUrl}/registro/newUsr`, credencials).pipe(catchError(this.handleError));
  }


  // New Register V1.1

  authenticationEmail(credencials): Observable<any> {
    return this.http.patch(`${this.authUrl}/EgmSystems/Auth/testEmail`, credencials).pipe(catchError(this.handleError));
    // return this.http.patch(`${this.authUrl}/1v1/autenticacion/testEmail`, credencials).pipe(catchError(this.handleError));
  }

  authenticationUname(credencials): Observable<any> {
    return this.http.patch(`${this.authUrl}/EgmSystems/Auth/testUsername`, credencials).pipe(catchError(this.handleError));
    // return this.http.patch(`${this.authUrl}/1v1/autenticacion/testUsername`, credencials).pipe(catchError(this.handleError));
  }

  registerUser(params): Observable<any> {
    return this.http.patch(`${this.authUrl}/EgmSystems/Reg/usuario`, params).pipe(catchError(this.handleError));
    // return this.http.patch(`${this.authUrl}/1v1/registro/newUsr`, params).pipe(catchError(this.handleError));
  }

  userSetActivate(credencials, url): Observable<any> {
    return this.http.post(`${this.authUrl}/EgmSystems/Reg/verificacion?${url}`, credencials).pipe(catchError(this.handleError));
  }

  userVerificationCode(credencials): Observable<any> {
    return this.http.patch(`${this.authUrl}/1v1/registro/verifUsr`, credencials).pipe(catchError(this.handleError));
  }



  registerStore(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };
    console.log('Token', options)
    console.log('Param', parameters)
    return this.http.patch(`${this.baseProductsUrl}/tienda/updateForm`, parameters, options)
      .pipe(catchError(this.handleError));
  }
  requestStore(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };
    console.log('Token', options)
    console.log('Param', parameters)
    return this.http.patch(`${this.baseProductsUrl}/tienda/requestStore`, parameters, options)
      .pipe(catchError(this.handleError));
  }



  requestBot(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };
    console.log('Token', options)
    console.log('Param', parameters)
    return this.http.patch(`${this.baseProductsUrl}/tienda/requestBot`, parameters, options)
      .pipe(catchError(this.handleError));
  }



  getNewVerifCode(credencials): Observable<any> {
    return this.http.patch(`${this.authUrl}/1v1/registro/getVerifCode`, credencials).pipe(catchError(this.handleError));
  }

  resetPass(credencials): Observable<any> { ///hitman///
    return this.http.patch(`${this.authUrl}/1v1/registro/resetPassword`, credencials).pipe(catchError(this.handleError));
  }
  // End New Register V1.1


  ////// Favoritos //////

  getFavoritesProducts(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseFavoritos}/productos/getPrdctfavorito`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  addFavoriteProduct(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseFavoritos}/productos/setPrdctfavorito`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  deleteFavoriteProduct(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseFavoritos}/productos/unsetPrdctfavorito`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getFavoritesStores(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseFavoritos}/tiendas/getStrfavorito`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  addFavoriteStore(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseFavoritos}/tiendas/setStrfavorito`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  deleteFavoriteStore(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseFavoritos}/tiendas/unsetStrfavorito`, parameters, options)
      .pipe(catchError(this.handleError));
  }






  getVerifCode(credencials): Observable<any> {
    return this.http.patch(`${this.authUrl}/registro/getVerifCode`, credencials).pipe(catchError(this.handleError));
  }

  verifUser(credencials): Observable<any> {
    return this.http.patch(`${this.authUrl}/registro/verifUsr`, credencials).pipe(catchError(this.handleError));
  }


  ////////////////////////////// home////////////////////////////////////////
  getCarrouselBanners(): Observable<any> {
    return this.http.patch(`${this.baseGenerales}/home/getSlidesList`, {})
      .pipe(catchError(this.handleError));
  }

  getDepartment(): Observable<any> {
    return this.http.patch(`${this.basePublicUrl}/ServGen/home/getDepartment`, {})
      .pipe(catchError(this.handleError));
  }

  ////////////////////////////// category ////////////////////////////////////////

  getAllCategorias(params): Observable<any> { //// hitman///////////
    return this.http.patch(`${this.baseGenerales}/home/getAllCategorias`, params)
      .pipe(catchError(this.handleError));
    // headers: new HttpHeaders()
  }


  getCategorias(params): Observable<any> { //// hitman//////////
    return this.http.patch(`${this.baseGenerales}/home/getCategorias`, params)
      .pipe(catchError(this.handleError));
    // headers: new HttpHeaders()
  }

  getStoreCategorias(params): Observable<any> { //// hitman ///////
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseServicios}/tienda/getCategorias`, params, options)
      .pipe(catchError(this.handleError));
  }

  getStoreCategories(params): Observable<any> {
    return this.http.patch(`${this.baseGenerales2}/general/getPrdctsCtgrs`, params)
      .pipe(catchError(this.handleError));
  }

  getCategoriesRandom(): Observable<any> {
    return this.http.patch(`${this.baseGenerales2}/general/getCtgrsRdm`, {})
      .pipe(catchError(this.handleError));
  }

  ////////////////////////////// stores ////////////////////////////////////////

  getSingleStore(param): Observable<any> { ///hitman////
    const options = {
      body: param
    };
    return this.http.patch(`${this.baseGenerales}/home/getInfoMarket`, param)
      .pipe(catchError(this.handleError));
  }

  getStores(param): Observable<any> {
    const options = {
      body: param
    };
    return this.http.patch(`${this.basePublicUrl}/ServGen/stores/getStores`, param)
      .pipe(catchError(this.handleError));
  }

  getProductsInStore(identificador): Observable<any> {
    return this.http.patch(`${this.baseGenerales}/productos/searchProductsByStore`, identificador)
      .pipe(catchError(this.handleError));
  }

  getProductsInStoreCategory(param): Observable<any> {
    return this.http.patch(`${this.baseGenerales2}/general/getPrdctsOfCtgr`, param)
      .pipe(catchError(this.handleError));
  }

  getProductByCategory(param): Observable<any> {
    return this.http.patch(`${this.baseGenerales}/productos/searchProductsByCategory`, param)
      .pipe(catchError(this.handleError));
  }

  ////////////////////////////// products ////////////////////////////////////////

  getProductsRecomended(): Observable<any> {
    // console.log('recomendados api')
    return this.http.patch(`${this.baseGenerales}/home/getRecomendados2`, {})
      .pipe(catchError(this.handleError));
  }

  getProductsBestSellers(): Observable<any> {
    // console.log('mas vendidos api')
    return this.http.patch(`${this.baseGenerales}/home/getMasVendidos2`, {})
      .pipe(catchError(this.handleError));
  }

  getAdvertised(): Observable<any> {
    return this.http.patch(`${this.baseGenerales}/home/getPublicitados`, {})
      .pipe(catchError(this.handleError));
  }

  getStoresAdvertised(): Observable<any> {
    return this.http.patch(`${this.baseGenerales2}/home/getTiendasPublicitadas`, {})
      .pipe(catchError(this.handleError));
  }

  getProducts(param): Observable<any> {
    const options = {
      params: param
    };
    console.log(param);
    return this.http.patch(`${this.basePublicUrl}/ServGen/productos/getProducts`, param)
      .pipe(catchError(this.handleError));
  }

  searchProductsGlobal(value): Observable<any> {
    // return this.http.patch('https://pruebas.ushops.tech/ushops/generales/ServGen/productos/searchProductsGlobal', {value})
    return this.http.patch(`${this.baseGenerales2}/general/findProducts2`, value)
      // return this.http.patch(`https://pruebas.ushops.tech/ushops/generales2/ServGen/general/findProducts2`, value)
      .pipe(catchError(this.handleError));
  }

  searchSingleProduct(value): Observable<any> {
    return this.http.patch(`${this.baseGenerales}/productos/getSingleProduct`, { value })
      .pipe(catchError(this.handleError));
  }

  getSingleProduct(param): Observable<any> {
    const options = {
      params: param
    };
    return this.http.patch(`${this.baseGenerales}/productos/getSingleProduct`, param)
      .pipe(catchError(this.handleError));
  }
  getProductosRelacionados(param): Observable<any> { ///hitman////
    const options = {
      params: param
    };
    return this.http.patch(`${this.baseGenerales}/productos/getProductosRelacionados2`, param)
      .pipe(catchError(this.handleError));
  }
  getProductosRelacionadosStore(param): Observable<any> { ///hitman////
    const options = {
      params: param
    };
    return this.http.patch(`${this.baseGenerales}/stores/getProductosRelacionados2`, param)
      .pipe(catchError(this.handleError));
  }

  getProductsRandom(): Observable<any> { ///hitman////
    return this.http.patch(`${this.baseGenerales2}/general/getPrdctsRdm`, {})
      .pipe(catchError(this.handleError));
  }






  getNotifications(param) { ///hitman////
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseNotificaciones}/generales/getNotificaciones`, param, options)
      .pipe(catchError(this.handleError));
  }
  actionNotification(param) { ///hitman////
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseNotificaciones}/generales/actionNotificacion`, param, options)
      .pipe(catchError(this.handleError));
  }
  getComments(param) { ///hitman////
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseServicios}/negocio/getComments`, param, options)
      .pipe(catchError(this.handleError));
  }
  pushComments(param) { ///hitman////
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseServicios}/negocio/pushComment`, param, options)
      .pipe(catchError(this.handleError));
  }

  changePass(credencialesPass) {
    // console.log(this.auth.getToken());
    //   console.log(`${this.baseAuthUrl}/usuario/updatePassword`);
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };
    return this.http.patch(`${this.authUrl}/autenticacion/changePass`, options).pipe(catchError(this.handleError));
    //return this.http.patch(`${this.baseAuthUrl}/usuario/updatePassword`, credencialesPass, options).pipe(catchError(this.handleError));
  }

  resetPassOld(credencials): Observable<any> { ///hitman///
    return this.http.patch(`${this.authUrl}/registro/resetPassword`, credencials).pipe(catchError(this.handleError));
  }


  ////////////////////////////// ventas ////////////////////////////////////////

  getSells(): Observable<any> {
    // const options = {
    //   params: param
    // };
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.get(`${this.baseAuthUrl}/negocio/getSellList`, options)
      .pipe(catchError(this.handleError));
  }


  getBuys(): Observable<any> {
    // const options = {
    //   params: param
    // };
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.get(`${this.baseAuthUrl}/negocio/getBuyList`, options)
      .pipe(catchError(this.handleError));
  }

  getPendientList(parameters): Observable<any> { /// hitman ///
    // const options = {
    //   params: param
    // };
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseProductsUrl}/negocio/getPendientList`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getUserProduct(parameters): Observable<any> { /// hitman ///
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };
    return this.http.patch(`${this.baseProductsUrl}/negocio/getProduct`, parameters, options)
      .pipe(catchError(this.handleError));
  }



  // actionProduct(parameters) {
  //   // console.log(this.auth.getToken());
  //   // console.log(`${this.baseAuthUrl}/negocio/actionProduct`);
  //   const options = {
  //     headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
  //   };

  //   return this.http.put(`${this.baseAuthUrl}/negocio/actionProduct`, parameters, options)
  //     .pipe(catchError(this.handleError));
  // }


  newProduct(parameters) {
    const producto = { producto: parameters };
    // console.log(this.auth.getToken());
    // console.log(`${this.baseAuthUrl}/negocio/actionProduct`);
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseProductsUrl}/negocio/newProduct`, parameters, options)
      .pipe(catchError(this.handleError));
  }


  getPendientProducts(parameters): Observable<any> {
    // const options = {
    //   params: param
    // };
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseProductsUrl}/negocio/getPendientProducts`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getPublishedList(parameters): Observable<any> { /// Hitman ///
    // const options = {
    //   params: param
    // };
    const options = {
      // params: parameters,
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseProductsUrl}/negocio/getPublishedList`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  updateProduct(parameters): Observable<any> { /// Hitman ///
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseProductsUrl}/negocio/updatePendientProduct`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  updateImage(parameters): Observable<any> { /// Hitman ///
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseProductsUrl}/negocio/uploadImage`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  deteleImage(parameters): Observable<any> { /// Hitman ///
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseProductsUrl}/negocio/removeImage`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  actionProduct(parameters): Observable<any> { /// Hitman ///
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseProductsUrl}/negocio/actionProduct`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  uploadFiles(parameters): Observable<any> {
    // const options = {
    //   params: param
    // };
    console.log(parameters);
    const options = {
      headers: new HttpHeaders({
        ['Authorization']: `Bearer ${this.auth.getToken()}`,
        // 'Content-Type': 'multipart/form-data'})
        // 'Content-Type': 'multipart/form-data;boundary=' + Math.random()
      })
    };

    return this.http.patch(`${this.baseUploadFilesUrl}/image`, parameters, options)
      .pipe(catchError(this.handleError));
  }


  //////////// delete product

  deleteProduct(parameters): Observable<any> { ///hitman////
    // const options = {
    //   params: param
    // };
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseServicios}/negocio/deleteProduct`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  updateFormStore(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseProductsUrl}/tienda/updateForm`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getFormStore(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseProductsUrl}/tienda/getForm`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getTiendaParameters(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseServicios}/tienda/getTiendaParameters`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  updateTiendaParameters(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseServicios}/tienda/updateTiendaParameters`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  updateTiendaImage(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };
    return this.http.patch(`${this.baseServicios}/tienda/updateImage`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  //// CHATBOT WHATSAPP //////////////////////////////

  getConversations(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerConversaciones`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getQRCode(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerQR`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  createConversation(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/crearConversacion`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  updateConversation(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/actualizarConversacion`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  deleteConversation(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/eliminarConversacion`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getCompanyData(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerDatosEmpresa`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  updateCompanyData(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/actualizarDatosEmpresa`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  uploadImageS3(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/subirImagenRespuesta`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  uploadVideoS3(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/subirVideoS3`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  uploadFileS3(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/subirArchivoS3`, parameters, options)
      .pipe(catchError(this.handleError));
  }



  //// MENSAJERIA MASIVA WHATSAPP //////////////////////////////

  getCampaignsWhatsapp(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerCampaignsWhatsapp`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  createCampaignWhatsapp(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/crearCampaignWhatsapp`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  updateCampaignWhatsapp(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/actualizarCampaignWhatsapp`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  deleteCampaignWhatsapp(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/eliminarCampaignWhatsapp`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getMensajesCampaigns(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerMensajesCampaign`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  createMensajeCampaigns(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/crearMensajeCampaign`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  updateMensajeCampaigns(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/actualizarMensajeCampaign`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  deleteMensajeCampaigns(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/eliminarMensajeCampaign`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getBandejasCampaign(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerBandejasCampaign`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  createBandejaCampaign(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/crearBandejaCampaign`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  updateBandejaCampaign(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/actualizarBandejaCampaign`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  deleteBandejaCampaign(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseChatbotWhatsappUrl}/eliminarBandejaCampaign`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  ///////// CARRITOO ////////// HITMAN ////
  updateUserAddress(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/direcciones/updateDireccion`, parameters, options)
      .pipe(catchError(this.handleError));
  }
  createUserAddress(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/direcciones/updateDireccion`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  deleteUserAddress(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/direcciones/deleteDireccion`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getAllUserAddress(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/direcciones/getDirecciones`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getUserAddress(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/direcciones/getDireccion`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getShippingOptions(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/opcionesCarrito/getMetodosEnvio`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getPayOptions(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/opcionesCarrito/getMetodosPago`, parameters, options)
      .pipe(catchError(this.handleError));
  }


  createDataBill(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/facturacion/updateDatosFacturacion`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  updateDataBill(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/facturacion/updateDatosFacturacion`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getAllDataBill(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/facturacion/getDatosFacturaciones`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getDataBill(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/facturacion/getDatosFactura`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  deleteDataBill(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/facturacion/deleteDatosFacturacion`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  updateCart(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/carrito/updateCart`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  verifCart(parameters) {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseCart}/ordenCompra/verifyOrden`, parameters, options)
      .pipe(catchError(this.handleError));
  }

  getUserShoppings(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`https://pruebas.ushops.tech/ushops/compras/mis-compras/ordenes`, parameters, options).pipe(catchError(this.handleError));
  }
  getUserSingleShopping(parameters): Observable<any> {
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`https://pruebas.ushops.tech/ushops/compras/mis-compras/orden`, parameters, options).pipe(catchError(this.handleError));
  }



  //////// EMAIL ////////


  sendEmail(parameters) {
    // const options = {
    //   headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
    // };

    // return this.http.patch(`${this.baseCart}/ordenCompra/verifyOrden`, parameters, {})
    return this.http.patch(`https://api.ushops.tech/EgmSystems/emailing/gmail/sendEmail`, parameters, {})
      .pipe(catchError(this.handleError));
  }



  /////////////////////////////////////



  setNewOrder(parameters) {
    // console.log(this.auth.getToken());
    // console.log(`${this.baseAuthUrl}/negocio/actionProduct`);
    const options = {
      headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
    };

    return this.http.patch(`${this.baseProductsUrl}/negocio/actionProduct`, parameters, options)
      .pipe(catchError(this.handleError));
  }





  // getUsers(func, parameters): Observable<any> {
  //   return this.http.get(`${this.baseUrl}${this.auth.getPort().toString()}/api/usuario/${func}`, options)
  //     .pipe(catchError(this.handleError));
  // }

  getAuthorization() {
    return (`Bearer ${this.auth.getToken()}`);
  }
  //
  //   getImages(URL): Observable<HttpEvent<{}>> {
  //
  //       let Params = new HttpParams();
  //       Params = Params.append('path', URL);
  //           const headers =  new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`});
  //
  //       // const formdata: FormData = new FormData();
  //
  //       // formdata.append('path', file);
  //
  //       const req = new HttpRequest('GET', `${this.baseUrl}${this.auth.getPort().toString()}/api/documentos/getImgFile` , {
  //           headers: headers,
  //           params: Params,
  //           // reportProgress: true,
  //           responseType: 'blob'
  //       });
  //       // console.log(req);
  //       return this.http.request(req);
  //   }
  //
  //
  //
  //
  //   ///////////////////// control de documentos ///////////////////////////////
  //   ingresoDocumento(forms) {
  //       const options = {
  //           headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
  //       };
  //
  //       // console.log('creando documento: ' + forms );
  //       // console.log(`${this.baseUrl}${this.auth.getPort().toString()}/api/documentos/newDocument`);
  //       return this.http.post(`${this.baseUrl}${this.auth.getPort().toString()}/api/documentos/newDocument`, forms, options)
  //           .pipe(catchError(this.handleError));
  //   }
  //
  //   getDocumentos(func, parameters): Observable<any> {
  //       const options =  {
  //           // responseType: 'json',
  //           headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`}),
  //           params: parameters
  //       };
  //
  //
  //       console.log(`${this.baseUrl}${this.auth.getPort().toString()}/api/documentos/${func}`);
  //       return this.http.get(`${this.baseUrl}${this.auth.getPort().toString()}/api/documentos/${func}`, options)
  //           .pipe(catchError(this.handleError));
  //   }
  //
  //   getDocumentFile(parameters): Observable<any> {
  //       const options = {
  //           headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`}),
  //           params: parameters,
  //           responseType: 'arraybuffer'
  //           // responseType: new HttpResponseBase()
  //       };
  //       console.log(options);
  //       console.log(`${this.baseUrl}${this.auth.getPort().toString()}/api/documentos/getImgFile`);
  //       return this.http.post<any>(`${this.baseUrl}${this.auth.getPort().toString()}/api/documentos/getImgFile`, options)
  //           .pipe(catchError(this.handleError));
  //   }
  //
  //   //////////////////////////// inventario///////////////////////////////////////////
  //   getInventario(func): Observable<any> {
  //       const options = {
  //           headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
  //       };
  //
  //       return this.http.get(`${this.baseUrl}${this.auth.getPort().toString()}/api/inventario/${func}`, options)
  //           .pipe(catchError(this.handleError));
  //   }
  //   getNumInventario(func): Observable<any> {
  //       const options = {
  //           headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
  //       };
  //
  //       return this.http.get(`${this.baseUrl}${this.auth.getPort().toString()}/api/inventario/${func}`, options)
  //           .pipe(catchError(this.handleError));
  //   }
  //   getReportes(func): Observable<any> {    // por arreglar
  //       const options = {
  //           headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
  //       };
  //
  //       return this.http.get(`${this.baseUrl}${this.auth.getPort().toString()}/api/inventario/${func}`, options)
  //           .pipe(catchError(this.handleError));
  //   }
  //
  //
  //   getClientes(func): Observable<any> {
  //       const options = {
  //           headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
  //       };
  //       return this.http.get(`${this.baseUrl}${this.auth.getPort().toString()}/api/clientela/${func}`, options)
  //           .pipe(catchError(this.handleError));
  //   }
  //
  // // getFacturas(func): Observable<any> {
  // //   const options = {
  // //     headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
  // //   };
  // //   return this.http.get(`${this.baseUrl}/facturacion/${func}`, options)
  // //     .pipe(catchError(this.handleError));
  // // }
  //
  // getFacturas(func, parameters): Observable<any> {
  //
  //   const options = {
  //     headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`}),
  //     params: parameters
  //   };
  //
  //   // console.log(${this.baseUrl} / facturacion / ${func});
  //   return this.http.get(`${this.baseUrl}${this.auth.getPort().toString()}/api/facturacion/${func}`, options)
  //     .pipe(catchError(this.handleError));
  // }
  //
  // getUsers(func, parameters): Observable<any> {
  //   const options = {
  //     headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`}),
  //     params: parameters
  //   };
  //   return this.http.get(`${this.baseUrl}${this.auth.getPort().toString()}/api/usuario/${func}`, options)
  //     .pipe(catchError(this.handleError));
  // }
  //
  // setUserHab(func, body): Observable<any> {
  //     const options = {
  //       headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
  //     };
  //     return this.http.put(`${this.baseUrl}${this.auth.getPort().toString()}/api/usuario/${func}`, body, options);
  //     //   .subscribe(
  //     //   response => {
  //     //     console.log(response);
  //     //     // return response;
  //     //   },
  //     //   error => {alert(error);
  //     //   // return error;
  //     //     }
  //     // );
  //   }
  //
  // createAccount(account): Observable<any> {
  //       const options = {
  //           headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
  //       };
  //       return this.http.post(`${this.baseUrl}${this.auth.getPort().toString()}/api/newAccount`, account, options)
  //           .pipe(catchError(this.handleError));
  //   }
  //

  //

  //
  //
  //
  //
  // ///////////////////////////////////////////////////////////// peticiones Dashboard//////////////////////////////////////////////
  //
  // getDashboard(func, parameters): Observable<any> {
  //   const options = {
  //     headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
  //   };
  //
  //   return this.http.get(`${this.baseUrl}${this.auth.getPort().toString()}/api/dashboard/${func}/${parameters}`, options)
  //     .pipe(catchError(this.handleError));
  // }
  //
  //
  // //////////////////////////////////////////////////////////////// agregar items negocio////////////////////////////////////////
  // addItemCategory(item) {
  //     const object = {_id: this.auth.getIdUser(), item: item};
  //
  //   const options = {
  //     headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
  //   };
  //   return this.http.post(`${this.baseUrl}${this.auth.getPort().toString()}/api/negocio/newParameterCategory`, object, options)
  //     .pipe(catchError(this.handleError));
  // }
  // addItemGroup(item) {
  //   const object = {_id: this.auth.getIdUser(), item: item};
  //   const options = {
  //     headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
  //   };
  //   return this.http.post(`${this.baseUrl}${this.auth.getPort().toString()}/api/negocio/newParameterGroup`, object, options)
  //     .pipe(catchError(this.handleError));
  // }
  // addItemType(item) {
  //   const object = {_id: this.auth.getIdUser(), item: item};
  //   const options = {
  //     headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`})
  //   };
  //   return this.http.post(`${this.baseUrl}${this.auth.getPort().toString()}/api/negocio/newParameterType`, object, options)
  //     .pipe(catchError(this.handleError));
  // }
  //
  // ///////////////////// adquirir parametros del negocio ///////////////////////////////
  //
  // getParameters(): Observable<any> {
  //   const options = {
  //     headers: new HttpHeaders({['Authorization']: `Bearer ${this.auth.getToken()}`}),
  //   };
  //   return this.http.get(`${this.baseUrl}${this.auth.getPort().toString()}/api/negocio/getConfigParameters`, options)
  //     .pipe(catchError(this.handleError));
  // }



  handleError(error: HttpErrorResponse) {
    // console.log('error desconocido: ', error);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('error:', error.error);
    } else {
      if (error.status === 401) {
        console.error('no autorizado:');
        // localStorage.removeItem('jwt');
      }
    }
    return throwError(error.error);
  }
}
