import { Component, OnInit, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';


@Component({
  selector: 'app-products-collage',
  templateUrl: './products-collage.component.html',
  styleUrls: ['./products-collage.component.scss']
})
export class ProductsCollageComponent implements OnInit {

  @Input('products') products: Array<any> = [];
  @Input('config') config: SwiperConfigInterface;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){  }

  getImg(id, imagenes){
    if (imagenes.length > 1) {
      const final = imagenes.filter(img => img?.URL.indexOf(id) > -1)
      if (final !== undefined) {
        return final[0].URL;
      }
    } else {
      return imagenes[0].URL;
    }
   }

}
