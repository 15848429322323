import { Component, OnInit, Input, EventEmitter, OnChanges, Output} from '@angular/core';

@Component({
  selector: 'app-modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss']
})
export class ModalWindowComponent implements OnInit, OnChanges {

  @Input('tipoModal') tipoModal: string;
  @Input('decisionContent') decisionContent: string;
  @Output() response = new EventEmitter();



  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void{
    console.log(this.tipoModal);
  }
  closeModal(){
    document.querySelector('#' + 'modalWindow').classList.add('modal-close');
    this.tipoModal = '';
    console.log(this.tipoModal);
    this.response.emit(false);
  }
  respuestaComponentes(event: any){
    console.log(event);
    if(event === 'close'){
      this.closeModal()
    } else{
      this.response.emit(event);
    }
  }
}
