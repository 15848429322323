import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { Data, AppService } from '../../app.service';
import { Product } from '../../app.models';
import { Stores } from '../../app.models';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import {ApiService} from '../../services/api.service';


@Component({
  selector: 'app-products-mix-carousel',
  templateUrl: './products-mix-carousel.component.html',
  styleUrls: ['./products-mix-carousel.component.scss']
})
export class ProductsMixCarouselComponent implements OnInit {

  // @Input('products') products: Array<Product> = [];
  @Input('products') products: Array<any> = [];

  public config: SwiperConfigInterface = {};
  // public store: Array<Stores> = [];
  store = [{
    nombreEmpresa: String
  }];
  
  

  constructor(
    public appService:AppService, 
    public dialog: MatDialog, 
    private router: Router, 
    private  api: ApiService) { 
  }

  ngOnInit() {
  }
  
  //  getImg(id, imagenes){
  //   const final = imagenes.filter(img => img.URL.indexOf(id) > -1);
  //   return final[0].URL;
  //  }


   getImg(store, position){
    
    if(store !== undefined && store.producto !== undefined){
      
      // console.log('tienda', store)
      switch(store.producto.length) {
        case 1:
          return store.producto[0].images[0].URL
          break;
        case 2:
          if(position === 1) {
          return store.producto[0].images[0].URL
          } else {
            return store.producto[1].images[0].URL
          }
          break;
        case 3:
          if(position === 1) {
            return store.producto[0].images[0].URL
          } else if(position === 2){
            return store.producto[1].images[0].URL
          } else {
            return store.producto[2].images[0].URL
          }
          break;
        default:
          if(position === 1) {
            return store.producto[0].images[0].URL
          } else if(position === 2){
            return store.producto[1].images[0].URL
          } else {
            return store.producto[2].images[0].URL
          }
          break;
        }
    }

  }

   getImgSec(id, imagenes, secondary) {
    const final = [];
    const aux = imagenes.filter(img => img.URL.indexOf(id) > -1)
    for (var i = 0; i < imagenes.length; i++){
      if (imagenes[i] !== aux[0]) {
        final.push(imagenes[i])
      }
    }
    if (final.length >= 2) {
      if (secondary === 1){
        return final[0].URL;
      } else {
        return final[1].URL;
      }
    } else if (final.length === 1){
      if (secondary === 1){
        return final[0].URL;
      } else {
        return aux[0].URL;
      }
    } else {
      return aux[0].URL;
    }
   }

  ngAfterViewInit(){

    this.config = {
      init: true,
      updateOnImagesReady: true,
      speed: 1000,
      autoHeight: true,
      uniqueNavElements: true,
      initialSlide: 0,
      runCallbacksOnInit: true,
      observer: true,
      slidesPerView: 6,
      spaceBetween: 80,       
      keyboard: true,
      // navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: true,
      preloadImages: false,
      lazy: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        100: {
          slidesPerView: 1
        },
        500: {
          slidesPerView: 1.5,
        },
        800: {
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: 2.5,
        },
        1500: {
          slidesPerView: 3,
        }
      }
    }
  }

}