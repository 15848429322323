import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-confirm-checkout',
  templateUrl: './confirm-checkout.component.html',
  styleUrls: ['./confirm-checkout.component.scss']
})
export class ConfirmCheckoutComponent implements OnInit {

  @Output() ConfirmCheckoutResponse = new EventEmitter();

  userEmail = '';

  constructor(
    public apiService: ApiService,
    public auth: AuthService) { }

  ngOnInit(): void {
    this.userEmail = this.auth.getUsername();
  }

  confirmCheckout(){
    this.ConfirmCheckoutResponse.emit('Done');
  }

}
