import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ChatbotConfigurationService } from 'src/app/services/chatbot-configuration.service';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { SocketIoService } from 'src/app/services/socket-io.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

declare var MediaRecorder: any;

@Component({
  selector: 'app-chatbot-configuration',
  templateUrl: './chatbot-configuration.component.html',
  styleUrls: ['./chatbot-configuration.component.scss']
})
export class ChatbotConfigurationComponent implements OnInit {

  // @ViewChild('tree') tree: TreeComponent;

  /// alertas /////////////////
  position = 'bottom-right';
  title: string;
  msg: string;
  showClose = true;
  theme = 'bootstrap';
  type = 'default';
  closeOther = false;

  restLoading = false;

  //Paginador de functions
  currentPage = 1; // Página actual
  maxVisiblePages = 10; // Número máximo de páginas visibles
  pageSize = 30; // Tamaño de página
  visiblePages: number[] = []; // Páginas visibles en el paginador
  totalPages: number[]; // Total de páginas

  // //Paginador tabla estados v2
  // currentPageStates = 1; // Página actual
  // maxVisiblePagesStates = 10; // Número máximo de páginas visibles
  // pageSizeStates = 30; // Tamaño de página
  // visiblePagesStates: number[] = []; // Páginas visibles en el paginador
  // totalPagesStates: number[]; // Total de páginas

  stateData: any = [
    // Primera Etapa: Primer Contacto
    // {
    //   "estado_actual": "Primer Contacto",
    //   "instruccion": "Dar una bienvenida cordial y solicitar nombre y correo electrónico.",
    //   "ejemplo": "¡Hola! Bienvenido a Dytron. Soy tu asistente virtual. ¿Cómo puedo ayudarte hoy?",
    //   "objetivo": "Dar una bienvenida cordial y comenzar la interacción.",
    //   "parametros_solicitados": [
    //     "nombre",
    //     "correo_electronico"
    //   ]
    // },
  ];

  selectedState: any;
  stateItems: any = [{ message: '', imagen: null }];
  showAssistant: boolean = false;
  stageChatbotData: any = [];
  chatgptInputMessage: string = '';
  typingSpeed = 50;
  displayChatbotText: string = '';
  fullChatbotText: string = 'Indicame la primera etapa';
  currentStage: string = '---';

  private mediaRecorder: any;
  private audioChunks: any[] = [];
  public audioUrl: SafeUrl | null = null;
  public isRecording: boolean = false;

  flowTitle: string;
  selectedConversation: any;
  isLoading: boolean = true;
  selectedNode: any;
  selectedStatus: any = 'activo';
  flowForm: FormGroup;
  qrData: any;
  qrConnection: boolean = false;
  qrSubscription: any;
  piePercentValue = 100;
  pieAnimationDuration = 10000;
  xpertVersion: number = 2;
  hasNewNotifications: boolean = false;


  nodes: any = [];
  keywords: any = [];
  answers: any = [];
  idFlowList: string[] = [];

  // treeOptions = {
  //   allowDrag: (node) => {
  //     return true;
  //   },
  //   allowDrop: true,
  //   getNodeClone: (node) => ({
  //     ...node.data,
  //     id: v4(),
  //     name: `copy of ${node.data.name}`
  //   })
  // };

  selectedFunctionType: string = 'datos_empresa';
  functions: any = [];

  status: any = [
    { label: 'Activo', value: 'activo', disabled: false },
    { label: 'Inactivo', value: 'inactivo', disabled: false }
  ];

  companyParameters: any = [
    { parametro: '', mensaje: [{ texto: '', respuestas: [] }], changed: false }
  ];

  botConfiguration = {
    nombre: '',
    instrucciones: '',
    tipo: '',
    personalidad: '',
    idioma: '',
    key: ''
  }

  botConfigTypes: any = [
    // { label: 'Seleccionar tipo', value: '' || null || undefined, disabled: false },
    { label: 'Vendedor', value: 'vendedor', disabled: false },
    { label: 'Soporte', value: 'soporte', disabled: false },
    { label: 'Servicio Técnico', value: 'servicioTecnico', disabled: false },
  ];

  botConfigPersonalities: any = [
    // { label: 'Seleccionar personalidad', value: '' || null || undefined, disabled: false },
    { label: 'Enojado', value: 'enojado', disabled: false },
    { label: 'Feliz', value: 'feliz', disabled: false }
  ];

  botConfigLanguage: any = [
    // { label: 'Seleccionar idioma', value: '' || null || undefined, disabled: true },
    { label: 'Español', value: 'spanish', disabled: false },
    { label: 'Ingles', value: 'english', disabled: false }
  ];

  constructor(private apiService: ChatbotConfigurationService,
    private authService: AuthService,
    private router: Router,
    private builder: FormBuilder,
    private elementRef: ElementRef,
    private api: ApiService,
    private toastr: ToastrService,
    private sharedDataService: SharedDataService,
    private socketService: SocketIoService,
    private sanitizer: DomSanitizer
    // private toastyService: ToastyService
  ) {
    this.flowForm = this.builder.group({
      titulo: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    // this.flowTitle = localStorage.getItem('identificadorTienda');
    // this.flowTitle = '111111'  //getIdentificadorEmpresa
    // this.showLoader = this.loadingInterceptor.getLoaderVisibility();
    this.startTyping();

    this.getStageData();

    this.sharedDataService.currentData.subscribe((data: any) => {
      console.log('data recibida en chatbot: ', data);
      if (data.data && data.data.tipo === 'tablaInstrucciones') {
        this.stateData = data.data.respuesta;
        for (let i = 0; i < this.stateData.length; i++) {
          this.stateData[i].numero_etapa = i + 1;

          this.stateData[i].editable = {
            instruccion: false,
            ejemplo: false,
            objetivo: false,
            solicitudInfo: false
          }

          if (this.stateData[i] && this.stateData[i].datosAdicionales) {
            this.stateItems[0].message = this.stateData[i].datosAdicionales.message;
            if (this.stateData[i].datosAdicionales.imagen) this.stateItems[0].imagen = this.stateData[i].datosAdicionales.imagen;
            if (this.stateData[i].datosAdicionales.video) this.stateItems[0].video = this.stateData[i].datosAdicionales.video;
            if (this.stateData[i].datosAdicionales.archivo) this.stateItems[0].archivo = this.stateData[i].datosAdicionales.archivo;
            if (this.stateData[i].datosAdicionales.tipo) this.stateItems[0].tipo = this.stateData[i].datosAdicionales.tipo;
          }
        }
      }
    });

    this.socketService.listen('respuestaAssistente').subscribe((data: any) => {
      // console.log('Mensaje recibido:', data);
      if (data && data.respuesta && data.tipo === 'respuestaChatGPT') {
        this.fullChatbotText = data.respuesta.message;
        this.currentStage = data.respuesta.etapa_actual;
        this.displayChatbotText = '';
        this.startTyping();

        console.log('respuesta chatbot: ', data.respuesta);

        // this.displayChatbotText = data.respuesta.message;
      }

      if (data && data.respuesta && data.tipo === 'envioMensaje' && data.respuesta.mensajesPendientes > 0) {
        this.hasNewNotifications = true;
      }
    });

    // this.updatePaginationStates();

    console.log('chatbot', this.flowTitle)

    let times = 1;
    this.updatePagination();
    this.cargarConfiguracionBot();
    this.cargarCodigoQR(times);
    this.cargarDatosEmpresa().then(
      (result: any) => {
        this.cargarFuncionesEmpresa();
      }
    )

    this.qrSubscription = setInterval(() => {
      times++;
      this.cargarCodigoQR(times);
    }, this.pieAnimationDuration + 1000);

    this.isLoading = false;
  }

  ngOnDestroy() {
    if (this.qrSubscription) {
      clearInterval(this.qrSubscription);
    }
  }

  openStateModal(state: any = undefined) {
    this.selectedState = state;
    document.querySelector('#' + 'estadoModal').classList.add('md-show');
  }

  sendChatGPTMessage() {
    this.socketService.emit('getChatbotResponse', { "mensaje": this.chatgptInputMessage });
    this.chatgptInputMessage = '';
  }

  addStateItems() {
    this.stateItems.push({ message: '', imagen: null });
    // this.selectedNode.data.keywords.push('');
  }

  removeStateItems(index: number) {
    this.stateItems[index].isDelete = true;
    // this.selectedNode.data.keywords.splice(index, 1);
  }

  deleteSpace(event: any, index: number) {
    this.keywords[index] = this.keywords[index].replace(/\s/g, ''); // Elimina espacios
  }

  preventSpace(event: any) {
    if (event.keyCode === 32) {
      event.preventDefault(); // Previene la entrada del espacio
    }
  }

  sendTestNotification() {
    this.socketService.emit('mensajeAsistente', { "mensajesPendientes": 1 });
  }

  toggleAssistant() {
    this.showAssistant = !this.showAssistant;
    this.hasNewNotifications = false;
  }

  toggleEditableColumn(item: any, param: string) {
    item.editable[param] = !item.editable[param]
  }

  cancelColumnUpdate(item: any, param: string) {
    item.editable[param] = !item.editable[param]
    this.socketService.emit('getData', { "requirement": "tablaInstrucciones" });
  }

  changeVersion() {
    switch (this.xpertVersion) {
      case 1:
        this.xpertVersion = 2;
        break;
      case 2:
        this.xpertVersion = 1;
        break;
      default:
        this.xpertVersion = 1;
        break;
    }
  }

  saveColumnUpdate(item: any, param: string) {

    let body = {
      id: item._id,
      etapa: item.etapa,
      "Instrucción": item["Instrucción"],
      "Ejemplo": item["Ejemplo"],
      "Objetivo": item["Objetivo"],
      "Solicitud de Información": item["Solicitud de Información"]
    }

    this.apiService.updateChatbotStateData(body).subscribe(
      result => {
        this.socketService.emit('getData', { "requirement": "tablaInstrucciones" });
        console.log('Se ha actualizado el estado exitosamente.');
        this.toastr.success('Se ha actualizado el estado exitosamente.', 'Operación exitosa', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      },
      error => {
        console.log('Error al actualizar el estado: ', error);
        this.toastr.error('Ha ocurrido un error al actualizar el estado.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    );
  }

  startTyping() {
    let currentIndex = 0;
    const interval = setInterval(() => {
      // Agregar una letra a displayedText cada vez
      this.displayChatbotText += this.fullChatbotText.charAt(currentIndex);
      currentIndex++;

      // Cuando se complete el texto, detener el intervalo
      if (currentIndex === this.fullChatbotText.length) {
        clearInterval(interval);
      }
    }, this.typingSpeed);
  }

  startRecording() {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        this.mediaRecorder = new MediaRecorder(stream);
        this.audioChunks = [];

        // Capturando los datos del micrófono
        this.mediaRecorder.ondataavailable = (event: any) => {
          this.audioChunks.push(event.data);
        };

        // Cuando se detiene la grabación, se crea la URL del audio
        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
          const audioUrl = URL.createObjectURL(audioBlob);

          // Usamos DomSanitizer para marcar la URL como segura
          this.audioUrl = this.sanitizer.bypassSecurityTrustUrl(audioUrl);
        };

        this.mediaRecorder.start();
        this.isRecording = true;
      })
      .catch((err) => console.error('Error al acceder al micrófono:', err));
  }

  stopRecording() {
    if (this.mediaRecorder && this.isRecording) {
      this.mediaRecorder.stop();
      this.isRecording = false;
    }
  }

  checkIsImage(index: number) {
    if (this.answers[index] && this.answers[index].options && !this.answers[index].options.type) {
      const fileExtension = this.answers[index].options.media.split('.').pop().toLowerCase();
      return (fileExtension.includes('png') || fileExtension.includes('jpeg')
        || fileExtension.includes('jpg') || fileExtension.includes('gif'));
    }
    // console.log('answer: ', this.answers[index]);
    return this.answers[index] && this.answers[index].options && this.answers[index].options.type.startsWith('image/');
  }

  checkIsVideo(index: number) {
    // console.log('answer: ', this.answers[index]);
    if (this.answers[index] && this.answers[index].options && !this.answers[index].options.type) {
      const fileExtension = this.answers[index].options.media.split('.').pop().toLowerCase();
      return (fileExtension.includes('mp4') || fileExtension.includes('webm')
        || fileExtension.includes('mov'));
    }

    return this.answers[index] && this.answers[index].options && this.answers[index].options.type.startsWith('video/');
  }

  checkIsFile(index: number) {
    if (this.answers[index] && this.answers[index].options) {
      const fileExtension = this.answers[index].options.media.split('.').pop().toLowerCase();
      return (fileExtension.includes('doc') || fileExtension.includes('docx')
        || fileExtension.includes('xls') || fileExtension.includes('xlsx') || fileExtension.includes('xlsm')
        || fileExtension.includes('pdf'));
    } else {
      return false;
    }
  }

  checkIsImageCompany(index: number) {
    // console.log('answer: ', this.answers[index]);

    if (this.companyParameters[index].mensaje && this.companyParameters[index].mensaje[0].options && !this.companyParameters[index].mensaje[0].options.type) {
      const fileExtension = this.companyParameters[index].mensaje[0].options.media.split('.').pop().toLowerCase();
      return (fileExtension.includes('png') || fileExtension.includes('jpeg')
        || fileExtension.includes('jpg') || fileExtension.includes('gif'));
    }

    return this.companyParameters[index].mensaje && this.companyParameters[index].mensaje[0].options && this.companyParameters[index].mensaje[0].options.type.startsWith('image/');
  }

  checkIsVideoCompany(index: number) {
    // console.log('answer: ', this.answers[index]);
    if (this.companyParameters[index].mensaje && this.companyParameters[index].mensaje[0].options && !this.companyParameters[index].mensaje[0].options.type) {
      const fileExtension = this.companyParameters[index].mensaje[0].options.media.split('.').pop().toLowerCase();
      return (fileExtension.includes('mp4') || fileExtension.includes('webm')
        || fileExtension.includes('mov'));
    }

    return this.companyParameters[index].mensaje && this.companyParameters[index].mensaje[0].options && this.companyParameters[index].mensaje[0].options.type.startsWith('video/');
  }

  checkIsFileCompany(index: number) {
    if (this.companyParameters[index].mensaje && this.companyParameters[index].mensaje[0].options) {
      const fileExtension = this.companyParameters[index].mensaje[0].options.media.split('.').pop().toLowerCase();
      return (fileExtension.includes('doc') || fileExtension.includes('docx')
        || fileExtension.includes('xls') || fileExtension.includes('xlsx') || fileExtension.includes('xlsm')
        || fileExtension.includes('pdf'));
    } else {
      return false;
    }

  }

  openFile(url: any) {
    window.open(url, '_blank');
  }

  getFileName(url: string) {
    return decodeURIComponent(url.split('/')[url.split('/').length - 1]);
  }

  resetFile(index: number) {
    this.answers[index].options = null;
  }

  resetFileCompany(index: number) {
    this.companyParameters[index].mensaje[0].options = null;
  }

  resetFileState(index: number) {
    this.stateItems[index].imagen = null;
    this.stateItems[index].video = null;
    this.stateItems[index].archivo = null;
  }

  openConfigBotModal() {
    document.querySelector('#config-bot-modal').classList.add('md-show');
  }

  openEditModal(node: any) {
    this.selectedNode = node;
    console.log('selected node: ', this.selectedNode);
    this.selectedStatus = node.data.status || 'activo';
    this.keywords = [];
    this.answers = [];
    for (let i = 0; i < this.selectedNode.data.keywords.length; i++) {
      this.keywords.push(this.selectedNode.data.keywords[i]);
    }
    for (let i = 0; i < this.selectedNode.data.answers.length; i++) {
      this.answers.push({ answer: [], options: null, cb: null, nested: null });
      for (let j = 0; j < this.selectedNode.data.answers[i].answer.length; j++) {
        this.answers[i].answer.push(this.selectedNode.data.answers[i].answer[j]);
      }

      if (this.selectedNode.data.answers[i].options !== null) {
        this.answers[i].options = { media: this.selectedNode.data.answers[i].options.media, type: this.selectedNode.data.answers[i].options.type };
        setTimeout(() => {
          if (this.selectedNode.data.answers[i].options.type.startsWith('image/')) {
            const imgElement: any = document.getElementById('block-image-' + i);
            // console.log('image element: ', imgElement);
            if (imgElement) imgElement.src = this.selectedNode.data.answers[i].options.media;
          } else if (this.selectedNode.data.answers[i].options.type.startsWith('video/')) {
            let videoPlayer = this.elementRef.nativeElement.querySelector('#videoPlayer' + i);
            videoPlayer.src = this.selectedNode.data.answers[i].options.media;
            videoPlayer.load();
          }
        }, 500);
      } else {
        const imgElement: any = document.getElementById('block-image-' + i);
        // console.log('image element: ', imgElement);
        if (imgElement) imgElement.src = 'assets/icons/upload-image.icon.png';
      }
    }
    // $('.node-modal-content').addClass('active');
    document.querySelector('#' + 'node-modal-content').classList.add('md-show');

  }

  openQRModal() {
    // $('.qr-modal-content').addClass('active');
    document.querySelector('#' + 'qr-modal-content').classList.add('md-show');
  }

  openCompanyModal(tipoFuncion: string) {

    this.selectedFunctionType = tipoFuncion;

    this.cargarDatosEmpresa().then(
      (result: any) => {
        for (let i = 0; i < this.companyParameters.length; i++) {
          if (this.companyParameters[i].mensaje && this.companyParameters[i].mensaje[0].options !== null) {
            setTimeout(() => {
              if (this.companyParameters[i].mensaje[0].options && this.companyParameters[i].mensaje[0].options.type.startsWith('image/')) {
                const imgElement: any = document.getElementById('company-message-image-' + i);
                // console.log('image element: ', imgElement);
                if (imgElement) imgElement.src = this.companyParameters[i].mensaje[0].options.media;
              } else if (this.companyParameters[i].mensaje[0].options && this.companyParameters[i].mensaje[0].options.type.startsWith('video/')) {
                let videoPlayer = this.elementRef.nativeElement.querySelector('#videoPlayerCompany' + i);
                videoPlayer.src = this.companyParameters[i].mensaje[0].options.media;
                videoPlayer.load();
              }
            }, 500);
          } else {
            const imgElement: any = document.getElementById('company-message-image-' + i);
            // console.log('image element: ', imgElement);
            if (imgElement) imgElement.src = 'assets/icons/upload-image.icon.png';
          }
        }

        document.querySelector('#' + 'company-data-modal').classList.add('md-show');
      }
    )
  }

  onFileSelected(event: any, index: number, type: string) {
    const file: File = event.target.files[0];
    console.log('file: ', file);
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgElement: any = document.getElementById('block-image-' + index);
        console.log('elemento imagen: ', imgElement);
        if (imgElement) {

          let formData: FormData = new FormData();
          formData.append('image', file);
          formData.append('type', file.type);

          this.apiService.uploadImageS3(formData).subscribe(
            result => {
              console.log('imagen subida a s3: ', result.url);
              console.log('Se ha subido la imagen a s3.');
              this.resetFile(index);
              this.answers[index].options = { media: result.url, type: file.type };

              console.log('answers: ', this.answers);
              imgElement.src = e.target.result;
              this.toastr.success('Se ha subido la imagen exitosamente.', 'Operacion exitosa', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Se ha subido la imagen exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
            },
            error => {
              console.log('Error al subir la imagen a s3: ', error);
              this.toastr.error('Ha ocurrido un error al subir la imagen.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al subir la imagen', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
            }
          );
        }
      };
      reader.readAsDataURL(file);
    } else if (file && file.type.startsWith('video/')) {
      console.log('entra a video');

      let formData: FormData = new FormData();
      formData.append('video', file);
      formData.append('type', file.type);

      this.apiService.uploadVideoS3(formData).subscribe(
        result => {
          console.log('video subido a s3: ', result.url);
          this.resetFile(index);
          this.answers[index].options = { media: result.url, type: file.type };
          setTimeout(() => {
            let videoPlayer = this.elementRef.nativeElement.querySelector('#videoPlayer' + index);

            videoPlayer.src = result.url;
            videoPlayer.load();
            this.toastr.success('Se ha subido el video exitosamente.', 'Operación exitosa', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Se ha subido el video exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
          }, 1000);
        },
        error => {
          console.log('Error al subir el video a s3: ', error);
          this.toastr.error('Ha ocurrido un error al subir el video.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al subir el video', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      );
    } else if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'doc' || fileExtension === 'docx'
        || fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'xlsm'
        || fileExtension === 'pdf') {
        let formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('type', file.type);

        this.apiService.uploadFileS3(formData).subscribe(
          result => {
            console.log('documento subido a s3: ', result.url);
            this.resetFile(index);
            this.answers[index].options = { media: result.url, type: file.type };
          },
          error => {
            console.log('Error al subir el archivo a s3: ', error);
            this.toastr.error('Ha ocurrido un error al subir el documento.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al subir el documento', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          }
        );
      }
    }
  }

  onFileSelectedCompany(event: any, index: number, type: string) {
    const file: File = event.target.files[0];
    console.log('file: ', file);
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgElement: any = document.getElementById('company-message-image-' + index);
        console.log('elemento imagen: ', imgElement);
        if (imgElement) {

          let formData: FormData = new FormData();
          formData.append('image', file);
          formData.append('type', file.type);

          this.apiService.uploadImageS3(formData).subscribe(
            result => {
              console.log('imagen subida a s3: ', result.url);
              console.log('Se ha subido la imagen a s3.');
              this.resetFileCompany(index);
              this.companyParameters[index].mensaje[0].options = { media: result.url, type: file.type };
              // this.companyParameters[index].changed = true;

              console.log('companyParameters: ', this.companyParameters);
              imgElement.src = e.target.result;
              this.toastr.success('Se ha subido la imagen exitosamente.', 'Operación exitosa', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Se ha subido la imagen exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
            },
            error => {
              console.log('Error al subir la imagen a s3: ', error);
              this.toastr.error('Ha ocurrido un error al subir la imagen.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al subir la imagen', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
            }
          );
        }
      };
      reader.readAsDataURL(file);
    } else if (file && file.type.startsWith('video/')) {
      console.log('entra a video');

      let formData: FormData = new FormData();
      formData.append('video', file);
      formData.append('type', file.type);

      this.apiService.uploadVideoS3(formData).subscribe(
        result => {
          console.log('video subido a s3: ', result.url);
          this.resetFileCompany(index);
          this.companyParameters[index].mensaje[0].options = { media: result.url, type: file.type };
          // this.companyParameters[index].changed = true;
          setTimeout(() => {
            let videoPlayer = this.elementRef.nativeElement.querySelector('#videoPlayerCompany' + index);

            videoPlayer.src = result.url;
            videoPlayer.load();
            this.toastr.success('Se ha subido el video exitosamente.', 'Operación exitosa', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Se ha subido la imagen exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
          }, 1000);
        },
        error => {
          console.log('Error al subir el video a s3: ', error);
          this.toastr.error('Ha ocurrido un error al subir el video.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al subir el video', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      );
    } else if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'doc' || fileExtension === 'docx'
        || fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'xlsm'
        || fileExtension === 'pdf') {
        let formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('type', file.type);

        this.apiService.uploadFileS3(formData).subscribe(
          result => {
            console.log('documento subido a s3: ', result.url);
            this.resetFileCompany(index);
            this.companyParameters[index].mensaje[0].options = { media: result.url, type: file.type };
            // this.companyParameters[index].changed = true;
          },
          error => {
            console.log('Error al subir el archivo a s3: ', error);
            this.toastr.error('Ha ocurrido un error al subir el documento.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al subir el documento', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          }
        );
      }
    }
  }

  onFileSelectedState(event: any, index: number) {
    const file: File = event.target.files[0];
    console.log('file: ', file);
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgElement: any = document.getElementById('state-image-' + index);
        console.log('elemento imagen: ', imgElement);
        if (imgElement) {

          let formData: FormData = new FormData();
          formData.append('image', file);
          formData.append('type', file.type);

          this.apiService.uploadImageS3(formData).subscribe(
            result => {
              console.log('imagen subida a s3: ', result.url);
              this.resetFileState(index);
              this.stateItems[index].imagen = result.url;
              this.stateItems[index].tipo = file.type;
              imgElement.src = e.target.result;
              this.toastr.success('Se ha subido la imagen exitosamente.', 'Operacion exitosa', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Se ha subido la imagen exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
            },
            error => {
              console.log('Error al subir la imagen a s3: ', error);
              this.toastr.error('Ha ocurrido un error al subir la imagen.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al subir la imagen', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
            }
          );
        }
      };
      reader.readAsDataURL(file);
    } else if (file && file.type.startsWith('video/')) {
      console.log('entra a video');

      let formData: FormData = new FormData();
      formData.append('video', file);
      formData.append('type', file.type);

      this.apiService.uploadVideoS3(formData).subscribe(
        result => {
          console.log('video subido a s3: ', result.url);
          this.resetFileState(index);
          this.stateItems[index].video = result.url;
          this.stateItems[index].tipo = file.type;
          setTimeout(() => {
            let videoPlayer = this.elementRef.nativeElement.querySelector('#videoPlayerState' + index);

            videoPlayer.src = result.url;
            videoPlayer.load();
            this.toastr.success('Se ha subido el video exitosamente.', 'Operacion exitosa', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Se ha subido el video exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
          }, 1000);
        },
        error => {
          console.log('Error al subir el video a s3: ', error);
          this.toastr.error('Ha ocurrido un error al subir el video.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al subir el video', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      );
    } else if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension === 'doc' || fileExtension === 'docx'
        || fileExtension === 'xls' || fileExtension === 'xlsx' || fileExtension === 'xlsm'
        || fileExtension === 'pdf') {
        let formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('type', file.type);

        this.apiService.uploadFileS3(formData).subscribe(
          result => {
            console.log('documento subido a s3: ', result.url);
            this.resetFileState(index);
            this.stateItems[index].archivo = result.url;
            this.stateItems[index].tipo = file.type;
          },
          error => {
            console.log('Error al subir el archivo a s3: ', error);
            this.toastr.error('Ha ocurrido un error al subir el documento.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al subir el documento', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          }
        );
      }
    }
  }

  onMoveNode(event: any) {
    // console.log("Moved", $event.node.name, "to", $event.to.parent.name, "at index", $event.to.index);
    console.log('drop event: ', event);
    console.log('parent inicial: ', event.from.parent);
    console.log('parent actual: ', event.to.parent);

    let nestedContent: any;

    if (event.from.parent.nested.length === 1) {
      nestedContent = null;
    } else {
      nestedContent = event.from.parent.nested.filter((id: any) => id !== event.node.identificadorFlujo);
      nestedContent = nestedContent.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    }

    let body: any = {
      identificadorConversacion: event.from.parent.identificadorConversacion,
      identificadorFlujo: event.from.parent.identificadorFlujo,
      nested: nestedContent
    }

    console.log('body actualizar padre inicial: ', body);

    this.apiService.updateConversation(body).subscribe(
      result => {

        if (!event.to.parent.nested || event.to.parent.nested.length === 0) {
          nestedContent = [event.node.identificadorFlujo];
        } else {
          event.to.parent.nested.push(event.node.identificadorFlujo);
          nestedContent = event.to.parent.nested;
          nestedContent = nestedContent.filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        }

        body = {
          identificadorConversacion: event.to.parent.identificadorConversacion,
          identificadorFlujo: event.to.parent.identificadorFlujo,
          nested: nestedContent
        }

        console.log('body actualizar padre final: ', body);

        this.apiService.updateConversation(body).subscribe(
          result => {
            // this.tree.treeModel.update();
            console.log('Se ha actualizado el flujo exitosamente.');
            this.toastr.success('Se ha actualizado la conversación exitosamente.', 'Operación exitosa', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Se ha actualizado la conversación exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
          },
          error => {
            console.log('Error al actualizar el flujo al mover el nodo: ', error);
            this.toastr.error('Ha ocurrido un error al mover el nodo.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Ha ocurrido un error al mover el nodo', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
            this.cargarFlujo(this.flowTitle);
          }
        );
      },
      error => {
        console.log('Error al actualizar el flujo al mover el nodo: ', error);
        // this.snackBar.open('Ha ocurrido un error al mover el nodo', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        this.toastr.error('Ha ocurrido un error al actualizar el nodo.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        this.cargarFlujo(this.flowTitle);
      }
    );
  }

  changeState(event: any, funcion: any) {

    let body: any = {
      id: funcion._id,
      habilitado: event.checked
    };

    console.log('body: ', body);

    return new Promise((resolve, reject) => {
      this.apiService.updateCompanyFunctions(body).subscribe(
        result => {
          console.log('Se ha actualizado la funcion exitosamente.', result);
          this.toastr.success('Se ha actualizado el estado exitosamente.', 'Operación exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Se ha actualizado el estado exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
          return resolve(result);
        },
        error => {
          console.log('Error al actualizar funcion de empresa: ', error);
          this.toastr.error('Ha ocurrido un error al actualizar el estado.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al actualizar el estado.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          return reject(error);
        }
      );
    });
  }


  cargarFlujo(title) {
    let body: any = {
      titulo: title
    };

    this.apiService.getConversations(body).subscribe(
      result1 => {
        this.selectedConversation = result1.find((conv: any) => conv.identificadorFlujo === 'F000000');
        if (this.selectedConversation) {

          body = {
            identificadorConversacion: this.selectedConversation.identificadorConversacion
          }

          this.apiService.getConversations(body).subscribe(
            result2 => {
              this.selectedConversation.disabled = true;
              this.selectedConversation.children = [];
              this.nodes = [];
              this.idFlowList = [];
              this.nodes.push(this.selectedConversation);
              this.idFlowList.push(this.selectedConversation.identificadorFlujo);
              this.addChildrenNodes(result2, this.nodes[0]);
              // this.tree.treeModel.update();
            },
            error => {
              console.log('Error al cargar flujos: ', error);
              this.toastr.error('Ha ocurrido un error al cargar la conversación.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al cargar la conversación', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
              // this.tree.treeModel.update();
            }
          );
        } else {
          body = {
            name: "Bienvenida/Saludo",
            titulo: this.flowTitle,
            keywords: [''],
            answers: [{ answer: [''], options: null, cb: null, nested: null }],
            identificadorFlujo: "F000000",
            type: "start",
            status: 'activo',
            nested: null
          };

          this.apiService.createConversation(body).subscribe(
            result => {
              console.log('conversacion creada exitosamente');

              body = {
                titulo: this.flowTitle
              };

              this.apiService.getConversations(body).subscribe(
                result => {
                  console.log('result encontrado: ', result);
                  this.selectedConversation = result.find((conv: any) => conv.identificadorFlujo === 'F000000');

                  body = {
                    identificadorConversacion: this.selectedConversation.identificadorConversacion
                  }

                  this.apiService.getConversations(body).subscribe(
                    result2 => {
                      this.selectedConversation.disabled = true;
                      this.selectedConversation.children = [];
                      this.nodes = [];
                      this.idFlowList = [];
                      this.nodes.push(this.selectedConversation);
                      this.idFlowList.push(this.selectedConversation.identificadorFlujo);
                      this.addChildrenNodes(result2, this.nodes[0]);
                      this.isLoading = false;
                      // this.tree.treeModel.update();
                    },
                    error => {
                      console.log('Error al cargar flujos: ', error);
                      this.toastr.error('Ha ocurrido un error al cargar la conversación.', 'Error', {
                        timeOut: 3000,
                        positionClass: 'toast-bottom-right',
                      });
                      // this.snackBar.open('Ha ocurrido un error al cargar la conversación', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
                      // this.tree.treeModel.update();
                    }
                  );
                  console.log('selectedConversation: ', this.selectedConversation);
                  console.log('id flujos lista: ', this.idFlowList);
                },
                error => {
                  console.log('Error al cargar flujo: ', error);
                  this.router.navigate(['/account/informacion']);
                }
              );
            },
            error => {
              console.log('Error al crear conversación: ', error);
              this.router.navigate(['/account/informacion']);
            }
          );
        }

        console.log('selectedConversation: ', this.selectedConversation);
        console.log('id flujos lista: ', this.idFlowList);
      },
      error => {
        console.log('Error al cargar flujo: ', error);
        this.isLoading = false;
        // this.snackBar.open('Ha ocurrido un error al cargar la conversación', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        this.toastr.error('Ha ocurrido un error al cargar la conversación.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    );
  }

  addChildNode(node: any) {
    if (node.data.children) {
      node.data.children.push({
        disabled: false,
        name: '',
        keywords: [''],
        answers: [{ answer: [''], options: null, cb: null, nested: null }],
        status: '',
        type: 'complement'
      });
    } else {
      node.data.children = [{
        disabled: false,
        name: '',
        keywords: [''],
        answers: [{ answer: [''], options: null, cb: null, nested: null }],
        status: ''
      }];
    }
    // this.tree.treeModel.update();
    // this.tree.treeModel.setExpandedNode(node, true);
  }

  editNode(node: any) {
    node.data.disabled = false;
    // this.tree.treeModel.update();
  }

  saveNode(node: any) {

    // console.log('nodo: ', node);
    // console.log('padre: ', node.parent);

    if (!node.data.name || node.data.name === '') {
      this.deleteNode(node);
    } else if (!node.data.identificadorFlujo) {

      let identificadorUnicoFlujo = 'F' + this.generarRandomString(6);
      let flagRepetido = true;

      while (flagRepetido) {
        // console.log('entra repetido, flow list: ', this.idFlowList);
        if (this.idFlowList.includes(identificadorUnicoFlujo)) {
          identificadorUnicoFlujo = 'F' + this.generarRandomString(6);
        } else {
          flagRepetido = false;
        }
      }

      console.log('identificadorUnicoFlujo', identificadorUnicoFlujo);

      let body: any = {
        name: node.data.name,
        identificadorFlujo: identificadorUnicoFlujo,
        identificadorConversacion: node.parent.data.identificadorConversacion,
        keywords: [''],
        answers: [{ answer: [''], options: null, cb: null, nested: null }],
        type: 'complement',
        status: 'activo',
        nested: null
      };

      node.data.identificadorFlujo = identificadorUnicoFlujo;
      node.data.identificadorConversacion = node.parent.data.identificadorConversacion;
      node.data.type = 'complement';
      node.data.status = 'activo';
      node.data.nested = null;

      this.apiService.createConversation(body).subscribe(
        result => {
          console.log('Flujo creado');

          let nestedParent;

          if (node.parent.data.nested && node.parent.data.nested.length > 0) {
            if (!node.parent.data.nested.includes(identificadorUnicoFlujo)) {
              nestedParent = [];
              for (let i = 0; i < node.parent.data.nested.length; i++) {
                const element = node.parent.data.nested[i];
                nestedParent.push(element);
              }
              nestedParent.push(identificadorUnicoFlujo);
            }
          } else {
            nestedParent = [identificadorUnicoFlujo];
          }

          body = {
            identificadorConversacion: node.parent.data.identificadorConversacion,
            identificadorFlujo: node.parent.data.identificadorFlujo,
            nested: nestedParent
          }

          // console.log('body actualizar padre: ', body);

          this.apiService.updateConversation(body).subscribe(
            result => {
              node.data.disabled = true;
              node.parent.data.nested = [];
              if (nestedParent && nestedParent.length > 0) {
                for (let i = 0; i < nestedParent.length; i++) {
                  const element = nestedParent[i];
                  node.parent.data.nested.push(element);
                }
              }

              // this.tree.treeModel.update();
              console.log('Se ha creado el flujo exitosamente.');
              this.toastr.success('Se ha creado el flujo exitosamente.', 'Operación exitosa', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Se ha creado el flujo exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
            },
            error => {
              node.data.disabled = true;
              // this.tree.treeModel.update();
              console.log('Error al actualizar el flujo padre: ', error);
              this.toastr.error('Ha ocurrido un error al actualizar el flujo padre.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al actualizar el flujo padre', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
            }
          );
        },
        error => {
          this.deleteNode(node);
          console.log('Error al crear flujo: ', error);
          this.toastr.error('Ha ocurrido un error al crear flujo.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al crear flujo', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      );
    } else {
      // console.log('solo cambia el nombre', node);

      let body: any = {
        identificadorConversacion: node.data.identificadorConversacion,
        identificadorFlujo: node.data.identificadorFlujo,
        name: node.data.name
      }

      this.apiService.updateConversation(body).subscribe(
        result => {
          node.data.disabled = true;
          // this.tree.treeModel.update();
          console.log('Se ha actualizado el flujo exitosamente.');
          this.toastr.success('Se ha actualizado el flujo exitosamente.', 'Operación exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Se ha actualizado el flujo exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
        },
        error => {
          node.data.disabled = true;
          // this.tree.treeModel.update();
          console.log('Error al actualizar el flujo: ', error);
          this.toastr.error('Ha ocurrido un error al actualizar el flujo.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al actualizar el flujo', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      );
    }
  }

  deleteNode(node: any) {
    console.log('nodo: ', node);

    if (!node.data.identificadorFlujo) {
      this.nodes = this.nodes.filter(n => n.id !== node.id);
      node.parent.data.children = node.parent.data.children.filter(n => n.id !== node.id);
      // this.tree.treeModel.update();
    } else {

      let body: any = {
        identificadorConversacion: node.data.identificadorConversacion,
        identificadorFlujo: node.data.identificadorFlujo
      }

      this.apiService.deleteConversation(body).subscribe(
        result => {

          if (node.parent && node.parent.data.nested && node.parent.data.nested.length > 0 && node.parent.data.nested.includes(node.data.identificadorFlujo)) {
            body = {
              identificadorConversacion: node.parent.data.identificadorConversacion,
              identificadorFlujo: node.parent.data.identificadorFlujo,
              nested: node.parent.data.nested.filter((n: any) => n !== node.data.identificadorFlujo)
            }

            // console.log('body actualizar padre: ', body);

            this.apiService.updateConversation(body).subscribe(
              result => {
                node.parent.data.nested = node.parent.data.nested.filter((n: any) => n !== node.data.identificadorFlujo);
                // this.tree.treeModel.update();
                console.log('Se ha eliminado el flujo exitosamente');
                this.toastr.success('Se ha eliminado el flujo exitosamente.', 'Operación exitosa', {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                });
                // this.snackBar.open('Se ha eliminado el flujo exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
                this.cargarFlujo(this.flowTitle);
              },
              error => {
                node.data.disabled = true;
                // this.tree.treeModel.update();
                console.log('Error al actualizar el flujo padre: ', error);
                this.toastr.error('Ha ocurrido un error al actualizar el flujo.', 'Error', {
                  timeOut: 3000,
                  positionClass: 'toast-bottom-right',
                });
                // this.snackBar.open('Ha ocurrido un error al actualizar el flujo', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
              }
            );
          } else {
            this.nodes = this.nodes.filter(n => n.id !== node.id);
            node.parent.data.children = node.parent.data.children.filter(n => n.id !== node.id);
            // this.tree.treeModel.update();
            console.log('Se ha eliminado el flujo exitosamente.')
            this.toastr.success('Se ha eliminado el flujo exitosamente.', 'Operación exitosa', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            // this.snackBar.open('Se ha eliminado el flujo exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
            this.cargarFlujo(this.flowTitle);
          }
        },
        error => {
          console.log('Error al eliminar el flujo: ', error);
          this.toastr.error('Ha ocurrido un error al eliminar el flujo.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al eliminar el flujo', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      );

    }

  }

  setKeyWord(index) {
    const inputElement = <HTMLInputElement>document.getElementById('keyword-input-' + index);
    if (inputElement) {
      const valorInput = inputElement.value;
      console.log(`Valor ${index + 1}: ${valorInput}`);
      this.selectedNode.data.keywords[index] = valorInput;
    }
  }

  addKeyword() {
    this.keywords.push('');
    this.selectedNode.data.keywords.push('');
  }

  removeKeyword(index: number) {
    this.keywords.splice(index, 1);
    this.selectedNode.data.keywords.splice(index, 1);
  }

  addBlock() {
    this.selectedNode.data.answers.push({ answer: [''], options: null, cb: null, nested: null });
    this.answers.push({ answer: [''], options: null, cb: null, nested: null });
  }

  removeBlock(index: any) {
    this.selectedNode.data.answers.splice(index, 1);
    this.answers.splice(index, 1);
  }

  addAnswer(index: any) {
    this.selectedNode.data.answers[index].answer.push('');
    this.answers[index].answer.push('');
  }

  removeAnswer(block: any, answer: any) {
    this.selectedNode.data.answers[block].answer.splice(answer, 1);
    this.answers[block].answer.splice(answer, 1);
  }

  addParameter() {
    this.companyParameters.push({ parametro: '', mensaje: [{ texto: '', respuestas: [] }], changed: false });
  }

  removeParameter(index: number) {
    console.log('indice: ', index);
    this.companyParameters.splice(index, 1);
  }

  guardarConfiguracionBot() {
    console.log('datos configuracion: ', this.botConfiguration);

    this.apiService.updateChatbotConfig(this.botConfiguration).subscribe(
      result => {
        console.log('configuracion actualizada');
        this.toastr.success('Se ha actualizado la configuración del bot exitosamente.', 'Operación exitosa', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Se ha actualizado la configuración del bot exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
        this.cargarConfiguracionBot();
      },
      error => {
        console.log('Error al actualizar configuracion del bot: ', error);
        this.toastr.error('Ha ocurrido un error al actualizar la configuración del bot.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al actualizar la configuración del bot.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    );
  }

  parameterChaged(index: number) {
    // console.log('index', index);
    // console.log('this.companyParameters', this.companyParameters);
    // console.log('this.companyParameters[index]', this.companyParameters[index]);
    this.companyParameters[index].changed = true;
  }

  guardarDatosEmpresa() {

    this.restLoading = true;

    for (let i = this.companyParameters.length - 1; i >= 0; i--) {
      if (!this.companyParameters[i].parametro
        || this.companyParameters[i].parametro === ''
        || !this.companyParameters[i].mensaje
        || this.companyParameters[i].mensaje[0].texto === '') {
        this.companyParameters.splice(i, 1);
      }
    }

    let body = {
      tipoFuncion: this.selectedFunctionType,
      datos: this.companyParameters
    }

    console.log('body: ', body);

    this.apiService.updateCompanyData(body).subscribe(
      result => {
        this.restLoading = false;
        this.closeModal('company-data-modal');
        console.log('datos actualizados');
        this.toastr.success('Se han actualizado los datos de la empresa exitosamente.', 'Operación exitosa', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Se han actualizado los datos de la empresa exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
        this.cargarDatosEmpresa();
      },
      error => {
        this.restLoading = false;
        console.log('Error al actualizar datos de la empresa: ', error);
        this.toastr.error('Ha ocurrido un error al actualizar los datos de la empresa.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al actualizar los datos de la empresa.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    );

  }

  cargarConfiguracionBot() {
    let body = {};

    return new Promise((resolve, reject) => {
      this.apiService.getChatbotConfig(body).subscribe(
        result => {

          this.botConfiguration = result;

          if (!this.botConfiguration.nombre) this.botConfiguration.nombre = '';
          if (!this.botConfiguration.instrucciones) this.botConfiguration.instrucciones = '';
          if (!this.botConfiguration.tipo) this.botConfiguration.tipo = '';
          if (!this.botConfiguration.personalidad) this.botConfiguration.personalidad = '';
          if (!this.botConfiguration.idioma) this.botConfiguration.idioma = '';
          if (!this.botConfiguration.key) this.botConfiguration.key = '';

          return resolve(this.botConfiguration);
        },
        error => {
          console.log('Error al cargar configuracion de bot: ', error);
          this.toastr.error('Ha ocurrido un error al obtener la configuracion del bot.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al obtener la configuracion del bot.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          return reject(error);
        }
      );
    });
  }

  getStageData() {
    let body = {};

    return new Promise((resolve, reject) => {
      this.apiService.getStageData(body).subscribe(
        result => {
          this.stageChatbotData = result;
          console.log('datos de etapas: ', this.stageChatbotData);
        },
        error => {
          console.log('Error al cargar datos de etapas: ', error);
          this.toastr.error('Ha ocurrido un error al obtener los datos de etapas.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al obtener la configuracion del bot.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          return reject(error);
        }
      );
    });
  }

  cargarDatosEmpresa() {

    let body = {
      tipoFuncion: this.selectedFunctionType
    };

    return new Promise((resolve, reject) => {
      this.apiService.getCompanyData(body).subscribe(
        result => {
          this.companyParameters = [
            { parametro: '', mensaje: [{ texto: '', respuestas: [] }], changed: false }
          ];
          console.log('result obtener datos empresas: ', result);

          if (result && result.datos && result.datos.length > 0) {
            this.companyParameters = result.datos;

            for (let i = 0; i < this.companyParameters.length; i++) {
              this.companyParameters[i].changed = false;

              if (!this.companyParameters[i].mensaje[0].respuestas || this.companyParameters[i].mensaje[0].respuestas.length === 0) {
                this.companyParameters[i].mensaje[0].respuestas = [];
              }
            }
          }

          return resolve(result);
        },
        error => {
          console.log('Error al cargar datos de empresa: ', error);
          this.toastr.error('Ha ocurrido un error al obtener los datos de empresa.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al obtener los datos de empresa.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          return reject(error);
        }
      );
    });
  }

  cargarFuncionesEmpresa() {

    let body: any = {};

    return new Promise((resolve, reject) => {
      this.apiService.getCompanyFunctions(body).subscribe({
        next: (result: any) => {

          this.functions = [];
          for (let i = 0; i < result.length; i++) {
            this.functions.push({
              _id: result[i]._id,
              identificadorEmpresa: result[i].identificadorEmpresa,
              tipoFuncion: result[i].name || result[i].tipoFuncion,
              descripcion: result[i].description,
              imagen: result[i].imagen,
              isChecked: result[i].habilitado || false
            });

            switch (this.functions[i].tipoFuncion) {
              case 'datos_empresa':
                this.functions[i].nombre = 'Datos de empresa'
                break;
              case 'get_product':
                this.functions[i].nombre = 'Obtener producto'
                break;
              case 'contactSupport':
                this.functions[i].nombre = 'Contacto a soporte'
                break;
              case 'planes_empresa':
                this.functions[i].nombre = 'Planes de empresa'
                break;
              case 'links_pagos_empresa':
                this.functions[i].nombre = 'Links de pago de la empresa'
                break;
              default:
                this.functions[i].nombre = this.functions[i].tipoFuncion;
                break;
            }
          }

          console.log('funciones empresas: ', this.functions);

          this.updatePagination();
          return resolve(result);
        },
        error: (error: any) => {
          console.log('Error al cargar funciones de empresa: ', error);
          this.toastr.error('Ha ocurrido un error al obtener las funciones de empresa.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al obtener las funciones de empresa.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          return reject(error);
        },
        // complete: () => console.info('complete')
      });
    });
  }

  sendData() {
    console.log('node: ', this.selectedNode);

    for (let i = 0; i < this.answers.length; i++) {
      for (let j = 0; j < this.answers[i].answer.length; j++) {
        if (!this.answers[i].answer[j] || (this.answers[i].answer[j] === '')) {
          this.answers[i].answer.splice(j, 1);
        }
      }
      if (!this.answers[i].answer || (this.answers[i].answer.length === 0 && !this.answers[i].options)) {
        this.answers.splice(i, 1);
      }
    }

    let body: any = {
      identificadorConversacion: this.selectedNode.data.identificadorConversacion,
      identificadorFlujo: this.selectedNode.data.identificadorFlujo,
      keywords: this.keywords,
      answers: this.answers,
      status: this.selectedStatus
    }

    console.log('body actualizar padre: ', body);

    this.apiService.updateConversation(body).subscribe(
      result => {
        this.keywords = [''];
        this.answers = [{ answer: [''], options: null, cb: null, nested: null }];
        this.closeModal('node-modal-content');
        this.cargarFlujo(this.flowTitle);
        // this.tree.treeModel.update();
        console.log('Se ha actualizado el flujo exitosamente.');
        this.toastr.success('Se ha actualizado el flujo exitosamente.', 'Operación exitosa', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Se ha actualizado el flujo exitosamente.', '×', { panelClass: 'success', verticalPosition: 'top', duration: 5000 });
      },
      error => {
        // this.tree.treeModel.update();
        console.log('Error al actualizar el flujo: ', error);
        this.toastr.error('Ha ocurrido un error al actualizar el flujo.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al actualizar el flujo', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    );
  }

  updateAditionalStateData() {
    let body = {
      id: this.selectedState._id,
      etapa: this.selectedState.etapa,
      datosAdicionales: this.stateItems[0]
    }

    this.apiService.updateChatbotStateData(body).subscribe(
      result => {
        this.socketService.emit('getData', { "requirement": "tablaInstrucciones" });
        this.closeModal('estadoModal');
        console.log('Se ha actualizado el estado exitosamente.');
        this.toastr.success('Se ha actualizado el estado exitosamente.', 'Operación exitosa', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });

      },
      error => {
        console.log('Error al actualizar el estado: ', error);
        this.toastr.error('Ha ocurrido un error al actualizar el estado.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    );
  }

  generarRandomString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
  }

  addChildrenNodes(conversaciones: any, node: any) {
    node.children = [];

    if (node.nested && node.nested.length > 0) {
      for (let i = 0; i < node.nested.length; i++) {
        const element = node.nested[i];
        let child: any = conversaciones.find((conv: any) => conv.identificadorFlujo === element);
        child.children = [];
        child.disabled = true;
        this.idFlowList.push(child.identificadorFlujo);
        node.children.push(child);
        if (child.nested && child.nested.length > 0) {
          this.addChildrenNodes(conversaciones, child);
        }
      }
    } else {
      node.disabled = true;
      this.idFlowList.push(node.identificadorFlujo);
    }
  }

  cargarCodigoQR(times: number) {
    let body: any = {};

    this.apiService.getQRCode(body).subscribe(
      result => {
        // console.log('QR actualizado: ', result.qr);

        this.qrData = 'cargando';
        this.piePercentValue = 0;

        setTimeout(() => {
          this.qrData = result.qr;
          this.qrConnection = result.connection;
          this.piePercentValue = 100;
        }, 500);
      },
      error => {
        this.qrData = null;
        console.log('Error al cargar QR: ', error);
        this.toastr.error('Ha ocurrido un error al cargar el código QR.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    );
  }

  logoutQR() {
    let body: any = {};

    this.apiService.logoutQR(body).subscribe(
      result => {
        // console.log('QR actualizado: ', result.qr);
        this.toastr.success('El usuario se ha desconectado exitosamente.', 'Operación exitosa', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      },
      error => {
        console.log('Error al cargar QR: ', error);
        this.toastr.error('Ha ocurrido un error al desconectar al usuario.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
        // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    );
  }

  getPaginatedItems(): any[] {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.functions.slice(startIndex, endIndex);
  }

  setPage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.updatePagination();
  }

  updatePagination() {
    let totalItems = this.functions.length;
    const totalNumPages = Math.ceil(totalItems / this.pageSize);
    this.totalPages = Array.from({ length: totalNumPages }, (_, index) => index + 1);

    // Lógica para determinar las páginas visibles en el paginador
    const middlePage = Math.ceil(this.maxVisiblePages / 2);
    let startPage = this.currentPage - middlePage + 1;
    let endPage = this.currentPage + middlePage - 1;

    if (startPage < 1) {
      startPage = 1;
      endPage = Math.min(this.maxVisiblePages, totalNumPages);
    }

    if (endPage > totalNumPages) {
      endPage = totalNumPages;
      startPage = Math.max(1, endPage - this.maxVisiblePages + 1);
    }

    this.visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, index) => index + startPage);
  }



  // getPaginatedItemsStates(): any[] {
  //   const startIndex = (this.currentPageStates - 1) * this.pageSizeStates;
  //   const endIndex = startIndex + this.pageSizeStates;
  //   return this.stateData.slice(startIndex, endIndex);
  // }

  // setPageStates(pageNumber: number) {
  //   this.currentPageStates = pageNumber;
  //   this.updatePaginationStates();
  // }

  // updatePaginationStates() {
  //   let totalItems = this.stateData.length;
  //   const totalNumPages = Math.ceil(totalItems / this.pageSizeStates);
  //   this.totalPagesStates = Array.from({ length: totalNumPages }, (_, index) => index + 1);

  //   // Lógica para determinar las páginas visibles en el paginador
  //   const middlePage = Math.ceil(this.maxVisiblePagesStates / 2);
  //   let startPage = this.currentPageStates - middlePage + 1;
  //   let endPage = this.currentPageStates + middlePage - 1;

  //   if (startPage < 1) {
  //     startPage = 1;
  //     endPage = Math.min(this.maxVisiblePagesStates, totalNumPages);
  //   }

  //   if (endPage > totalNumPages) {
  //     endPage = totalNumPages;
  //     startPage = Math.max(1, endPage - this.maxVisiblePagesStates + 1);
  //   }

  //   this.visiblePagesStates = Array.from({ length: endPage - startPage + 1 }, (_, index) => index + startPage);
  // }

  // addToast(options) {

  //   console.log('toast saliendo');
  //   if (options.closeOther) {
  //     this.toastyService.clearAll();
  //   }
  //   console.log('toast saliendo', 1);

  //   this.position = options.position ? options.position : this.position;
  //   console.log('toast saliendo', 2);

  //   const toastOptions: ToastOptions = {
  //     title: options.title,
  //     msg: options.msg,
  //     showClose: options.showClose,
  //     timeout: options.timeout,
  //     theme: options.theme,
  //     onAdd: (toast: ToastData) => {
  //       console.log('On add toast');
  //     },
  //     onRemove: (toast: ToastData) => {
  //       console.log('Removed toast');
  //     }
  //   };
  //   console.log('toast saliendo', 3, toastOptions);

  //   switch (options.type) {
  //     case 'default': this.toastyService.default(toastOptions); break;
  //     case 'info': this.toastyService.info(toastOptions); break;
  //     case 'success': this.toastyService.success(toastOptions); break;
  //     case 'wait': this.toastyService.wait(toastOptions); break;
  //     case 'error': this.toastyService.error(toastOptions); break;
  //     case 'warning': this.toastyService.warning(toastOptions); break;
  //   }

  //   console.log('toast saliendo', 4);
  // }


  closeModal(name: string) {
    // $('.' + name).removeClass('active');
    document.querySelector('#' + name).classList.remove('md-show');
  }
}
