import { Component, OnInit, Output, Input,EventEmitter} from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ApiService} from '../../services/api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { emailValidator } from 'src/app/theme/utils/app-validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @Output() output_control: EventEmitter<boolean> = new EventEmitter(true);

  firstFormGroup: FormGroup;
  isEditable = false;
  email = '';

  message_header_newPassword = '<div style=\"padding: 20px;\"><div style=\"margin: 32px auto; display: flex; flex-direction: row; justify-content: center;\"><div style=\"margin: auto;\"><img  src=\"https://s3-us-west-2.amazonaws.com/pruebas.ushops/logotipo.jpg\" alt=\"\"></div></div><div style=\"margin: 0px auto; border-radius: 5px; border: none; background-color: rgb(237, 250, 248); max-width: 500px; padding: 25px 15px; font-size: 16px;\"><h1 style=\"font-size: 20px; text-align: center;\">Proceso de restauración de contraseña</h1><p style=\"text-align: center; font-size: 16px;\">Con este correo uShops te informa que se ha solicitado un acceso a tu cuenta con una contraseña provisional</p><p style=\"text-align: center; font-size: 14px;\">Una vez ingresado a tu cuenta debes cambiar la contraseña a una nueva para que tu usuario vuelva a estar habilitado</p></div><div style=\"max-width: 500px; margin: 0px auto;\"><p style=\"text-align: center; font-size: 16px;\">Tu contraseña provisional es </p><h1 style=\"text-align: center; letter-spacing: 2px; font-size: 32px; font-weight: bold;\">'
  message_body_newPassword = '</h1><p style=\"text-align: center; font-size: 14px;\">Si tienes algún problema escríbenos: services@ushops.tech</p></div></div>'

  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    private api: ApiService,
    public snackBar: MatSnackBar
  ) { 
  }

  ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, emailValidator])],
    });
    console.log("estamos en app reset password")
  }

  siguientePaso(values: FormGroup){
    this.email = values['email'];
  }

  cerrarModal(values: FormGroup){

    if(this.firstFormGroup.valid){
      const body = {
        device : 'web',
        email: values['email'],
        scope: 'uShops-platform'
      }
      console.log(body);
      this.api.resetPass(body).subscribe(
        result =>{
          console.log(result)
          
          this.snackBar.open('Codigo enviado con éxito!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
          // this.sendEmail(body.email, result['codigoVerificacion']); 
        },
        error=>{
          console.log(error);
        }
      )
    } else {
      this.isEditable = true;
    }


    this.output_control.emit(false);
  }

  sendEmail(email, codigo) {
    const body = {
      titulo: "uSHops - Recuperar clave",
      de: "uShops",
      email: 'edys_017@hotmail.com',
      mensaje: this.message_header_newPassword + codigo + this.message_body_newPassword
    }
    this.api.sendEmail(body).subscribe(
      result =>{
        console.log('CORREO ENVIADO',result);
      },
      error =>{
        console.log(error)
      }
    )
  }

}
