import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class ScheduleService {

    ipPublica = environment.urlApi;
    // baseChatbotWhatsappUrl = this.ipPublica + '/ushops/chatbot'
    baseChatbotWhatsappUrl = this.ipPublica + '/ushops/xpertAssistant'

    constructor(private http: HttpClient, private auth: AuthService) {
    }


    
    obtenerEstadoCita(parameters): Observable<any> {
        // const options = {
        //     headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        // };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerEstadoCita`, parameters)
            .pipe(catchError(this.handleError));
    }


    
    getAvailableDays(parameters): Observable<any> {
        // const options = {
        //     headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        // };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerDiassDisponibles`, parameters)
            .pipe(catchError(this.handleError));
    }

    getAvailableHours(parameters): Observable<any> {
        // const options = {
        //     headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        // };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/obtenerHorariosDisponibles`, parameters)
            .pipe(catchError(this.handleError));
    }

    createAppointment(parameters): Observable<any> {
        const options = {
            headers: new HttpHeaders({ ['Authorization']: `Bearer ${this.auth.getToken()}` })
        };

        return this.http.patch(`${this.baseChatbotWhatsappUrl}/crearCita`, parameters, options)
            .pipe(catchError(this.handleError));
    }


    handleError(error: HttpErrorResponse) {
        console.log('error desconocido: ', error);
        if (error.error instanceof ErrorEvent) {
        } else {
            if (error.status === 401) {
                console.error('no autorizado:');
                localStorage.removeItem('jwt');
            }
        }
        return throwError(new Error(error.statusText));
    }
}