import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { matchingPasswords } from 'src/app/theme/utils/app-validators';
import {AppService} from './../../../../app.service';


@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss']
})
export class ResetPassComponent implements OnInit {

  @Output() ResetPassResponse = new EventEmitter();

  hide = true;
  hide1 = true;
  hide2 = true;
  insertPass : FormGroup;
  resetPass: FormGroup;
  preloaderInsertPass = false;
  preloaderResetPass = false;
  incorrectPass = false;

  modalControl = false;

  constructor(private api: ApiService, 
    private auth: AuthService, 
    public formBuilder: FormBuilder, 
    public snackBar: MatSnackBar,
    public appService: AppService) {
  
  }

  ngOnInit(): void {
    this.insertPass = this.formBuilder.group({
      contrasenaAnterior : ['',Validators.compose([Validators.required])]
    });
    this.resetPass = this.formBuilder.group({
      contrasenaNueva : ['', Validators.required, Validators.minLength(5)],
      confirmContrasenaNueva : ['', Validators.required,  Validators.minLength(5)],
    });
  }



  nextStep(value: FormGroup){ 
    if(this.insertPass.valid){
      this.preloaderInsertPass = true;
      this.incorrectPass = false;
      const body = {
        device : 'web',
        username: this.auth.getUsername(),
        scope: 'uShops-platform',
        password: value['contrasenaAnterior'],
      }
      console.log(body)
      this.api.authentication(body).subscribe(
        result => {
          console.log(result);
          this.incorrectPass = false;
          document.querySelector('#' + 'step_1').classList.add('step-hide');
          document.querySelector('#' + 'step_2').classList.add('step-show');
          this.preloaderInsertPass = false;
        },
        error => {
          this.preloaderInsertPass = false;
          this.incorrectPass = true;
        }
      )
    }
  }

  backStep(){
    document.querySelector('#' + 'step_1').classList.remove('step-hide');
    document.querySelector('#' + 'step_2').classList.remove('step-show');
  }
  closeModal(){
    document.querySelector('#' + 'modalWindow').classList.remove('modal-show');
  }
  submitForm(valueOldPass: FormGroup, valueNewPass: FormGroup){
    // document.querySelector('#' + 'modalWindow').classList.remove('modal-show');
    // this.backStep();
    console.log('validacin', this.resetPass.valid)
    console.log('validacin', this.resetPass.get('contrasenaNueva').value)
    console.log('validacin', this.resetPass.get('confirmContrasenaNueva').value)
    // if(this.resetPass.valid){ 
      this.preloaderResetPass = true;
      const body = {
        device : 'web',
        username: this.auth.getUsername(),
        scope: 'uShops-platform',
        contrasenaAnterior: valueOldPass['contrasenaAnterior'],
        // contrasenaNueva: valueNewPass['contrasenaNueva']
        // contrasenaAnterior: this.resetPass.get('contrasenaAnterior').value,
        contrasenaNueva: this.resetPass.get('contrasenaNueva').value
      }
      console.log(body);
      this.api.changePass(body).subscribe(
        result=> {
          console.log(result);
          this.snackBar.open('Tu contraseña ha sido cambiada exitosamente!', '×', {panelClass: 'success', verticalPosition: 'top', duration: 3000});
          this.preloaderResetPass = false;
          this.backStep();
          document.querySelector('#' + 'modalWindow').classList.remove('modal-show');
          this.insertPass.reset();
          this.resetPass.reset();
          this.ResetPassResponse.emit('Done');
        },
        error => {
          console.log(error);
        }
      )
    // }
  }

  abrirModal(){
    console.log("modal")
    this.modalControl = !this.modalControl;
  }

  cerrarModal(control: boolean){
    this.modalControl = control;
    console.log("sign-in-cerrar-Modal")
 }

}

