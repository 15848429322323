import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Data, AppService } from '../../../app.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import { interval } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],

})
export class TopMenuComponent implements OnInit {
  
  public autenticacion;
  public nombreUsuario;
  public usuario_autenticado;
  public habilitacionTienda;
  

  public currencies = ['USD', 'EUR'];
  public currency: any;
  public flags = [
    { name: 'English', image: 'assets/images/flags/gb.svg' },
    { name: 'German', image: 'assets/images/flags/de.svg' },
    { name: 'French', image: 'assets/images/flags/fr.svg' },
    { name: 'Russian', image: 'assets/images/flags/ru.svg' },
    { name: 'Turkish', image: 'assets/images/flags/tr.svg' }
  ];
  public flag: any;

  notificaciones = [];
  numeroNotificaciones = 0;
  badgetHidden = true;
  user_auth;
  store_auth;
  idStore

  constructor(private api : ApiService, 
    public appService: AppService, 
    public auth: AuthService, 
    public router: Router
    ) {
    // console.log(this.appService.usuario.habilitacionTienda);
    if(this.auth.getHabilitacionTienda() === 'true')
    {
      this.appService.usuario.habilitacionTienda = true;
    } else {
      this.appService.usuario.habilitacionTienda = false;
    }
    // this.obtenerNotificaciones();
   }

  ngOnInit() {
    this.autenticacion = this.auth.getAuth();
    this.habilitacionTienda = this.auth.getHabilitacionTienda();
    // console.log(this.habilitacionTienda)
    // console.log(this.appService.usuario.autenticado);
    this.nombreUsuario = this.auth.username;
    // console.log(this.auth.username);
    // console.log(this.appService.usuario.username)

    this.user_auth = this.appService.usuario.autenticado;
    this.store_auth = this.appService.usuario.habilitacionTienda;

    const contador = interval(60000);
    
    contador.subscribe(
      n => {
        // this.obtenerNotificaciones();    // obtiene las notificaciones
      }
    )
  }

  public obtenerNotificaciones(){
    const body = {
      scope : "uShops-platform",
    }
    // console.log('NOTIFICACIONES')
    this.api.getNotifications(body).subscribe(
      async result=>{
        // console.log('notificaciones',result);
        this.notificaciones = [];
        let arrayNot = Object.values(result);
        // arrayNot.forEach(item => {
        //     const interval = this.calcularTiempo(item['fechaCreacion']);
        //     const notify = {
        //       imagen: item['imagen']['URL'],
        //       mensaje: item['mensaje'],
        //       time: interval,
        //       estadoNotificacion : item['estadoNotificacion'],
        //       identificadorEmpresa: item['identificadorEmpresa'],
        //       parametrosAdicionales : item['parametrosAdicionales'],
        //       tipo: item['tipo'],
        //       identificadorNotificacion: item['identificadorNotificacion']
        //     }
        //     this.notificaciones.push(notify)
        // });
        // const pendientNotifications = this.notificaciones.filter(function(notify) {
        //   return notify['estadoNotificacion'] === 'pendiente';
        // });
        // this.numeroNotificaciones = pendientNotifications.length;
        // if(this.numeroNotificaciones === 0){
        //   this.badgetHidden = true;            
        // } else {
        //   this.badgetHidden = false;
        // }
        let arrayNotifyToShow = Promise.all(arrayNot.map(async (item)=>{
          return new Promise((resolve,reject)=>{
            const interval = this.calcularTiempo(item['fechaCreacion']);
            const notify = {
              imagen: item['imagen']['URL'],
              mensaje: item['mensaje'],
              time: interval,
              estadoNotificacion : item['estadoNotificacion'],
              identificadorEmpresa: item['identificadorEmpresa'],
              parametrosAdicionales : item['parametrosAdicionales'],
              tipo: item['tipo'],
              identificadorNotificacion: item['identificadorNotificacion']
            }
            resolve(notify);
          });
        })).then(
          result1=>{              
            this.notificaciones = Object.values(result1).reverse();
            // console.log(this.notificaciones);
            const pendientNotifications = this.notificaciones.filter(function(notify) {
              return notify['estadoNotificacion'] === 'pendiente';
            });
            this.numeroNotificaciones = pendientNotifications.length;
            if(this.numeroNotificaciones === 0){
              this.badgetHidden = true;            
            } else {
              this.badgetHidden = false;
            }
          },
          error1 =>{}
        );
      },
      error => {
        this.notificaciones = [];
        this.numeroNotificaciones = 0;
        this.badgetHidden = true;
      }
    )
  }

  calcularTiempo(dateNotifyStr: string){
    let date: Date = new Date();
    // let dateNotifyStrMod = (((dateNotifyStr.split("-").join('/')).split('T').join(' ')).split('.'))[0];
    let dateNotify = new Date(dateNotifyStr);

    
    let intervalo = (date.getTime() - dateNotify.getTime())

    if ((intervalo/1000/60)<=1){
      return 'ahora';
    } else if ((intervalo/1000/60)<60 && (intervalo/1000/60)>1){
      return (intervalo/1000/60).toFixed(0) + ' m'
    } else if ((intervalo/1000/60)>=60 && (intervalo/1000/60)<1440){
      return (intervalo/1000/60/60).toFixed(0) + ' h'
    } else {
      return (intervalo/1000/60/60/24).toFixed(0) + ' d'
    }
  }

  actionNotificacion(index: any){
    // console.log(index);
    // console.log(this.notificaciones[index])
    const body = {
      scope: 'uShops-platform',
      device: 'web',
      identificadorNotificacion: this.notificaciones[index]['parametrosAdicionales']['notID'],
    }
    // console.log(body);
    this.api.actionNotification(body).subscribe(
      result => {
        // console.log(result);
        if(this.notificaciones[index]['tipo']=== 'correccion producto'){
          this.router.navigate(['/account/publicacionProductos']);
        } else if (this.notificaciones[index]['tipo']=== 'aprobacion producto'){
          this.router.navigate(['/account/publicados']);
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  public changeCurrency(currency) {
    this.currency = currency;
  }

  public changeLang(flag) {
    this.flag = flag;
  }

  public signOUT() {
    this.auth.delAuth();
    this.appService.usuario.autenticado = false;
    this.appService.usuario.usuarioID = null;
    this.appService.usuario.username = '';
    this.appService.usuario.habilitacionTienda = false;
    this.auth.clearParameters();
    // this.auth.setUsername({username:''});
    // this.auth.setToken({jwt:null});
    this.router.navigate(['/']);
    setTimeout(function() {
      window.location.reload();
    }, 25);
    // this.router.navigateByUrl('/search', {skipLocationChange: true}).then(() =>
  }

  IrTienda(){
    this.idStore = this.auth.getIdentificadorEmpresa();
    console.log('idStore', this.idStore);
    this.router.navigate(['/negocio/v1'],{queryParams: {codigo: this.idStore}});
  }
}
