import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class SocketIoService {
    private socket: Socket;

    constructor(private auth: AuthService) {

        console.log('token socket: ', this.auth.getToken());

        this.socket = io('https://test-connection-xpert.egm-robotics.com', {
            extraHeaders: {
                'jwt': this.auth.getToken()
            }
        });

        // Manejar la conexión exitosa
        this.socket.on('connect', () => {
            console.log('Conexión exitosa al servidor de Socket.IO');
        });

        // Manejar errores de conexión
        this.socket.on('connect_error', (error: any) => {
            console.error('Error en la conexión:', error);
        });

        // Manejar desconexión
        this.socket.on('disconnect', (reason: string) => {
            console.log('Desconectado del servidor:', reason);
        });
    }

    // Método para escuchar eventos
    listen(eventName: string) {
        return new Observable((subscriber) => {
            this.socket.on(eventName, (data: any) => {
                subscriber.next(data);
            });
        });
    }

    // Método para emitir eventos
    emit(eventName: string, data: any) {
        this.socket.emit(eventName, data);
    }
}