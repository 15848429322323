import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-info-pago-transfer',
  templateUrl: './info-pago-transfer.component.html',
  styleUrls: ['./info-pago-transfer.component.scss']
})
export class InfoPagoTransferComponent implements OnInit {

  @Output() InfoPagoTranserResponse = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  confirmInfoTransfer(){
    this.InfoPagoTranserResponse.emit('close');
  }

  

}
