import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sign-in-modal',
  templateUrl: './sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.scss']
})
export class SignInModalComponent implements OnInit {
  @Output() SignInResponse = new EventEmitter();
  hide = true;
  signinForm: FormGroup;
  preloaderLoginForm = false;

  constructor( 
    public formBuilder: FormBuilder,
    private api: ApiService,
    private auth: AuthService,
    private appService: AppService,
    public snackBar: MatSnackBar,
    public router: Router) { }

  ngOnInit(): void {
    this.signinForm = this.formBuilder.group({
      user: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(4)])]
    })
  }
  submitForm(form : any){
    console.log(form);
    return;
    if(this.signinForm.valid)
    {
      this.preloaderLoginForm = true;
      const body = {
        device : 'web',
        username: form['user'],
        scope: 'uShops-platform',
        password: form['password']
      };
      this.api.authentication(body).subscribe(
        result =>{
          this.preloaderLoginForm = false;
          console.log(result);
          console.log(result['jtoken'])
          console.log(result['parameters']['estado'])
          this.snackBar.open('Ingreso exitoso!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
          this.auth.setNombres(result['parameters']['nombres'])
          this.auth.setToken(result['jtoken'])
          this.auth.setUsername(result['parameters']['email']);
          this.auth.setAuth();
          this.auth.setIdentificadorEmpresa(result['parameters']['identificadorEmpresa'])
          if (result['parameters']['estado']==='cambio contraseña') {
            this.router.navigate(['account/informacion']);
          }
          // } else this.router.navigate(['/']);
          if (result['parameters']['estadoTienda'] === 'habilitado'){
            this.auth.setHabilitacionTienda('true');
            this.appService.usuario.habilitacionTienda = true;
          } else {
            this.auth.setHabilitacionTienda('false');
            this.appService.usuario.habilitacionTienda = false;
          }
          this.SignInResponse.emit('Done');
        },
        error=>{
          this.preloaderLoginForm = false;
          this.snackBar.open('Contraseña incorrecta!', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        }
      )
    }
  }
}
