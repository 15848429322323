import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from '../../shared/products-carousel/product-dialog/product-dialog.component';
import { AppService } from '../../app.service';
import { Product, Category, Stores } from "../../app.models";
import { Location } from '@angular/common';

import {ApiService} from '../../services/api.service';
import { FnParam } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  @ViewChild('sidenav') sidenav: any;
  public sidenavOpen:boolean = true;
  private sub: any;
  public viewType: string = 'grid';
  public viewCol: number = 25; //25
  public viewCol_related: number = 20; //25
  public viewCol_stores: number = 33.3; 
  public counts = [12, 24, 36];
  public count:any;
  public sortings = ['Por defecto', 'Promociones', 'Precios bajos', 'Precios altos'];
  public sort:any;
  // public products: Array<Product> = [];
  // public products: Array<Product> = [];
  public products: Array<any> = [];
  public categories:Category[];
  public brands = [];
  public priceFrom: number = 750;
  public priceTo: number = 1599;
  public colors = ["#5C6BC0","#66BB6A","#EF5350","#BA68C8","#FF4081","#9575CD","#90CAF9","#B2DFDB","#DCE775","#FFD740","#00E676","#FBC02D","#FF7043","#F5F5F5","#000000"];
  public sizes = ["S","M","L","XL","2XL","32","36","38","46","52","13.3\"","15.4\"","17\"","21\"","23.4\""];
  public page:any;
  address: string;
  busqueda: string;

  products_total: Array<any> = [];
  products_min: Array<Product> = [];
  products_related_min: Array<Product> = [];
  stores_min: Array<Stores> = [];

  tipo;
  prm;
  query_categ;
  categ;
  visualizacion = 'todo';
  indicador = 'N';

  constructor(private activatedRoute: ActivatedRoute, public appService:AppService, public dialog: MatDialog, private router: Router,private  api: ApiService, public _location: Location) { }

  ngOnInit() {   
    this.count = this.counts[0];
    this.sort = this.sortings[0];
    this.prm = this.activatedRoute.queryParamMap.subscribe(params => {
      console.log(params); // { order: "popular" }
      // this.order = params.search;
      // this.query_categ = params.search;
      this.query_categ = {...params };
      console.log('prms-qy-p',this.query_categ); // popular
    }
  );

    this.sub = this.activatedRoute.params.subscribe(params => {
      console.log('params-product', params)
      this.categ = params.categoria;
      this.busqueda = params.texto;
      this.tipo = params.tipo;
      switch (params.tipo) {
        case 'similares':
          console.log('indicador', params.indicador);
          if (params.indicador === undefined) {
            this.visualizacion = 'secciones';
          } else {
            this.indicador = params.indicador;
          }
          this.categ = params.categoria;
          this.busquedaGlobal(params.categoria, params.texto);
          // this.getPublicitados();
          break;
        case 'masvendidos':
          this.getMasVendidos();
          break;
        case 'recomendados':
          this.getRecomendados();
          break;
        case 'publicitados':
          this.getPublicitados();
          break;
        case 'promociones':
          this.getMasVendidos();
          break;
        case 'relacionados':
          this.getRelacionados();
          break;
        case 'bycategory':
          console.log('indicador', params.indicador)
          this.categ = params.texto;
          if (params.indicador === undefined) {
            this.visualizacion = 'secciones';
          } else {
            this.indicador = params.indicador;
          }
          this.getByCategories(params.texto);
          break;
        default:
          console.log('indicador', params.indicador);
          if (params.indicador === undefined) {
            this.visualizacion = 'secciones';
          } else {
            this.indicador = params.indicador;
          }
          this.categ = params.categoria;
          this.busquedaGlobal('all', this.query_categ.params.search);
          break;
      }

    });
    if(window.innerWidth < 600){
      this.sidenavOpen = false;
    };
    if (window.innerWidth > 1100) {
      this.viewCol = 25;
      this.viewCol_related = 20;
      this.viewCol_stores = 33.3;
    } else if (window.innerWidth > 900 && window.innerWidth < 1100 ) {
      this.viewCol = 33.3;
      this.viewCol_related = 25;
      this.viewCol_stores = 50;
    } else if (window.innerWidth > 700 && window.innerWidth < 900) {
      this.viewCol = 50;
      this.viewCol_related = 33.3;
      this.viewCol_stores = 100;
    } else if (window.innerWidth > 600 && window.innerWidth < 700) {
      this.viewCol = 100;
      this.viewCol_related = 50;
      this.viewCol_stores = 100;
    } else if (window.innerWidth > 100 && window.innerWidth < 600) {
      this.viewCol = 50;
      this.viewCol_related = 50;
      this.viewCol_stores = 100;
    } else {
      this.viewCol = 100;
      this.viewCol_related = 100;
      this.viewCol_stores = 100;
    }

    this.getCategories();
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  getRelacionados() {
    const body = {
      scope: 'uShops-platform',
      device: 'web',
      categoria: 'all',
      search: this.query_categ.params.search
  }
  console.log('body', body)
    this.api.searchProductsGlobal(body).subscribe(
      result => {
        console.log('relacionados',result);
        this.products_total = result['relacionados'];
      },
      error => {
          console.log(error)
        }
      )
  }

  getByCategories(categoria) {
    // const address = document.location.href;
    const body = {
        scope: 'uShops-platform',
        device: 'web',
        categoria: categoria,
        search: ''
      }
      console.log('body-Categ', body);
      this.api.searchProductsGlobal(body).subscribe(
        result => {
          this.products = result;
          if (this.visualizacion === 'secciones') {
            console.log('searchbycategory',this.products)
            this.products_min = this.products['productos'];
            if (this.products['tiendas']?.length > 3) {
              this.stores_min = this.products['tiendas'].slice(0,3);
              console.log('slice-T',this.stores_min)
            } else {
              this.stores_min = this.products['tiendas'];
            }
            if (this.products['relacionados']?.length > 5) {
              this.products_related_min = this.products['relacionados'].slice(0,5);
              console.log('slice-R',this.products_related_min)
            } else {
              this.products_related_min = this.products['relacionados'];
            }
          } else {
            switch(this.indicador) {
              case 'T':
                this.products_total = this.products['tiendas'];
                break;
              case 'R':
                this.products_total = this.products['relacionados'];
            }
          }          
          console.log(this.products);
        },
          error => {
              console.log(error)
            }
          )
  }

  public getAllProducts(){
    this.api.getProductsRecomended().subscribe(
      result => {
        console.log(result)
        this.products = result;
      },
      error => {
        console.log(error)
      }
    )
  }

  verTodo(variable){
    if(variable == 'S'){
      // console.log('categ',this.categ)
      if(this.products['tiendas'].length > 0) {
        this.router.navigate(['/stores/bycategory', this.categ]);
      }
    }else{
      if(this.tipo === 'similares') {
        this.router.navigateByUrl('/products', {skipLocationChange: true}).then(() =>
        this.router.navigate(['/products/search', this.tipo, this.categ, 'total',variable, this.busqueda]));
      } else {
        this.router.navigateByUrl('/products', {skipLocationChange: true}).then(() =>
        this.router.navigate(['/products/search', this.tipo, 'total', variable, this.busqueda]));
      }
    }
  }

  getMasVendidos(){
    this.api.getProductsBestSellers().subscribe(
      result => {
        console.log(result);
        this.products_total = result;
      },
      error => {
        console.log(error)
      }
    )
  }

  getRecomendados() {
    this.api.getProductsRecomended().subscribe(
      result => {
        console.log(result)
        this.products_total = result;
      },
      error => {
        console.log(error)
      }
    );
  }

  getPublicitados(){
    this.api.getStoresAdvertised().subscribe(
      result => {
        console.log(result);
        this.products = result;
        if (result.length > 4){
          this.stores_min = result.slice(0,4);
        } else {
          this.stores_min = result;
        }
      },
      error => {
        console.log(error)
      }
    )
  }

  busquedaGlobal(categoria, texto){
    const body = {
          scope: 'uShops-platform',
          device: 'web',
          categoria: categoria,
          search: texto
    }
    if (body.search !== null && body.search.length > 1 && body.search !== undefined ) {
      this.api.searchProductsGlobal(body).subscribe(
        result => {
          this.products = result;
          if (this.visualizacion === 'secciones') {
            console.log('searchbycategory',this.products)
            this.products_min = this.products['productos'];
            if (this.products['tiendas']?.length > 3) {
              this.stores_min = this.products['tiendas'].slice(0,3);
              console.log('slice-T',this.stores_min)
            } else {
              this.stores_min = this.products['tiendas'];
            }
            if (this.products['relacionados']?.length > 5) {
              this.products_related_min = this.products['relacionados'].slice(0,5);
              console.log('slice-R',this.products_related_min)
            } else {
              this.products_related_min = this.products['relacionados'];
            }
          } else {
            switch(this.indicador) {
              case 'T':
                this.products_total = this.products['tiendas'];
                break;
              case 'R':
                this.products_total = this.products['relacionados'];
            }
          }          
          console.log(this.products);
        },
          error => {
              console.log(error)
            }
          )
    }
  }

  getImg1(id, imagenes){
    if (imagenes.length > 1) {
      const final = imagenes.filter(img => img?.URL.indexOf(id) > -1)
      if (final !== undefined) {
        return final[0].URL;
      }
    } else {
      return imagenes[0].URL;
    }
   }

  getImg(store, position){
    
    if(store !== undefined && store.producto !== undefined){
      
      // console.log('tienda', store)
      switch(store.producto.length) {
        case 1:
          return store.producto[0].images[0].URL
          break;
        case 2:
          if(position === 1) {
          return store.producto[0].images[0].URL
          } else {
            return store.producto[1].images[0].URL
          }
          break;
        case 3:
          if(position === 1) {
            return store.producto[0].images[0].URL
          } else if(position === 2){
            return store.producto[1].images[0].URL
          } else {
            return store.producto[2].images[0].URL
          }
          break;
        default:
          if(position === 1) {
            return store.producto[0].images[0].URL
          } else if(position === 2){
            return store.producto[1].images[0].URL
          } else {
            return store.producto[2].images[0].URL
          }
          break;
        }
    }

  }

  getMainImg(product) {

    console.log('producto',product)
    // for (var i = 0; i < product.images.length; i++) {
    // }
    
    // let found = product.images[0].find(element => element === product.ImagenPrincipal);
    // console.log('URL-final',found);

    // return found[0];
  }

  public getCategories(){
    const body = {
      scope: 'uShops-platform',
      device: 'web',
    };
    this.api.getCategorias(body).subscribe(
      result => {
        this.categories = result;
        console.log(this.categories);
      },
      error => {
        console.log(error)
      }
    )
  }

  public getBrands(){
    this.brands = this.appService.getBrands();
  }

  OnDestroy() {
    this.sub.unsubscribe();
  }

  @HostListener('window:resize')
  public onWindowResize():void {
    (window.innerWidth < 600) ? this.sidenavOpen = false : this.sidenavOpen = true;
    if (window.innerWidth >= 1200) {
      this.viewCol = 25;
      this.viewCol_related = 20;
      this.viewCol_stores = 33.3;
    } else if (window.innerWidth > 900 && window.innerWidth < 1200 ) {
      this.viewCol = 33.3;
      this.viewCol_related = 25;
      this.viewCol_stores = 50;
    } else if (window.innerWidth > 700 && window.innerWidth < 900) {
      this.viewCol = 50;
      this.viewCol_related = 33.3;
      this.viewCol_stores = 100;
    } else if (window.innerWidth > 600 && window.innerWidth < 700) {
      this.viewCol = 100;
      this.viewCol_related = 50;
      this.viewCol_stores = 100;
    } else if (window.innerWidth > 100 && window.innerWidth < 600) {
      this.viewCol = 50;
      this.viewCol_related = 50;
      this.viewCol_stores = 100;
    } else {
      this.viewCol = 100;
      this.viewCol_related = 100;
      this.viewCol_stores = 100;
    }
    // (window.innerWidth < 600) ? this.viewCol = 50 : this.viewCol = 33.3; 
  }

  public changeCount(count){
    this.count = count;
    this.getAllProducts();
  }

  public changeSorting(sort){
    this.sort = sort;
  }

  public changeViewType(viewType, viewCol){
    this.viewType = viewType;
    this.viewCol = viewCol;
    if(viewType === 'grid' && viewCol === 25){
      this.sidenavOpen = false;
    }else{
      this.sidenavOpen = true;
    }
  };

  public openProductDialog(product){
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog'
    });
    dialogRef.afterClosed().subscribe(product => {
      if(product){
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }

  public onPageChanged(event){
      this.page = event;
      // this.getAllProducts();
      window.scrollTo(0,0);
  }

  public onChangeCategory(event){
    if(event.target){
      this.router.navigate(['/products', event.target.innerText.toLowerCase()]);
    }
  }

}
