import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { Data, AppService } from '../../../app.service';
import { Product } from '../../../app.models';
import { ProductZoomComponent } from './product-zoom/product-zoom.component';
import { MatAccordion } from '@angular/material/expansion';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta } from '@angular/platform-browser';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import Overlay from 'ol/Overlay';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @ViewChild('zoomViewer') zoomViewer;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('map', { static: true }) mapElement: ElementRef;
  public product: Product;
  public image: any;
  public zoomImage: any;
  private sub: any;
  public form: FormGroup;
  public relatedProducts: Array<Product>;
  public relatedProductsStore: Array<Product>;

  public viewCol: number = 25; //25

  isProduction = environment.production;

  favoriteProduct: boolean = false;
  favoritesProducts: Array<any>;

  map: Map;
  marker: Overlay;

  config_swiper_3: SwiperConfigInterface = {
    observer: false,
    slidesPerView: 4,
    spaceBetween: 10,
    keyboard: true,
    navigation: true,
    pagination: false,
    loop: false,
    preloadImages: false,
    lazy: true,
    breakpoints: {
      480: {
        slidesPerView: 3
      },
      300: {
        slidesPerView: 3
      },
      600: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      }
    }
  };
  config_swiper_1: SwiperConfigInterface = {
    observer: true,
    slidesPerView: 1,
    spaceBetween: 0,
    keyboard: true,
    navigation: true,
    pagination: true,
    grabCursor: true,
    loop: false,
    preloadImages: false,
    direction: 'vertical',
    lazy: true,
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      740: {
        slidesPerView: 2,
      },
      960: {
        slidesPerView: 3,
      },
      1280: {
        slidesPerView: 1,
      },
      1500: {
        slidesPerView: 1,
      }
    }
  };
  config_swiper_2: SwiperConfigInterface = {
    observer: true,
    slidesPerView: 6,
    spaceBetween: 16,
    keyboard: true,
    navigation: true,
    pagination: false,
    grabCursor: true,
    loop: false,
    preloadImages: false,
    lazy: true,
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      740: {
        slidesPerView: 2,
      },
      960: {
        slidesPerView: 3,
      },
      1280: {
        slidesPerView: 4,
      },
      1500: {
        slidesPerView: 5,
      }
    }
  };

  openModal = false;
  title = 'My first AGM project';
  lat = -0.246202;
  lng = -78.521315;
  closeResult = '';
  cartProducts = '';
  cartProductsArray = [];

  metodosPago = [
    { text: 'Pago en efectivo', key: 'efectivo' },
    { text: 'Transferencia bancaria', key: 'transferenciaBancaria' },
    { text: 'Tarjeta de crédito', key: 'tarjetaCredito' },
    { text: 'Tarjeta de débito', key: 'tarjetaDebito' }
  ];
  metodosEnvio = [
    { text: 'Entrega en sitio', key: 'entregaSitio' },
    { text: 'Tramaco Express', key: 'envioTamaco' },
    { text: 'Servientrega', key: 'envioServientrega' }
  ];

  metodosEnvioToShow = [];
  metodosPagoToShow = [];

  cantidadProductos = 1;
  cantidadProductosToShow = '1 articulo';
  precioFinal = 0;

  store;
  prm;
  param_product;

  openModal2 = false;
  tipoModal = '';


  constructor(
    private modalService: NgbModal,
    public appService: AppService,
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public formBuilder: FormBuilder,
    public apiService: ApiService,
    public router: Router,
    private metaService: Meta) {  

      // this.metaService.addTag({ name: 'description', content: 'Article Description' });

      this.metaService.updateTag({ property: 'og:image:secure_url', content: 'https://seo-infoushops-edy.s3-us-west-2.amazonaws.com/egm-intro.jpg' });
      this.metaService.updateTag({ property: 'og:image', content: 'https://seo-infoushops-edy.s3-us-west-2.amazonaws.com/egm-intro.jpg' });
      this.metaService.updateTag({ property: 'og:image:url', content: 'https://seo-infoushops-edy.s3-us-west-2.amazonaws.com/egm-intro.jpg' });

        this.metaService.updateTag({ property: 'og:title', content: 'test' });
        // this.metaService.updateTag({ property: 'og:image', content: result.images[0].URL });
        this.metaService.updateTag({ property:"og:type", content:"website" });
        this.metaService.updateTag({ property:"og:image:type", content:"image/jpeg" });
        this.metaService.updateTag({ property:"og:site_name", content:"USHOPSSSS" });
        this.metaService.updateTag({ property:"og:locale:alternate", content:"es_ES" });
        this.metaService.updateTag({ property: 'og:description', content: 'test desc' });
        this.metaService.updateTag({ property:"og:url", content:"https://www.info.ushops.tech" });
  }

  ngOnInit() {

    console.log('this.isProduction: ', this.isProduction);

    if (this.isProduction === false) {
      this.initMap();
      setTimeout(() => {
        let rotationElement = document.getElementsByClassName('ol-rotate').item(0);
        let attributionElement = document.getElementsByClassName('ol-attribution').item(0);
        let zoomElement = document.getElementsByClassName('ol-zoom').item(0);
        // console.log('rotation element: ', rotationElement);
        // console.log('attribution element: ', attributionElement);
        if (rotationElement) rotationElement.setAttribute('style', 'display: none;');
        if (attributionElement) attributionElement.setAttribute('style', 'display: none;');
        if (zoomElement) zoomElement.setAttribute('style', 'width: fit-content;');
      }, 1000);
    } else {
      setTimeout(() => {
        let element = document.getElementsByClassName('osm-container').item(0);
        element.setAttribute('style', 'display: none;');
      }, 1000);
    }

    this.prm = this.activatedRoute.queryParamMap.subscribe(params => {
      console.log(params);
      this.param_product = { ...params };
      console.log(this.param_product);
      console.log('query-tienda', this.param_product.params.empresa);
      console.log('query-producto', this.param_product.params.codigo);

      const body = {
        scope: "uShops-platform",
        device: "web",
        // value: params.product
        value: this.param_product.params.codigo
      }
      this.apiService.getSingleProduct(body).subscribe(
        result => {
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          console.log('getSingleProduct', result);


          // this.metaService.updateTag({ property: 'og:title', content: result.nombreProducto });
          // // this.metaService.updateTag({ property: 'og:image', content: result.images[0].URL });
          // this.metaService.updateTag({ property:"og:type", content:"website" });
          // this.metaService.updateTag({ property:"og:image:type", content:"image/jpeg" });
          // this.metaService.updateTag({ property:"og:site_name", content:"USHOPSSSS" });
          // this.metaService.updateTag({ property:"og:locale:alternate", content:"es_ES" });
          // this.metaService.updateTag({ property: 'og:description', content: result.descripcionRapida });
          // this.metaService.updateTag({ property:"og:url", content:"https://www.info.ushops.tech" });



          this.product = result;
          this.image = this.product.images[0].URL;
          this.zoomImage = this.image;
          this.precioFinal = this.product.valorUnitario;
          this.config_swiper_3 = {
            observer: false,
            slidesPerView: 4,
            spaceBetween: 10,
            keyboard: true,
            navigation: true,
            pagination: false,
            loop: false,
            preloadImages: false,
            lazy: true,
            breakpoints: {
              480: {
                slidesPerView: 3
              },
              300: {
                slidesPerView: 3
              },
              600: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              }
            }
          };

          this.config_swiper_2 = {
            observer: true,
            slidesPerView: 6,
            spaceBetween: 16,
            keyboard: true,
            navigation: true,
            pagination: false,
            grabCursor: true,
            loop: true,
            preloadImages: false,
            lazy: true,
            breakpoints: {
              320: {
                slidesPerView: 2
              },
              600: {
                slidesPerView: 3,
              },
              800: {
                slidesPerView: 4,
              },
              1000: {
                slidesPerView: 5,
              }
            }
          };
        },
        error => {
          console.log(error);
        }
      );
      const body2 = {
        scope: "uShops-platform",
        device: "web",
        // identificador: params.store
        identificador: this.param_product.params.empresa
      }
      this.apiService.getSingleStore(body2).subscribe(
        result => {
          console.log('store data: ', result);
          this.store = result;
          this.metodosEnvioToShow = [];
          this.metodosPagoToShow = [];
          if (result['metodosEnvio'] !== undefined) {
            this.metodosEnvio.forEach(element => {
              if (result['metodosEnvio'].indexOf(element.key) !== -1) {
                this.metodosEnvioToShow.push(element.text);
              }
            });
          }
          if (result['metodosPago'] !== undefined) {
            this.metodosPago.forEach(element => {
              if (result['metodosPago'].indexOf(element.key) !== -1) {
                this.metodosPagoToShow.push(element.text);
              }
            });
          }
          console.log(this.metodosPagoToShow)
        },
        error => {
          console.log(error);
        }
      )

      const body3 = {
        scope: "uShops-platform",
        device: "web",
        identificadorEmpresa: this.param_product.params.empresa,
        value: ''
      }

      // this.apiService.getProductosRelacionados(body2).subscribe(
      //   result => {
      //     console.log(result);
      //     this.relatedProducts = result;
      //   },
      //   error => {
      //     console.log(error);
      //   }
      // )

      this.apiService.getProductsRandom().subscribe(
        result => {
          console.log(result);
          this.relatedProducts = result;
        },
        error => {
          console.log(error);
        }
      )
      // this.apiService.getProductosRelacionadosStore(body2).subscribe(
      // this.apiService.getProductosRelacionados(body2).subscribe(
      this.apiService.getProductsInStore(body3).subscribe(
        result => {
          console.log(result);
          if (result.length < 5) {
            this.relatedProductsStore = result;
          } else {
            this.relatedProductsStore = result.slice(0, 4);
          }
        },
        error => {
          console.log(error);
        }
      )

      // this.getFavoritesProducts();

    });
    if (window.innerWidth >= 900) {
      this.viewCol = 25;
    } else if (window.innerWidth >= 400 && window.innerWidth < 900) {
      this.viewCol = 50;
    } else if (window.innerWidth > 100 && window.innerWidth < 400) {
      this.viewCol = 50;
    } else {
      this.viewCol = 100;
    }



  }

  initMap() {
    const view = new View({
      center: fromLonLat([-78.48922, -0.2112]), // Coordenadas iniciales del centro del mapa
      zoom: 17 // Nivel de zoom inicial
    });

    this.map = new Map({
      target: this.mapElement.nativeElement,
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],
      view: view
    });

    setTimeout(() => {
      this.addMarker(-78.48922, -0.2112);
    }, 2000);
  }

  addMarker(longitude: number, latitude: number) {
    const markerElement = document.createElement('div');
    markerElement.className = 'marker';

    const marker = new Overlay({
      element: markerElement,
      position: fromLonLat([longitude, latitude]),
      positioning: 'center-center',
      stopEvent: false
    });

    this.map.addOverlay(marker);
  }

  favProduct(fav_prod) {
    var index = fav_prod.indexOf(fav_prod.find(item => item.identificadorProducto === this.product.identificadorProducto));
    if (index === -1) {
      document.getElementById('favorite_icon').style.fill = "#FFFFFF";
      return false
    } else {
      document.getElementById('favorite_icon').style.fill = "#FF0000";
      return true
    }
  }

  getFavoritesProducts() {
    const body = {
      scope: 'uShops-platform',
      device: 'web',
    }
    this.apiService.getFavoritesProducts(body).subscribe(
      result => {
        this.favoriteProduct = this.favProduct(result);
      },
      error => {
        console.log(error);
      }
    )
  }

  // ngOnChanges(): void{
  //   this.getFavoritesProducts();

  // }


  ngAfterViewInit() {


    this.config_swiper_1 = {
      height: 500,
      observer: false,
      slidesPerView: 1,
      spaceBetween: 2,
      keyboard: true,
      navigation: true,
      pagination: true,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        320: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 1,
        },
        1500: {
          slidesPerView: 1,
        }
      }
    };
    this.config_swiper_3 = {
      observer: false,
      slidesPerView: 4,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 3
        },
        300: {
          slidesPerView: 3
        },
        600: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        }
      }
    };
    this.config_swiper_2 = {
      observer: true,
      slidesPerView: 6,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        320: {
          slidesPerView: 2
        },
        600: {
          slidesPerView: 3,
        },
        800: {
          slidesPerView: 4,
        },
        1000: {
          slidesPerView: 5,
        }
      }
    };

  }

  getImg1(id, imagenes) {
    if (imagenes.length > 1) {
      const final = imagenes.filter(img => img?.URL.indexOf(id) > -1)
      if (final !== undefined) {
        return final[0].URL;
      }
    } else {
      return imagenes[0].URL;
    }
  }

  contactarTienda(numero, tienda) {
    if (numero.length === 10 && numero[0] === '0') {
      window.open('https://api.whatsapp.com/send?phone=' +
        numero.replace(numero[0], '593') + '&text=' + 'Hola ' + tienda + '\n' + ', acabo de ver su catálogo de productos en uShops y estoy interesado en realizar una compra');
    }
  }

  public getProductById(id) {
    // this.appService.getProductById(id).subscribe(data=>{
    //   this.product = data;
    //   this.image = data.images[0].medium;
    //   this.zoomImage = data.images[0].big;
    //   setTimeout(() => { 
    //    // this.directiveRef.setIndex(0);
    //   });
    // });
  }

  public getRelatedProducts() {
    // this.appService.getProducts('related').subscribe(data => {
    //   this.relatedProducts = data;
    // })
  }

  public selectImage(image) {
    this.image = image.URL;
    this.zoomImage = image.URL;
  }

  public onMouseMove(e) {
    if (window.innerWidth >= 1280) {
      var image, offsetX, offsetY, x, y, zoomer;
      image = e.currentTarget;
      offsetX = e.offsetX;
      offsetY = e.offsetY;
      x = offsetX / image.offsetWidth * 100;
      y = offsetY / image.offsetHeight * 100;
      zoomer = this.zoomViewer.nativeElement.children[0];
      if (zoomer) {
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
        zoomer.style.display = "block";
        zoomer.style.height = image.height + 'px';
        zoomer.style.width = image.width + 'px';
      }
    }
  }

  public onMouseLeave(event) {
    this.zoomViewer.nativeElement.children[0].style.display = "none";
  }

  public openZoomViewer() {
    this.dialog.open(ProductZoomComponent, {
      data: this.zoomImage,
      panelClass: 'zoom-dialog'
    });
  }

  addToCart() {
    const productoToCart = {
      nombre: this.product.nombreProducto,
      descripcionGeneral: this.product.descripcionGeneral,
      imagenPrincipal: this.getImg(this.product.imagenPrincipal, this.product.images),
      valorUnitario: this.product.valorUnitario,
      cantidad: this.cantidadProductos,
      identificadorProducto: this.product.identificadorProducto
    }
    this.cartProductsArray = [];
    if (this.cookieService.check('userCart') && this.cookieService.get('userCart') !== '') {
      this.cartProducts = this.cookieService.get('userCart');
      this.cartProducts.split('|').forEach(element => {
        this.cartProductsArray.push(JSON.parse(element));
      });
    }
    console.log(this.cartProductsArray)
    const index = this.cartProductsArray.findIndex(product => product.nombre === this.product.nombreProducto);
    console.log(index);
    if (index === -1) {
      if (this.cartProducts === '') {
        this.cartProducts = JSON.stringify(productoToCart)
      } else {
        this.cartProducts = this.cartProducts + '|' + JSON.stringify(productoToCart)
      }
      console.log(this.cartProducts)
      this.cookieService.set('userCart', this.cartProducts);
      let sb = this.snackBar.open('El producto se ha añadido a tu carrito', 'x', {
        duration: 3000,
        panelClass: 'notify-ok'
      });
      sb.onAction().subscribe(() => {
        sb.dismiss();
      });
    }
    else {
      let sb = this.snackBar.open('El producto ya está en tu carrito', 'x', {
        duration: 3000,
        panelClass: 'notify-warn'
      });
      sb.onAction().subscribe(() => {
        sb.dismiss();
      });
    }


  }

  getImg(id, imagenes) {
    if (imagenes.length > 1) {
      const final = imagenes.filter(img => img?.URL.indexOf(id) > -1)
      if (final !== undefined) {
        return final[0].URL;
      }
    } else {
      return imagenes[0].URL;
    }
  }

  selector_function() {
    $(".list-selector").toggleClass("active");
  }

  cantidad_articulos(cantidad: number) {
    this.cantidadProductos = cantidad;
    this.precioFinal = cantidad * this.product.valorUnitario;
    if (cantidad === 1) {
      this.cantidadProductosToShow = cantidad.toString() + ' articulo';
    } else {
      this.cantidadProductosToShow = cantidad.toString() + ' articulos';
    }
  }

  abrirMapa() {
    this.openModal = !this.openModal;
  }


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  compartir(tipo: String) {
    if (tipo === 'facebook') {
      console.log(encodeURIComponent(document.location.href));
      window.open(
        'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(document.location.href),
        'facebook-share-dialog',
        'width=626,height=436');
    }
    if (tipo === 'whatsapp') {
      if (screen.width < 600) {
        window.open(
          'https://wa.me/?text=' + encodeURIComponent(document.location.href));
      } else {
        window.open(
          'https://web.whatsapp.com/send?text=' + encodeURIComponent(document.location.href));
      }
    }
    if (tipo === 'twitter') {
      window.open(
        'https://twitter.com/intent/tweet?text=' + encodeURIComponent(document.location.href),
        'width=626,height=436');
    }
    if (tipo === 'instagram') {
      window.open(
        'https://www.instagram.com/?url=' + encodeURIComponent(document.location.href),
        'width=626,height=436');
    }

    return false;
  }


  public onSubmit(values: Object): void {
    if (this.form.valid) {
      //email sent
    }
  }

  restaurarModal(event: any) {
    console.log(event);

    if (event === false) {
      this.openModal2 = false;
      this.tipoModal = '';
    }
    if (event === 'Done') {
      const productoToCart = {
        nombre: this.product.nombreProducto,
        descripcionGeneral: this.product.descripcionGeneral,
        imagenPrincipal: this.getImg(this.product.imagenPrincipal, this.product.images),
        valorUnitario: this.product.valorUnitario,
        cantidad: this.cantidadProductos,
        identificadorProducto: this.product.identificadorProducto
      }
      this.cartProducts = JSON.stringify(productoToCart)
      this.cookieService.set('userCartSingle', this.cartProducts);
      this.router.navigate(['/checkout']);
      console.log(this.cartProducts)
    }
  }

  comprarAhora() {

    if (this.appService.usuario.autenticado === true) {
      const productoToCart = {
        nombre: this.product.nombreProducto,
        descripcionGeneral: this.product.descripcionGeneral,
        imagenPrincipal: this.getImg(this.product.imagenPrincipal, this.product.images),
        valorUnitario: this.product.valorUnitario,
        cantidad: this.cantidadProductos,
        identificadorProducto: this.product.identificadorProducto
      }
      this.cartProducts = JSON.stringify(productoToCart)
      this.cookieService.set('userCartSingle', this.cartProducts);
      this.router.navigate(['/checkout']);
      console.log(this.cartProducts)
      this.router.navigate(['/checkout']);
    } else {
      this.tipoModal = 'sign-in';
      this.openModal2 = true;
    }
  }



  @HostListener('window:resize')
  public onWindowResize(): void {
    // (window.innerWidth < 600) ? this.sidenavOpen = false : this.sidenavOpen = true;
    if (window.innerWidth >= 900) {
      this.viewCol = 25;
    } else if (window.innerWidth >= 400 && window.innerWidth < 900) {
      this.viewCol = 50;
    } else if (window.innerWidth > 100 && window.innerWidth < 400) {
      this.viewCol = 50;
    } else {
      this.viewCol = 100;
    }
  }


  addFavoriteProduct() {
    if (this.favoriteProduct === true) {
      const body = {
        scope: 'uShops-platform',
        reaccion: 'no me gusta',
        producto: this.product.identificadorProducto
      };
      this.apiService.deleteFavoriteProduct(body).subscribe(
        result => {
          console.log('DEL-FAV', result);
          this.snackBar.open(`${this.product.nombreProducto} se quitó de Favoritos`, '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
          this.favoriteProduct = false;
          // this.router.navigate(['/producto/v1'],{queryParams: {empresa: this.product.identificadorEmpresa, codigo: this.product.identificadorProducto}})
          document.getElementById('favorite_icon').style.fill = "#FFFFFF";
        },
        error => {
          console.log(error);
        }
      )

    } else {
      const body = {
        scope: 'uShops-platform',
        reaccion: 'me gusta',
        producto: this.product.identificadorProducto
      };
      this.apiService.addFavoriteProduct(body).subscribe(
        result => {
          console.log('ADD-FAV', result);
          this.favoriteProduct = true;
          this.snackBar.open(`${this.product.nombreProducto} agregado a Favoritos`, '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          // this.router.navigate(['/producto/v1'],{queryParams: {empresa: this.product.identificadorEmpresa, codigo: this.product.identificadorProducto}})
          document.getElementById('favorite_icon').style.fill = "#FF0000";
        },
        error => {
          console.log(error);
        }
      )
    }


  }

}