import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ClientsService } from 'src/app/services/clients.service';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss']
})
export class ClientInfoComponent implements OnInit {

  clientId: any = '';
  client: any;
  formClient: FormGroup;
  formNote: FormGroup;

  companyData: any;

  notes: any = [
    // { id: 0, titulo: 'Titulo de la nota 1', descripcion: 'aaaa bbbbbbbb ccccc ssssssss fffffffff gggggggggg', fecha: new Date() },
    // { id: 1, titulo: 'Titulo de la nota 2', descripcion: 'aaaa bbbbbbbb ccccc ssssssss fffffffff ggggggggggaaaa bbbbbbbb ccccc ssssssss fffffffff ggggggggggaaaa bbbbbbbb ccccc ssssssss fffffffff gggggggggg', fecha: new Date() },
    // { id: 2, titulo: 'Titulo de la nota 3', descripcion: 'a', fecha: new Date() },
    // { id: 3, titulo: 'Titulo de la nota 4', descripcion: 's', fecha: new Date() },
    // { id: 4, titulo: 'Titulo de la nota 5', descripcion: 'aaaa bbbbbbbb ccccc ssssssss fffffffff ggggggggggaaaa bbbbbbbb ccccc ssssssss fffffffff ggggggggggaaaa bbbbbbbb ccccc ssssssss fffffffff ggggggggggvaaaa bbbbbbbb ccccc ssssssss fffffffff ggggggggggaaaa bbbbbbbb ccccc ssssssss fffffffff ggggggggggaaaa bbbbbbbb ccccc ssssssss fffffffff ggggggggggaaaa bbbbbbbb ccccc ssssssss fffffffff ggggggggggaaaa bbbbbbbb ccccc ssssssss fffffffff ggggggggggaaaa bbbbbbbb ccccc ssssssss fffffffff gggggggggg', fecha: new Date() },
    // { id: 5, titulo: 'Titulo de la nota 6', descripcion: 'aaaa bbbbbbbb ccccc ssssssss fffffffff gggggggggg', fecha: new Date() },
    // { id: 6, titulo: 'Titulo de la nota 7', descripcion: 'aaaa bbbbbbbb ccccc ssssssss fffffffff gggggggggg', fecha: new Date() },
    // { id: 7, titulo: 'Titulo de la nota 8', descripcion: 'aaaa bbbbbbbb ccccc ssssssss fffffffff gggggggggg', fecha: new Date() },
    // { id: 8, titulo: 'Titulo de la nota 9', descripcion: 'aaaa bbbbbbbb ccccc ssssssss fffffffff gggggggggg', fecha: new Date() },
  ];

  constructor(private activatedeRoute: ActivatedRoute,
    private router: Router,
    private apiService: ClientsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder) {
    this.formClient = this.formBuilder.group({
      nombre: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      direccion: ['', [Validators.required]],
      ciudad: ['', [Validators.required]],
      empresa: ['', [Validators.required]],
      cargo: ['', [Validators.required]]
    });

    this.formNote = this.formBuilder.group({
      titulo: ['', [Validators.required]],
      descripcion: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.activatedeRoute.queryParams.subscribe(params => {
      this.clientId = params['id'];
      this.getCompanyData().then(
        result => {
          this.getClient();
        }, error => {
          console.log('error: ', error);
        }
      )

      // console.log('Valor id:', this.clientId);
    });
  }

  openNoteModal() {
    document.querySelector('#note-modal').classList.add('md-show');
  }

  editNote(note: any) {
    note.isEditable = true;
  }

  cancelEditedNote(note: any) {

    let body: any = {
      _id: this.clientId
    };

    if (this.companyData.integracionIMS && this.companyData.integracionIMS === 'activo') {
      this.apiService.obtenerUnCliente(body).subscribe({
        next: (result: any) => {
          console.log('cliente: ', result);
          if (result.notas && result.notas.length > 0) {
            let index = this.notes.findIndex(obj => obj.id === note.id);
            if (index !== -1) {
              this.notes[index] = result.notas.find(n => n.id === note.id);
            } else {
              this.notes = result.notes;
            }

            delete note.isEditable;
          }
        },
        error: (error) => {
          console.log('Error al cancelar la edición: ', error);
          this.toastr.error('Ha ocurrido un error al cancelar la edición.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
        },
        // complete: () => console.info('complete')
      })
    } else {
      this.apiService.obtenerUnClienteSentinel(body).subscribe({
        next: (result: any) => {
          console.log('cliente: ', result);
          if (result.notas && result.notas.length > 0) {
            let index = this.notes.findIndex(obj => obj.id === note.id);
            this.notes[index] = result.notas.find(n => n.id === note.id);
            delete note.isEditable;
          }
        },
        error: (error) => {
          console.log('Error al cancelar la edición: ', error);
          this.toastr.error('Ha ocurrido un error al cancelar la edición.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
        },
        // complete: () => console.info('complete')
      })
    }
  }

  saveEditedNote(note: any) {
    this.getClient().then(
      res => {

        delete note.isEditable;
        note.fecha = new Date();
        let index = this.notes.findIndex(obj => obj.id === note.id);
        if (index !== -1) {
          this.notes[index] = note;
        }

        let body: any = {
          _id: this.client._id,
          notas: this.notes
        };

        if (this.companyData.integracionIMS && this.companyData.integracionIMS === 'activo') {
          this.apiService.actualizarCliente(body).subscribe({
            next: (result: any) => {
              // console.log('result', result);
              this.toastr.success('Se ha actualizado la nota del cliente.', 'Operación exitosa', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
            },
            error: (error) => {
              console.log('Error al actualizar nota: ', error);
              this.toastr.error('Ha ocurrido un error y no se ha logrado actualizar la nota del cliente.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
            },
            // complete: () => console.info('complete')
          });
        } else {
          this.apiService.actualizarClienteSentinel(body).subscribe({
            next: (result: any) => {
              // console.log('result', result);
              this.toastr.success('Se ha actualizado la nota del cliente.', 'Operación exitosa', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
            },
            error: (error) => {
              console.log('Error al actualizar nota: ', error);
              this.toastr.error('Ha ocurrido un error y no se ha logrado actualizar la nota del cliente.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
            },
            // complete: () => console.info('complete')
          });
        }
      }
    )
  }

  deleteNote(note: any) {

    this.getClient().then(
      res => {

        note.deleteStatus = 1;
        let index = this.notes.findIndex(obj => obj.id === note.id);
        if (index !== -1) {
          this.notes[index] = note;
        }

        let body: any = {
          _id: this.client._id,
          notas: this.notes
        };

        if (this.companyData.integracionIMS && this.companyData.integracionIMS === 'activo') {
          this.apiService.actualizarCliente(body).subscribe({
            next: (result: any) => {
              // console.log('result', result);
              this.notes = this.notes.filter(n => n.id !== note.id);
              this.toastr.success('Se ha eliminado la nota del cliente.', 'Operación exitosa', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });

            },
            error: (error) => {
              console.log('Error al eliminar nota: ', error);
              this.toastr.error('Ha ocurrido un error y no se ha logrado eliminar la nota del cliente.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
            },
            // complete: () => console.info('complete')
          });
        } else {
          this.apiService.actualizarClienteSentinel(body).subscribe({
            next: (result: any) => {
              // console.log('result', result);
              this.notes = this.notes.filter(n => n.id !== note.id);
              this.toastr.success('Se ha eliminado la nota del cliente.', 'Operación exitosa', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
            },
            error: (error) => {
              console.log('Error al eliminar nota: ', error);
              this.toastr.error('Ha ocurrido un error y no se ha logrado eliminar la nota del cliente.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
            },
            // complete: () => console.info('complete')
          });
        }
      }
    )
  }

  getClient() {

    let body: any = {
      _id: this.clientId
    };

    // console.log('body: ', body);

    return new Promise((resolve, reject) => {
      if (this.companyData.integracionIMS && this.companyData.integracionIMS === 'activo') {
        this.apiService.obtenerUnCliente(body).subscribe({
          next: (result: any) => {
            // console.log('cliente: ', result);
            this.client = result;

            if (result.notas && result.notas.length > 0) {
              this.notes = result.notas.filter(n => !n.deleteStatus || n.deleteStatus === 0);
            } else {
              this.notes = [];
            }

            // this.notes.push({
            //   "id": "N-OYZNENZADEXN7TPGQ37QGMM9NWO285FU0BFFKI8JHFL59MA4BX2AO6BKC98R4J2",
            //   "titulo": "test 1 editado",
            //   "descripcion": "aaa aaaaa aaaaaaa aaaa aaaaa aaa aaa",
            //   "fecha": "2024-06-13T17:18:56.219Z",
            //   urlImagen: "https://s3.us-west-2.amazonaws.com/img.ushops/whatsapp-chatbot-imagenes/1701140768621_7d850946-2a4d-437c-9b91-0dd6af82f4b0.jpg",
            //   transicion: {
            //     confirmacion: 'aprobar',
            //     denegacion: 'denegar',
            //     nuevoEstadoConfirmacion: 'leads',
            //     nuevoEstadoDenegacion: 'test'
            //   }
            // });

            return resolve(result);
          },
          error: (error) => {
            console.log('Error al cargar cliente: ', error);
            this.toastr.error('Ha ocurrido un error al cargar el cliente.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            return reject(error);
            // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          },
          // complete: () => console.info('complete')
        })
      } else {
        this.apiService.obtenerUnClienteSentinel(body).subscribe({
          next: (result: any) => {
            console.log('cliente: ', result);
            this.client = result;

            if (result.notas && result.notas.length > 0) {
              this.notes = result.notas.filter(n => !n.deleteStatus || n.deleteStatus === 0);
            } else {
              this.notes = [];
            }

            // this.notes.push({
            //   "id": "N-OYZNENZADEXN7TPGQ37QGMM9NWO285FU0BFFKI8JHFL59MA4BX2AO6BKC98R4J2",
            //   "titulo": "test 1 editado",
            //   "descripcion": "aaa aaaaa aaaaaaa aaaa aaaaa aaa aaa",
            //   "fecha": "2024-06-13T17:18:56.219Z",
            //   "urlImagen": "https://s3.us-west-2.amazonaws.com/img.ushops/whatsapp-chatbot-imagenes/1701140768621_7d850946-2a4d-437c-9b91-0dd6af82f4b0.jpg",
            //   "transicion": {
            //     "confirmacion": 'aprobar',
            //     "denegacion": 'denegar',
            //     "nuevoEstadoConfirmacion": 'leads',
            //     "nuevoEstadoDenegacion": 'test'
            //   }
            // });

            return resolve(result);
          },
          error: (error) => {
            console.log('Error al cargar cliente: ', error);
            this.toastr.error('Ha ocurrido un error al cargar el cliente.', 'Error', {
              timeOut: 3000,
              positionClass: 'toast-bottom-right',
            });
            return reject(error);
            // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
          },
          // complete: () => console.info('complete')
        })
      }
    });
  }

  changeProcessKanban(type: string, note: any) {
    console.log('form: ', this.formClient.value);

    if (!this.client) return;

    let body: any = {
      _id: this.client._id,
      proceso: type === 'confirmacion' ? note.transicion.nuevoEstadoConfirmacion : note.transicion.nuevoEstadoDenegacion,
      sendStatusUser : true
    };

    if (this.companyData.integracionIMS && this.companyData.integracionIMS === 'activo') {
      this.apiService.actualizarCliente(body).subscribe({
        next: (result: any) => {
          // console.log('result', result);
          this.toastr.success('Se ha actualizado el proceso del cliente.', 'Operación exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          this.router.navigate(['/backoffice/kanban-ventas']);
        },
        error: (error) => {
          console.log('Error al actualizar cliente: ', error);
          this.toastr.error('Ha ocurrido un error y no se ha logrado actualizar el proceso del cliente.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      });
    } else {
      this.apiService.actualizarClienteSentinel(body).subscribe({
        next: (result: any) => {
          // console.log('result', result);

          
          this.toastr.success('Se ha actualizado el proceso del cliente.', 'Operación exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          this.router.navigate(['/backoffice/kanban-ventas']);
        },
        error: (error) => {
          console.log('Error al actualizar cliente: ', error);
          this.toastr.error('Ha ocurrido un error y no se ha logrado actualizar el proceso del cliente.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      });
    }
  }

  updateClient() {
    console.log('form: ', this.formClient.value);

    if (!this.client) return;

    let body: any = {
      _id: this.client._id,
      nombre: this.formClient.value.nombre,
      telefono: this.formClient.value.telefono,
      email: this.formClient.value.email,
      direccion: this.formClient.value.direccion,
      ciudad: this.formClient.value.ciudad,
      empresa: this.formClient.value.empresa,
      cargo: this.formClient.value.cargo
    };

    if (this.companyData.integracionIMS && this.companyData.integracionIMS === 'activo') {
      this.apiService.actualizarCliente(body).subscribe({
        next: (result: any) => {
          // console.log('result', result);
          this.closeModal('client-modal');
          this.getClient();
          this.toastr.success('Se ha actualizado la información del cliente.', 'Operación exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
        },
        error: (error) => {
          console.log('Error al actualizar cliente: ', error);
          this.toastr.error('Ha ocurrido un error y no se ha logrado guardar la información del cliente.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      });
    } else {
      this.apiService.actualizarClienteSentinel(body).subscribe({
        next: (result: any) => {
          // console.log('result', result);
          this.closeModal('client-modal');
          this.getClient();
          this.toastr.success('Se ha actualizado la información del cliente.', 'Operación exitosa', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
        },
        error: (error) => {
          console.log('Error al actualizar cliente: ', error);
          this.toastr.error('Ha ocurrido un error y no se ha logrado guardar la información del cliente.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      });
    }
  }

  createNote() {
    console.log('note: ', this.formNote.value);

    this.getClient().then(
      res => {

        let newId: string;
        do {
          newId = this.generateRandomIdNote();
        } while (!this.isIdUnique(newId, this.notes));

        this.notes.push({
          id: newId,
          titulo: this.formNote.value.titulo,
          descripcion: this.formNote.value.descripcion,
          fecha: new Date()
        });

        let body: any = {
          _id: this.client._id,
          notas: this.notes
        };

        if (this.companyData.integracionIMS && this.companyData.integracionIMS === 'activo') {
          this.apiService.actualizarCliente(body).subscribe({
            next: (result: any) => {
              // console.log('result', result);
              this.closeModal('note-modal');
              this.toastr.success('Se ha creado la nota del cliente.', 'Operación exitosa', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
            },
            error: (error) => {
              console.log('Error al actualizar cliente: ', error);
              this.toastr.error('Ha ocurrido un error y no se ha logrado crear la nota del cliente.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
            },
            // complete: () => console.info('complete')
          });
        } else {
          this.apiService.actualizarClienteSentinel(body).subscribe({
            next: (result: any) => {
              // console.log('result', result);
              this.closeModal('note-modal');
              this.toastr.success('Se ha creado la nota del cliente.', 'Operación exitosa', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
            },
            error: (error) => {
              console.log('Error al actualizar cliente: ', error);
              this.toastr.error('Ha ocurrido un error y no se ha logrado crear la nota del cliente.', 'Error', {
                timeOut: 3000,
                positionClass: 'toast-bottom-right',
              });
              // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
            },
            // complete: () => console.info('complete')
          });
        }
      }
    )
  }

  getCompanyData() {
    let body: any = {};

    return new Promise((resolve, reject) => {
      this.apiService.obtenerDatosBot(body).subscribe({
        next: (result: any) => {
          this.companyData = result;
          console.log('this.companyData', this.companyData);
          return resolve(result);
        },
        error: (error) => {
          console.log('Error al actualizar cliente: ', error);
          this.toastr.error('Ha ocurrido un error al obtener informacion de sentinel.', 'Error', {
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
          });
          return reject(error);
          // this.snackBar.open('Ha ocurrido un error al cargar el código QR', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
        },
        // complete: () => console.info('complete')
      });
    });
  }

  redirectChat() {
    const queryParams = { num: this.client.telefono };
    const query = new URLSearchParams(queryParams).toString();

    window.open(`/backoffice/whatsapp-user-chat?${query}`, '_blank');
  }

  openClientModal() {
    document.querySelector('#client-modal').classList.add('md-show');
  }

  closeModal(name: string) {
    document.querySelector('#' + name).classList.remove('md-show');
  }

  generateRandomIdNote(length: number = 62): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return 'N-' + result;
  }

  isIdUnique(id: string, objectsArray: any[]): boolean {
    return !objectsArray.some(obj => obj.id === id);
  }

}
