import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @Output() closeSidebar = new EventEmitter<void>();
  @Input() isSidebarOpen = false;
  urlActual: string;

  sidebarItems = [
    // { name: 'Dashboard', url: 'dashboard' },
    { name: 'Configuración de chatbot', url: 'chatbot-configuration' },
    // { name: 'Mensajeria masiva', url: 'mass-messaging' },
    { name: 'Mensajes con usuarios', url: 'admin-users' },
    { name: 'Campañas de whatsapp', url: 'whatsapp-campaign' },
    { name: 'Kanban de ventas', url: 'kanban-ventas' },
    // { name: 'Calendario de eventos', url: 'events-calendar' },
    { name: 'Información de clientes', url: 'admin-clients' },
  ]

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.urlActual = this.router.url;
  }

  ngAfterViewInit(): void {
    this.setActiveSidebarItem();
  }

  redirect(url: string) {
    this.router.navigate(['backoffice/' + url]);
    this.onCloseSidebar();
    this.setActiveSidebarItem(url);
  }

  onCloseSidebar(): void {
    this.isSidebarOpen = false;
    this.closeSidebar.emit();
  }

  setActiveSidebarItem(id: any = undefined) {
    let items = document.getElementsByClassName('sidebar-item');

    if (items) {
      for (let i = 0; i < items.length; i++) {
        items[i].classList.remove('sidebar-selected');
      }
    }

    if (id !== undefined) {
      document.getElementById(id).classList.add('sidebar-selected');
    } else {
      if (this.urlActual.includes('chatbot-configuration')) {
        document.getElementById('chatbot-configuration').classList.add('sidebar-selected');
      }

      if (this.urlActual.includes('mass-messaging')) {
        document.getElementById('mass-messaging').classList.add('sidebar-selected');
      }

      if (this.urlActual.includes('admin-users')) {
        document.getElementById('admin-users').classList.add('sidebar-selected');
      }
    }
  }
}
