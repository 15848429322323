export class Category {
  constructor(public id: number,
              public name: string,
              public categoriaGeneral: string,
              public hasSubCategory: boolean,
              public subcategorias: Array<any>,
              public parentId: number) { }
}

export class Product {
  constructor(
              public _id: string,
              public identificadorProducto: string,
              public fechaCreacion: string,
              public tiempoPublicacion: number,
              public identificadorEmpresa: string,
              public codigoEmpresarial: string,
              public oldPrice: number,
              public discount: number,
              public iva: string,
              public nombreProducto: string,
              public cantidadDisponible: number,
              public categoria: string,
              public grupo: string,
              public valorUnitario: number,
              public descripcionRapida: string,
              public descripcionGeneral: string,
              public informacionAdicional: string,
              public imagenPrincipal: string,
              public images: Array<any>,
              public metodosEnvio: Array<any>,
              public metodosPago: Array<any>,
              public estadoPublicacion: string,
              public estadoProducto: string,
              public ratingsCount: number,
              public ratingsValueFinal: number,
              public ratingsValue: number,

              public id: number,
              public soldQuantity: number,
              public total: number,
              public color: Array<string>,
              public size: Array<string>,
              public weight: number,
              public categoryId: number,
              public nombreEmpresa: string,

              public newPrice: string,
              public description: string,
              public availibilityCount: number,
              public name: string,

              public empresa: Array<any>



  ) { }
}

export class Stores {
  constructor(
    public imagenURL: Array<any> =[{URL}],
    public nombreEmpresa: String,
    public mision: String,
    public areaPrincipal: String,
    public area: String,
    public sector: String,
    public identificadorEmpresa: String,
    public identificador: String,
    public ciudad: String,
    public producto: Array <any>
  ) { }


}

export class Ventas {
  constructor(
    public numeroOrden: String,
    public Fecha: Date,
    public estado: String,
    public numeroProductos: number,
    public Total: number,

  ) { }
}
