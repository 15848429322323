import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent {

  @Input() categories;
  @Input() categoryParentId;
  @Input() origen;
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() category: EventEmitter<any> = new EventEmitter();
  mainCategories;
  address;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  public ngDoCheck() {
  }

  public stopClickPropagate(event: any){
    if(window.innerWidth < 960){
      event.stopPropagation();
      event.preventDefault();
    }    
  }

  public changeCategory(event, category){
    this.address = document.location.href;
    if (this.origen) {
      if (this.origen === 'products') {
        if (this.address.split('/').find(element => element === 'bycategory')){
          // this.router.navigateByUrl('/products', {skipLocationChange: true}).then(() =>
          // this.router.navigate(['/products/search/bycategory/' + category ]));
          this.router.navigate(['/products/search/bycategory/' + category]);
        } else {
          this.router.navigate(['/products/search/bycategory/' + category]);
        }
      } else {
        if(category === 'all') {
          console.log('category',this.category.emit('Todos'));   
        } else {
          console.log('category',this.category.emit(category));   
        }   
      }
    }
  }

    
}