// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlApi: 'https://pruebas2.egm-robotics.com',
  socketsUrl: 'http://pruebas2.egm-robotics.com:45511',
  URL_PAYMENT : 'https://pruebas2.egm-robotics.com//ushops/salesxpertPagos/payment/pagosPayphoneConfirm',
  URL_PAYMENT_CANCELLATION : 'http://localhost:4200',
  tokenPayphone : 'zFwjxP5DqEaHAffYLQEja--jbrg2J-ceVc0otLz6_mBidRASElrRx8TvrzYvOkka1dO8CxZlfKsOTzlXAzIXiRllaFQRK_pqT-oEEzZwpXgrm15Z6BG-geV_NFVoKvZw4HUI33wOrt6Fv1nmvKAN7We2BQl6SujQup6apzqVsXrPJH2XrExyoOMwjdrCXae34PVbWk_-8edYEoSRi296hGT0NzHt_jkvyaKBs05BMy_9eAC5afdgmfuqbCQXaFeympsh7bn98az0MGn9tPBNaXIQIqZSx7vKyXhcrjS6ZXC01jYmNisq5kquTSvCWIf7Hfuguw'

  

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
