import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { Data, AppService } from '../../app.service';
import { Stores } from '../../app.models';
import { Product } from '../../app.models';
import {ApiService} from '../../services/api.service';


@Component({
  selector: 'app-products-carousel',
  templateUrl: './products-carousel.component.html',
  styleUrls: ['./products-carousel.component.scss']
})
export class ProductsCarouselComponent implements OnInit {

  // @Input('products') products: Array<Product> = [];
  @Input('products') products: Array<any> = [];
  @Input('info') info: Array<any> = [];
  @Input('config') config: SwiperConfigInterface;

  imgMain = [];
  informacion = [];

  constructor(public appService:AppService, public dialog: MatDialog, private router: Router, private api: ApiService) { }

  ngOnInit() {
  }

   ngAfterViewInit(){
  }

   getImg(id, imagenes){
    if (imagenes.length > 1) {
      const final = imagenes.filter(img => img?.URL.indexOf(id) > -1)
      if (final !== undefined) {
        return final[0].URL;
      }
    } else {
      return imagenes[0].URL;
    }
   }

  public openProductDialog(product){   
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog'
    });
    dialogRef.afterClosed().subscribe(product => {
      if(product){
        this.router.navigate(['/products', product.id, product.name]); 
      }
    });
  }

}