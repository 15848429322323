import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ScheduleService } from 'src/app/services/schedule.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-schedule-appointment',
  templateUrl: './schedule-appointment.component.html',
  styleUrls: ['./schedule-appointment.component.scss']
})
export class ScheduleAppointmentComponent implements OnInit {

  element1: HTMLElement;

  selectedIndex: number | null = null;

  agendado = false;
  estadoUsuario = null;

  selectedHour: string; // Variable para almacenar la hora seleccionada
  selectedHourId: string;

  descripcionNegocio = 
`Set de fotos de navidad 

🕣 20 minutos 
                        
Luz Natural hace fotos para que la gente recuerde quién es ❤️`
confirmacionAgendamiento = 
`Cita agendada correctamente, muchas gracias ❤️

Recuerda que: 
- Debes llegar puntual, el tiempo empieza a correr desde la hora acordada. 
Llega 5-10 minutos antes.

- Si faltas a tu sesión no hacemos devolución del dinero.

-Ten en cuenta  que en esta época el tráfico es más pesado, sal con tiempo extra de casa.

-  Tus fotos llegarán a tu correo 1 semana después de tu sesión. (No entregamos fotos antes de este tiempo) 

Recuerda que son máximo 6 asistentes, incluidos niños y mascotas, de lo contrario debes tomar 2 sesiones. 

Recuerda llevar el 50% restante en efectivo de ser posible 🙏

Te esperamos con toda la actitud para celebrar esta Navidad! ✨🎄
Calle 20sur no 25b - 16
Barrio San Lucas

(Sobre la Superior por una bajada a mano izquierda antes de Wajaca).
`
  resumenCitaDefault= 
`<strong>Día seleccionado:</strong> {{dia}}
<strong>Hora seleccionada:</strong> {{hora}}
`
  resumenCita = ''
  configurationCalendar :any = null;
  actualDate: Date = new Date();
  actualDateString: string = new Date().toLocaleDateString('es-ES');
  actualMonth: string;
  monthDays: any[];
  dayNames: string[] = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];

  fechaSeleccionada: any;
  selectedDate: any;
  alertsTitle: string = 'Eventos de hoy';


  eventsLocale: any = [];
  eventsRemote: any = [];

  listHours: any = [];

  emailFormData: any;
  nombreFormData: any;
  telefonoFormData: any;
  apellidoFormData: any;
  nPersonasFormData: any;
  pagoFormData: any;
  adicionalFormData: any;
  

  opcionesPago: string[] = [
    'Transferencia 50%',
    'Transferencia 100%',
    'Bono Regalo',
    'Other'
  ];

  opcionesPerrito: string[] = ['Si', 'No'];

   numeroCitas: any;

  links: Array<Object> = [
    {
      title: 'Quienes somos',
      subtitles: [
        {
          subtitle: 'Nosotros',
          link: 'about-us'
        }, {
          subtitle: 'Compromiso con la ciudadania',
          link: 'home'
        }, {
          subtitle: 'Galería',
          link: 'home'
        }, {
          subtitle: 'Contactos',
          link: 'contact'
        }
      ]
    }, {
      title: 'Cómo funciona',
      subtitles: [
        {
          subtitle: 'Guía para principiantes',
          link: 'home'
        }, {
          subtitle: 'Servicios',
          link: 'home'
        }, {
          subtitle: 'Marketing para tu negocio',
          link: 'home'
        }, {
          subtitle: 'Trabaja con nosotros',
          link: 'home'
        }
      ]
    }, {
      title: 'Precios',
      subtitles: [
        {
          subtitle: 'Planes',
          link: 'home'
        }, {
          subtitle: 'Otros costos',
          link: 'home'
        }
      ]
    }, {
      title: 'Ayuda',
      subtitles: [
        {
          subtitle: 'Servicio al cliente',
          link: 'home'
        }, {
          subtitle: 'Preguntas Frecuentes',
          link: 'home'
        }
      ]
    }
  ];

  adi: string | null = null;
  adu: string | null = null;
  ndc: number | null = null;

  constructor(private route: ActivatedRoute, 
    private apiService: ScheduleService, 
    private toastr: ToastrService,
    private router: Router) { 
    // this.route.queryParams.subscribe(params => {
    //   this.adi = params['adi'] || null;
    //   this.adu = params['adu'] || null;
    // });
  }

  @HostListener('document:scroll', ['$event']) scrollfunction(event) {
    if (event && event.path) {
      let aux3 = event.path[1].scrollY;

      var aux = 1.5 * aux3;
      //console.log(window.innerHeight, window.innerWidth);

      if (aux <= (window.innerHeight / 2)) {
        aux = window.innerHeight / 2;
      }

      if (aux >= (0.9 * window.innerHeight)) {
        aux = 0.9 * window.innerHeight;
      }

      //console.log(aux.toString() + 'px');
      this.element1.style.top = aux.toString() + 'px';
      var aux2 = ((aux - window.innerHeight / 2) / (0.9 * window.innerHeight - window.innerHeight / 2));
      //console.log(aux2);
      this.element1.style.opacity = aux2.toString();
    }
  }
  getQueryParams(parameters) {
    const params = this.route.snapshot.queryParams;
    this.adi = params['adi'] || null;
    this.adu = params['adu'] || null;
    this.ndc = Number(params['ndc']) || null;

    if(!this.adi || (this.ndc != 1 && this.ndc != 2)){
      this.router.navigate(['home']);
      return 
    }

    console.log(this.adi, this.adu, this.ndc)
  }

  ngOnInit(): void {
    this.resumenCita = this.resumenCitaDefault

    this.getQueryParams(this.route.queryParams);





    console.log('parametros query -> adi = ' + this.adi + '; adu = ' + this.adu + '; adu = ' + this.ndc);

    if(this.adu){
      this.telefonoFormData = this.adu
      this.numeroCitas = this.ndc
    }

    this.actualizarCalendario();


    this.getStatusUser();
 


    this.getAvailableDays();

    this.selectedDate = this.actualDate.toLocaleDateString('es-ES');
    this.eventsLocale = [];
    for (let i = 0; i < this.eventsRemote.length; i++) {
      if (new Date(this.eventsRemote[i].fecha).toLocaleDateString('es-ES') === this.selectedDate) {
        this.eventsLocale.push(this.eventsRemote[i]);
      }
    }
  }

  getStatusUser() {

    let body: any = {
      adi: this.adi,
      adu: this.adu,
      ndc: this.ndc
    }

    console.log(body)
    this.apiService.obtenerEstadoCita(body).subscribe({
      next: (result: any) => {
        console.log('estado: ', result);

        if(result && result.length){
          this.estadoUsuario = result
        } else {
          this.estadoUsuario = null
        }


      },
      error: (error) => {
        console.log('Error al obtener el estado');

      },
      // complete: () => console.info('complete')
    })

    // for (let i = 8; i < 17; i++) {
    //   this.listHours.push({
    //     id: i,
    //     value: i + ':00'
    //   })
    // }
  }




  openEmailModal() {

    if(!this.fechaSeleccionada || !this.selectedHour){
      this.toastr.error('Faltan parámetros de la cita.', 'Error', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });
      return;
    }

    // Array con los nombres de los meses
    const meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

    // Extraer día, mes y año
    const dia = this.fechaSeleccionada.getDate();
    const mes = meses[this.fechaSeleccionada.getMonth()];
    const año = this.fechaSeleccionada.getFullYear();

    // Formatear la fecha en un formato legible
    const fechaFormateada = `${dia} de ${mes} de ${año}`;

    
    this.resumenCita = this.resumenCitaDefault
      .replace('{{dia}}', fechaFormateada)
      .replace('{{hora}}', this.selectedHour)

    console.log(this.resumenCita, this.fechaSeleccionada, this.selectedHour)

    document.querySelector('#email-modal').classList.add('md-show');


    
  }

  getAvailableDays() {

    let body: any = {
      adi: this.adi,
      adu: this.adu,
      ndc: this.ndc
    }

    // Falta en back modificar para que solo devuelva las fechas y horas disponibles.
    // Falta modificar el rest en back y adaptar la respuesta al front en la lista de horas.

    console.log(body)
    this.apiService.getAvailableDays(body).subscribe({
      next: (result: any) => {
        console.log('horarios: ', result);
        if(result && result.length){
          this.configurationCalendar = result[0]
          const fechaInicio = new Date(this.configurationCalendar.fechaInicio);
          // Obtener la fecha actual (suponiendo que this.actualDate ya es un objeto Date)
          const fechaActual = this.actualDate;

          // Comparar si la fecha de inicio es posterior a la fecha actual
          if (fechaInicio > fechaActual) {
            // Si la fecha de inicio es después de la actual, actualizamos el mes
            const mesInicio = fechaInicio.getMonth();
            this.actualDate.setMonth(mesInicio);
          }

          this.actualizarCalendario();
        }
      },
      error: (error) => {
        console.log('Error al obtener horarios: ', error);
        this.toastr.error('Ha ocurrido un error al obtener horarios.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      },
      // complete: () => console.info('complete')
    })

    // for (let i = 8; i < 17; i++) {
    //   this.listHours.push({
    //     id: i,
    //     value: i + ':00'
    //   })
    // }
  }

  getAvailableHours(fecha) {

    let body: any = {
      adi: this.adi,
      adu: this.adu,
      ndc: this.ndc, 
      fecha: fecha
    }

    // Falta en back modificar para que solo devuelva las fechas y horas disponibles.
    // Falta modificar el rest en back y adaptar la respuesta al front en la lista de horas.

    this.apiService.getAvailableHours(body).subscribe({
      next: (result: any) => {
        console.log('horarios: ', result);
        this.listHours = [...result]
      },
      error: (error) => {
        console.log('Error al obtener horarios: ', error);
        this.toastr.error('Ha ocurrido un error al obtener horarios.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      },
      // complete: () => console.info('complete')
    })

    // for (let i = 8; i < 17; i++) {
    //   this.listHours.push({
    //     id: i,
    //     value: i + ':00'
    //   })
    // }
  }

  actualizarCalendario(): void {
    this.actualMonth = this.actualDate.toLocaleString('es-ES', { month: 'long' });
    const primerDiaMes = new Date(this.actualDate.getFullYear(), this.actualDate.getMonth(), 1);
    const ultimoDiaMes = new Date(this.actualDate.getFullYear(), this.actualDate.getMonth() + 1, 0);
    this.monthDays = Array.from({ length: ultimoDiaMes.getDate() }, (_, i) => i + 1);

    let primerDiaSemana = primerDiaMes.getDay();

    if (primerDiaSemana !== 0) {
      for (let i = 0; i < primerDiaSemana; i++) {
        this.monthDays.unshift(' ');
      }
    }
  }

  cambiarMes(incremento: number): void {
    this.actualDate.setMonth(this.actualDate.getMonth() + incremento);
    this.actualizarCalendario();
  }

  seleccionarFecha(dia: any): void {
    this.fechaSeleccionada = null
    this.selectedHour= null

    if(this.estadoUsuario){
      return ;
    }

    if (dia !== ' ') {
      this.fechaSeleccionada = new Date(this.actualDate.getFullYear(), this.actualDate.getMonth(), dia);
      // console.log('Fecha seleccionada:', fechaSeleccionada.toLocaleDateString('es-ES'));
      this.selectedDate = this.fechaSeleccionada.toLocaleDateString('es-ES');

      this.eventsLocale = [];
      for (let i = 0; i < this.eventsRemote.length; i++) {
        if (new Date(this.eventsRemote[i].fecha).toLocaleDateString('es-ES') === this.selectedDate) {
          this.eventsLocale.push(this.eventsRemote[i]);
        }
      }

      if (this.selectedDate === this.actualDate.toLocaleDateString('es-ES')) {
        this.alertsTitle = 'Eventos de hoy';
      } else {
        this.alertsTitle = 'Eventos del ' + dia + ' de ' + this.actualMonth;
      }

      this.getAvailableHours(this.fechaSeleccionada);

    } else {
      this.selectedDate = null;
    }
    /////////////////////////////////////////////////////////


  }

  getDateByDay(dia: any) {
    if (dia !== ' ') {
      const fechaSeleccionada = new Date(this.actualDate.getFullYear(), this.actualDate.getMonth(), dia);
      return fechaSeleccionada.toLocaleDateString('es-ES');
    }

    return null;
  }

  hasEvents(dia: any) {
    // console.log('hasEvents', dia);
  
    if (dia !== ' ' && this.configurationCalendar) {
      const fechaSeleccionada = new Date(this.actualDate.getFullYear(), this.actualDate.getMonth(), dia);
      
    // console.log('hasEvents', fechaSeleccionada, dia);

  //   {
  //     "_id": "66ef176712456ad35b00fd36",
  //     "identificadorEmpresa": "U65490445DBOT",
  //     "nombreEmpresa": "Luz Natural",
  //     "intervalo": 60,
  //     "dias": [
  //         "Viernes",
  //         "Sábado",
  //         "Domingo"
  //     ],
  //     "fechaInicio": "2024-11-08T05:00:00.000Z",
  //     "fechaFinal": "2024-12-22T05:00:00.000Z",
  //     "horaInicio": 9,
  //     "horaFinal": 17,
  //     "numeroSesiones": 1
  // }


      // Convertir la fecha de inicio y final del rango a objetos Date
      const fechaInicio = new Date(this.configurationCalendar.fechaInicio);
      const fechaFinal = new Date(this.configurationCalendar.fechaFinal);
  
      // Verificar si la fecha seleccionada está dentro del rango
      if (fechaSeleccionada >= fechaInicio && fechaSeleccionada <= fechaFinal) {
  
        // Array de días disponibles
        const dias = this.configurationCalendar.dias;
  
        // Obtener el nombre del día de la semana
        const diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
        const diaSemana = diasSemana[fechaSeleccionada.getDay()];
  
        // Verificar si el día de la semana está en el array de días
        if (dias.includes(diaSemana)) {
          return true;
        }
      }
    }
  
    return false;
  }

  saveSchedule() {


    if (
      !this.emailFormData ||
      !this.nombreFormData ||
      !this.telefonoFormData ||
      !this.apellidoFormData ||
      !this.nPersonasFormData ||
      !this.pagoFormData ||
      !this.adicionalFormData ||
      !this.adi ||
      !this.fechaSeleccionada ||
      !this.selectedHour ||
      !this.selectedHourId
    ) {
      // Mostrar un mensaje de error o alerta si lo deseas
      console.log("Por favor, completa todos los campos del formulario.");
      this.toastr.error('Faltan parámetros', 'Error', {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });


      return false; // Retornar para evitar continuar
    }

    //Falta obtener la fecha y hora del calendario y la lista de horas para enviar en el body

    let body: any = {
      email: this.emailFormData,
      fecha: this.fechaSeleccionada,
      hora:  this.selectedHour,
      hourId: this.selectedHourId,

      nombre: this.nombreFormData,
      apellido: this.apellidoFormData,
      telefono: this.telefonoFormData,
      nPersonas: this.nPersonasFormData,
      pago: this.pagoFormData,
      adicional: this.adicionalFormData,

      adi: this.adi,
      adu: this.adu,
      ndc: this.ndc
    };


  

    this.apiService.createAppointment(body).subscribe({
      next: (result: any) => {
        console.log('horarios: ', result);
        this.closeModal('email-modal')
        // this.router.navigate(['home']);
        this.agendado = true

        if (this.ndc >= 2) {
          this.scheduleSecondAppointment();
        }

      },
      error: (error) => {
        console.log('Error al crear cita: ', error);
        this.toastr.error('Ha ocurrido un error al crear cita.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      },
      // complete: () => console.info('complete')
    })

  } 

// Función para agendar la segunda cita
scheduleSecondAppointment() {
  const nextHourIndex = this.getNextAvailableHourIndex(this.selectedIndex);

  if (nextHourIndex !== null) {
    let nextHour = this.listHours[nextHourIndex];
    let body: any = {
      email: this.emailFormData,
      fecha: this.fechaSeleccionada,
      hora: nextHour.value, // Hora siguiente
      hourId: nextHour.id,  // ID de la hora siguiente
      adi: this.adi,
      adu: this.adu,
      ndc: this.ndc
    };

    this.apiService.createAppointment(body).subscribe({
      next: (result: any) => {
        console.log('Segunda cita creada: ', result);
      },
      error: (error) => {
        console.log('Error al crear la segunda cita: ', error);
        this.toastr.error('Ha ocurrido un error al crear la segunda cita.', 'Error', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    });
  }
}

// Función para obtener el índice de la siguiente hora disponible
getNextAvailableHourIndex(currentIndex: number): number | null {
  const nextIndex = currentIndex + 1;

  if (nextIndex < this.listHours.length && !this.listHours[nextIndex].reservado) {
    return nextIndex;
  }

  return null; // No hay hora disponible o está reservada
}



  onHourChange(selectedValue: string, idSelected, index: number) {
    if (this.ndc >= 2) {
      // Verifica si el elemento actual es el último o si la siguiente hora está reservada
      if (index >= this.listHours.length - 1 || this.listHours[index + 1].reservado) {
        return; // No permite la selección si no se pueden seleccionar 2 citas consecutivas
      }
      this.selectedIndex = index;
    } else {
      // Comportamiento normal cuando udc es 1
      this.selectedIndex = index;
    }


    // if (this.ndc >= 2) {
    //   // Verifica si el elemento actual es el último
    //   if (index === this.listHours.length - 1) {
    //     return; // No permite la selección del último elemento
    //   }
    //   this.selectedIndex = index;
    // } else {
    //   // Comportamiento normal cuando udc es 1
    //   this.selectedIndex = index;
    // }
  


    // console.log(this.selectedIndex)
 
    this.selectedHour = selectedValue; // Actualiza la variable con la hora seleccionada    
    this.selectedHourId = idSelected; // Actualiza la variable con la hora seleccionada

    // console.log('Hora seleccionada:', this.selectedHour); // Muestra la hora en la consola
  }

  isItemSelected(index: number): boolean {

        // Si udc es 2 o más, aplica la lógica de selección del siguiente elemento
    if (this.ndc >= 2) {
        return this.selectedIndex === index || this.selectedIndex === index - 1;
      }
      // Si udc es 1, solo marca el seleccionado actual
      return this.selectedIndex === index;
    // }


    // return this.selectedIndex === index || this.selectedIndex === index - 1;
  }

  isItemDisabled(index: number, hour: any): boolean {
    if (hour.reservado) {
      return true; // Deshabilita si la hora está reservada
    }

 // Si udc >= 2, deshabilita las horas que no tienen al menos 2 consecutivas disponibles
 if (this.ndc >= 2) {
  // Verifica si es el último elemento o si la siguiente hora está reservada
  if (index === this.listHours.length - 1 || this.listHours[index + 1].reservado) {
    return true; // Deshabilita porque no hay suficiente espacio para 2 consecutivas
  }
}

return false; // Permite la selección si no está reservado y cumple las condiciones

  }







  closeModal(name: string) {
    document.querySelector('#' + name).classList.remove('md-show');
  }

}
