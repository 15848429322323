// import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ValidatorFn, FormControl } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { emailValidator, matchingPasswords } from '../../theme/utils/app-validators';
import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {AuthService} from '../../services/auth.service';
import { VerificationComponent } from './verification/verification.component';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})

export class SignUpComponent implements OnInit {
  @ViewChild('child1') childOne:VerificationComponent;
  registerForm: FormGroup;
  
  preloaderSignUp = false;
  emailRegistered = false;
  uNameRegistered = false ;

  newUser = new FormGroup({
    name: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    
    uname: new FormControl(''),
    // phone: new FormControl('', [Validators.required, Validators.minLength(9), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
    email: new FormControl('', [Validators.required, Validators.email]),
    // password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    // password_aux: new FormControl('', [Validators.required, Validators.minLength(6)])
  })

  message_header_register = '<div style=\"padding: 20px;\"><div style=\"margin: 32px auto; display: flex; flex-direction: row; justify-content: center;\"><div style=\"margin: auto;\"><img  src=\"https://s3-us-west-2.amazonaws.com/pruebas.ushops/logotipo.jpg\" alt=\"\"></div></div><div style=\"margin: 0px auto; border-radius: 5px; border: none; background-color: rgb(237, 250, 248); max-width: 500px; padding: 25px 15px; font-size: 16px;\"><h1 style=\"font-size: 20px; text-align: center;\">Ya está listo tu Código de verificación</h1><p style=\"text-align: center; font-size: 16px;\">Una vez ingresado el código ya serás un usuario habilitado en uShopsMarketplace y podrás disfrutar de la mejor experiencia en compras</p></div><div style=\"max-width: 500px; margin: 0px auto;\"><p style=\"text-align: center; font-size: 14px;\">Tu código de verificación es:</p><h1 style=\"text-align: center; letter-spacing: 2px; font-size: 32px; font-weight: bold;\">';
  message_body_register = '</h1><p style=\"text-align: center; font-size: 16px;\">Si quieres saber más de nuestra plataforma, ingresa a <a href=\"https://info.ushops.tech/home\" target=\"_blank\">www.info.ushops.tech</a> o escríbenos: services@ushops.tech</p></div></div>';



  constructor(public formBuilder: FormBuilder, public router: Router, public snackBar: MatSnackBar,
              private api: ApiService, private auth: AuthService) { }

  ngOnInit() {
    
    
    
    
    this.registerForm = this.formBuilder.group({
      'scope':'ushops-platform-kUAwIhqI',
      'device':'web',
      //'cedula': ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      // 'telefono': ['', Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      'email': ['', Validators.compose([Validators.required, emailValidator])],
      'nombre': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      'apellido': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      'username': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      // 'password': ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      // 'password_aux': ['', Validators.compose([Validators.required, Validators.minLength(3)])],

    },
    );
    this.auth.clear();
  }

  // public emailValid(email: string) : ValidatorFn{
  //   return (form: FormGroup): {[key: string]: boolean } | null =>{
  //     const emailValue = form.get(email).value;
  //     if (!emailValue) {return {missing : true};}

  //     if (this.emailRegistered)
  //     {
  //       console.log(this.emailRegistered);
  //       const err = {emailValid :  true};
  //       form.get(email).setErrors(err);
  //       return err;
  //     }
  //     // if( emailValue === 'hola@hola.com'){
        
  //     // }else console.log('no coincide')
  //   }
  // }

  verifUser(event: any){
    // console.log(event.target.value);
    // const body = {
    //   device : 'web',
    //   username: event.target.value.trim(),
    //   scope: 'ushops-platform-kUAwIhqI'
    // };
    // this.api.authentication(body).subscribe(
    //   result=>{
    //     console.log(result);
    //     console.log(event.target.name)
    //     if(event.target.name === 'userName'){
    //       this.uNameRegistered = true;
    //       this.snackBar.open('El uName ingresado ya ha sido registrado', '×', { panelClass: 'error', verticalPosition: 'top', duration: 4000 });
    //     }
    //     else if (event.target.name === 'email'){
    //       this.emailRegistered = true;
    //       this.snackBar.open('El email ingresado ya ha sido registrado', '×', { panelClass: 'error', verticalPosition: 'top', duration: 4000 });
    //     }
    //   },
    //   error => {
    //     if(event.target.name === 'userName') {this.uNameRegistered = false;}
    //     else if (event.target.name === 'email') {this.emailRegistered = false;}
    //   }
    // )

    const body = {
      scope: 'ushops-platform-kUAwIhqI',
      parametro: event.target.value.trim()
    }

    if (event.target.name === 'email'){
      this.api.authenticationEmail(body).subscribe(
        result => {
          // if(result !== null) {
            this.emailRegistered = true;
            this.snackBar.open('El email ingresado ya ha sido registrado', '×', { panelClass: 'error', verticalPosition: 'top', duration: 4000 });
          // }
        }, 
        error => {
          // console.log(error);
          this.emailRegistered = false;

        }
      )
    } else if (event.target.name === 'userName') {
      this.api.authenticationUname(body).subscribe(
        result => {
          // if(result !== null) {
            this.uNameRegistered = true;
            this.snackBar.open('El uName ingresado ya ha sido registrado', '×', { panelClass: 'error', verticalPosition: 'top', duration: 4000 });
          // }
        }, 
        error => {
          // console.log(error);
          this.uNameRegistered = false
        }
      )
    }
  }

  public onRegisterFormSubmit(values: FormGroup) {
    console.log('boton presionado');
    var acepto = document.forms['formregistro']['acepto'];
    if(acepto.checked)
    {
      if (this.registerForm.valid && !this.emailRegistered && !this.uNameRegistered) {
        this.preloaderSignUp = true;
        console.log(values);
        console.log(values['email'])
        console.log(values['nombre'])
        this.auth.setLocal('registeredUser',{
          nombre:values['nombre'],
          email:values['email'],
          //cedula:values['cedula'],
        })
        //this.childOne.cedula_shared = values['cedula'];
        this.api.newUser(values).subscribe(
            result => {
              if (result.mensaje === 'cuenta ya validada') {
                  console.log(result);
                  this.preloaderSignUp = false;
                  this.snackBar.open('Ya se ha registrado esta cuenta', '×', { panelClass: 'error', verticalPosition: 'top', duration: 4000 });
              } else{
                console.log(result);
                  this.snackBar.open('Registro exitoso!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 4000 });
                  this.snackBar.open('El código de verificacion ha sido enviado a tu enviado a tu email!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 7000 });
                  // this.auth.setToken(result);
                  this.router.navigate(['/sign-up/verification']);
              }
            },
            error => {
              this.preloaderSignUp = false;
              alert('EL USUARIO YA EXISTE, elige otro usuario');
              // return;
            }
        );
      } else {
        this.snackBar.open('El uName o email ingresado ya ha sido registrado', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
      }
    }
  }

  RegisterUser() {
    var acepto = document.forms['formregistro']['acepto'];
    if(acepto.checked) {
      console.log(this.newUser.valid , !this.emailRegistered , !this.uNameRegistered)
      console.log('this.newUser',this.newUser)
      // if(this.newUser.get('password').value === this.newUser.get('password_aux').value && this.newUser.valid) {
        if (this.newUser.valid && !this.emailRegistered && !this.uNameRegistered) {
          this.auth.setLocal('registeredUser',{
            nombre:this.newUser.get('name').value,
            email:this.newUser.get('email').value
          })
          const body = {
            registerCompany: 'ushops-platform-kUAwIhqI',
            device: 'web',
            username: this.newUser.get('uname').value,
            // phone: this.newUser.get('phone'). value,
            email: this.newUser.get('email').value,
            firstName: this.newUser.get('name').value,
            lastName: this.newUser.get('lastName').value,
            // password: this.newUser.get('password').value
          }
          console.log('body-register', body);
          this.api.registerUser(body).subscribe(
            result=>{
              console.log(result)
              // this.sendEmail(result['codigoVerificacion'])
              this.router.navigate(['/sign-up/verification']);
            },
            error => {
              console.log(error)
            }
          )
        } else {
          this.snackBar.open('Algo salió mal. Revisa tus datos e intentalo de nuevo', '×', { panelClass: 'error', verticalPosition: 'top', duration: 4000 });
        }
      // } else {
      //   this.snackBar.open('Las contraseñas ingresadas no coinciden', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
      // }
    } else {
      this.snackBar.open('No olvides de aceptar los Términos y condiciones de uShops', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }
  }

  sendEmail(codigo){
    const body = {
      titulo: "uShops - Clave de acceso",
      de: "uShops",
      email: 'edys_017@hotmail.com',
      mensaje: this.message_header_register + codigo + this.message_body_register
    }
    this.api.sendEmail(body).subscribe(
      result =>{
        console.log('CORREO REGISTRO ENVIADO',result);
      },
      error =>{
        console.log(error)
      }
    )
  }
  

}
